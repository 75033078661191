import React from 'react';
import { useSelector } from 'react-redux';

import ResultsHeader from './ResultsHeader';
import ResultsTable from './ResultsTable';
import TeacherHeader from '../Teacher Schedule/TeacherHeader';

const ResultsPage = (props) => {

    const tName = useSelector((state) => state.authentication.user?.userObj)
    const role = useSelector((state) => state.authentication.user?.userObj?.role)

    return (
        <div className="container-fluid">
            <TeacherHeader
                name={tName ? tName.first_name + " " + tName.last_name : null}
                role={role}
                title={`Results for ${props.computedMatch.params.quizName}`}
            />
            <ResultsTable 
                quizId={props.computedMatch.params.quizId}
                studentsCompleted={props.computedMatch.params.studentsCompleted}
                previewQuizId={props.computedMatch.params.previewQuizId}
                classPeriod={props.computedMatch.params.classPeriod}
                className={props.computedMatch.params.className}
            />
        </div>
    )
}

export default ResultsPage;