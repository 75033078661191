import React, { useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import TeacherHeader from '../Teacher Schedule/TeacherHeader';
import TeacherCleverHeader from './Header/TeacherCleverHeader';
import TeacherStudentsTable from './components/TeacherStudentsTable';
import authHeader from '../../../helpers/authHeader';
import { handleResponse } from '../../../helpers/handleResponse';
import './TeacherCleverStudents.css';
import { Button, Modal } from '@mui/material';
const TeacherCleverStudents = props => {
    const tName = useSelector(state => state.authentication.user?.userObj);
    const role = useSelector(state => state.authentication.user?.userObj?.role);
    const history = useHistory();
    const [classInfo, setClassInfo] = React.useState('');
    const classId = props.computedMatch.params.classId;
    const API_URL = process.env.REACT_APP_API_URL;
    const [explanationModalOpen, setExplanationModalOpen] = React.useState(false);
    const fetchClassInfo = useCallback(async () => {
        const requestOptions = {
            method: 'GET',
            headers: authHeader(),
            credentials: 'include'
        };
        await fetch(API_URL + `/class/${classId}/`, requestOptions)
            .then(handleResponse)
            .then(data => {
                setClassInfo(data);
            });
    }, [API_URL, classId]);

    useEffect(() => {
        if (classId) fetchClassInfo();
    }, [classId, fetchClassInfo]);

    return (
        <div>
            <TeacherHeader
                title={'Classroll'}
                name={tName ? tName.first_name + ' ' + tName.last_name : null}
                role={role}
                underLogout={() => (
                    <span
                        className="explanation-span"
                        onClick={() => {
                            setExplanationModalOpen(true);
                        }}
                    >
                        Explain this page
                    </span>
                )}
            />
            <div className="teacher-clever-students-content">
                <div className="back-button-container">
                    <Button className="back-button-t-students" onClick={() => history.push(`/teacher-schedule`)}>
                        BACK TO CLASSES
                    </Button>
                </div>
                <div className="teacher-clever-header">
                    <TeacherCleverHeader classId={classId} />
                </div>
                <div className="table-title-wrapper">
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span>{`${classInfo.name} - Grade ${classInfo.grade}`}</span>
                    </div>
                </div>
                <div className="table-content">
                    <TeacherStudentsTable classId={classId} />
                </div>
            </div>
            <Modal
                open={explanationModalOpen}
                onClose={() => {
                    setExplanationModalOpen(false);
                }}
                className="explanation-modal"
            >
                <div className="explanation-modal-content">
                    <p style={{ fontWeight: 'bold', fontSize: '1.2rem', marginBottom: '2rem', textAlign: 'center' }}>
                        Changing Students
                    </p>
                    <ul>
                        <li style={{ margin: '0.75rem 0' }}>
                            When a student first applies to your class (by entering the class code) they will appear on this list
                            as Inactive.{' '}
                        </li>
                        <li style={{ margin: '0.75rem 0' }}>
                            You can add the student to your Class Roll by changing their status to Enrolled and clicking the
                            button Save Changes.{' '}
                        </li>
                        {/* <li>
                            If you change an Enrolled student to Inactive, she will no longer appear in your Class Roll; however,
                            the Inactive student will remain on this list and you can re-Enroll her later.{' '}
                        </li> */}
                        <li style={{ margin: '0.75rem 0' }}>
                            If you Remove a student from this list you cannot restore them, but the student can reapply to your
                            class and will again appear here as Inactive.
                        </li>
                    </ul>
                    <div className="back-button-container" style={{ marginTop: '4rem' }}>
                        <Button className="back-button-t-students" onClick={() => setExplanationModalOpen(false)}>
                            Close
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default TeacherCleverStudents;
