import { useEffect, useState} from 'react';
import { useDispatch } from 'react-redux';
import authHeader from '../../helpers/authHeader';
import { useHistory } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { ReactComponent as Logo } from '../../assets/img/logo.svg';
import { handleResponse } from '../../helpers/handleResponse';
import { userActions } from '../../redux/user/user.actions';
const CleverRedirect = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const API_URL = process.env.REACT_APP_API_URL;

    const { searchParams } = new URL(window.location.href);
    const codeParam = searchParams.get('code');

    const [cleverError, setCleverError] = useState(null);
    const [text, setText] = useState('You are being redirected');

    const cleverLogin = authenticationCode => {
        const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            credentials: 'include',
            body: JSON.stringify({ code: authenticationCode })
        };
        fetch(API_URL + '/clever/auth/login/', requestOptions)
            .then(res => handleResponse(res, false))
            .then(data => {
                if (data?.registration_token) {
                    if (data?.role === 'Teacher') {
                        window.history.replaceState(null, null, window.location.href.split('?')[0]);
                        history.push(`/teacher-registration-introduction/${data?.registration_token}`, { search: '' });
                    } else if (data?.role === 'Student') {
                        window.history.replaceState(null, null, window.location.href.split('?')[0]);
                        history.push(`/student-registration/${data?.registration_token}`, { search: '' });
                    }
                } else {
                    if (data.userObj) {
                        dispatch(userActions.loginClever(data));
                    } else {
                        if (
                            data?.status_code === 401 ||
                            data?.status_code === 400 ||
                            data?.status_code === 403 ||
                            data?.status_code === 404 ||
                            data?.status_code === 500 ||
                            data?.status_code === 402
                        ) {
                            setCleverError(data);                           
                        }
                    }
                }
            })
            .catch(error => {
                setCleverError(error);
            });
        };
        
    useEffect(() => {
        if (codeParam) {
            dispatch(userActions.logoutNoRedirect())
            setTimeout(() => {
                cleverLogin(codeParam);
            }, 2000);
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    useEffect(() => {
        const intervalId = setInterval(() => {
            setText(text => {
                if (text.endsWith('...')) {
                    return text.slice(0, -3);
                } else {
                    return text + '.';
                }
            });
        }, 500);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <Container className="d-flex justify-content-center align-items-center vh-100">
            <div className="d-flex flex-column align-items-center">
                <Logo style={{ width: 366, height: 'auto', maxWidth: '100%', maxHeight: '100%' }} />

                {!cleverError ? (
                    <div className="mt-2" style={{ fontSize: '2rem' }}>
                        {text}
                    </div>
                ) : (
                    <>
                        <div className="mt-2" style={{ color: 'red', fontSize: '2rem' }}>
                            Error: {cleverError.detail || 'Something went wrong.'}
                        </div>
                        <button style={{ 
                            borderRadius: '12px',
                            height: '50px',
                            width: '130px',
                            backgroundColor: 'white',
                            color: 'grey',
                            fontWeight: 'bold',
                            fontSize: '16px',
                            textAign: 'center',
                            border: '2px solid #B1B1FF',
                            marginTop: '4vh',
                            boxShadow: '0px 0px 16px #B8B8ED33',
                        }}
                            onClick={() => {
                                window.history.replaceState(null, null, window.location.href.split('?')[0]);
                                history.push('/login', { search: '' });
                            }}
                        >
                            Back to Login
                        </button>
                    </>
                )}
            </div>
        </Container>
    );
};

export default CleverRedirect;
