import React, { useEffect, useState} from 'react';
import authHeader from '../../../helpers/authHeader';
import { handleResponse } from '../../../helpers/handleResponse';

import { useHistory } from "react-router-dom";


import moment from 'moment';

import * as AiIcons from 'react-icons/ai';
import * as HiIcons from 'react-icons/hi';
import ContactUsModalComponent from '../../Common/contact-us-modal/contact-us-modal.component';

import { selectShow } from '../../../redux/modals/modal.select';
import { toggleShow } from '../../../redux/modals/modals.actions';
import { userActions } from '../../../redux/user/user.actions';
import { connect, useDispatch } from "react-redux";

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import { TableContainer, Table, TableCell, TableHead, TableRow, TableSortLabel, TableBody } from '@material-ui/core';

import './TeacherTableRetakesContent.css';

const TeacherTableRetakesContent = ({show, toggleShow, classId, className }) => {
    const API_URL = process.env.REACT_APP_API_URL
    const history = useHistory();
    const dispatch = useDispatch();

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    today = dd + '-' + mm + '-' + yyyy;


    const [ data, setData ] = useState()

    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()

    const [rowData, setRowData] = useState(data);
    const [orderDirection, setOrderDirection] = useState("asc");

    const sortArray = (arr, orderBy) => {
        switch (orderBy) {
          case "asc":
          default:
            return arr.sort((a, b) =>
              a.last_name.localeCompare(b.last_name)
            );
          case "desc":
            return arr.sort((a, b) =>
              a.last_name < b.last_name ? 1 : b.last_name < a.last_name ? -1 : 0
            );
        }
      };
       
      const handleSortRequest = () => {
        setRowData(sortArray(data, orderDirection));
        setOrderDirection(orderDirection === "asc" ? "desc" : "asc");
      };

    const id = Object.values(classId);


    const onChangeStartDate = (date) => {
        setStartDate(date)
    }

    const onChangeEndDate = (date) => {
        setEndDate(date)
    }

    const handleLogout = () => {
        dispatch(userActions.logout());
    };

    const handleContactUs = () => {
        toggleShow();
    };

    const formattedStart = moment(startDate).format('DD-MM-YYYY');
    const formattedEnd = moment(endDate).format('DD-MM-YYYY');

    const formattedStartLabel = moment(startDate).format('MM-DD-YYYY');
    const formattedEndLabel = moment(endDate).format('MM-DD-YYYY');


    const fetchData = () => {
        const requestOptions = {
            method: "GET",
            headers: authHeader(),
            credentials: "include",
        };
        startDate && endDate ? fetch(API_URL + `/report/teacher/stuqztaken/${classId}/?start=${formattedStart}&end=${formattedEnd}`, requestOptions)
            .then(
                handleResponse
            )
            .then(
                (data) => {
                    setData(data.result)
                })
        :
            fetch(API_URL + `/report/teacher/stuqztaken/${classId}/?start=${today}&end=${today}`, requestOptions)
                .then(
                    handleResponse
                )
                .then(
                    (data) => {
                        setData(data.result)
                    })
    }

    useEffect(() => {
        fetchData();
    },[startDate, endDate])


    const getStripedStyles = (index) => {
        return { backgroundColor: index % 2 ? '#eeeeee' : 'white' };
    }

    return (
        <div class="container mb-2" style={{margin: '0 auto', paddingBottom: 100}}>

            <button className='back-button' style={{marginTop: 30}}
                onClick={() => history.push(`/class-quizzes/${classId}/${className}`)}
            >
                BACK TO QUIZZES
            </button>


            <div style={{display: 'flex', flexDirection: 'row',flexWrap: 'nowrap', justifyContent: 'space-between'}}>
                <div className='start-calendar' style={{float: 'left', width: '32%'}}>
                    <span className='start-date'>Start date: {formattedStartLabel ? formattedStartLabel : today}</span>
                    <Calendar onChange={onChangeStartDate} value={startDate} />
                </div>
                <div style={{textAlign: 'center', height: '29vh', marginTop: '6.5vh', justifyContent: 'center', color: 'grey', fontWeight: 'bold'}}>
                    <p style={{marginTop: '5vh', textAlign: 'start', marginLeft: '3vw', marginBottom: -3}}>Yellow = Today</p>
                    <p style={{textAlign: 'start', marginLeft: '3vw', marginBottom: -0.1}}>Blue = Date Selected</p>
                    <p>Select a Start date and an End date</p>
                </div>
                <div className='end-calendar' style={{ float: 'right', width: '32%', paddingBottom: 50 }}>
                    <span className='end-date' style={{marginBottom: 40}}>End date: {formattedEndLabel ? formattedEndLabel : today}</span>
                    <Calendar onChange={onChangeEndDate} value={endDate}/>
                </div>
               
            </div>


            <TableContainer className="table-retakes-container">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell key="first" align="center">
                                    First
                            </TableCell>
                            <TableCell key="last" align="center" onClick={handleSortRequest}>
                                <TableSortLabel active={true} direction={orderDirection}>
                                    Last
                                </TableSortLabel>
                            </TableCell >
                            <TableCell key="original-quiz-count" align="center">
                                    Original Quiz Count
                            </TableCell>
                            <TableCell key="retake-quiz-count" align="center">
                                    Retake Quiz Count
                            </TableCell>
                            <TableCell key="total-quiz-count" align="center">
                                <TableSortLabel>
                                    Total Quiz Count
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody className='past-table-body' >
                        {data ? data.map((row, index) => (
                            <TableRow key={index} className='past-table-rows'>
                                <TableCell className='past-cell' align="center"
                                    style={{ color: 'grey', ...getStripedStyles(index), borderBottom: '2px solid grey' }}
                                >
                                    {row?.first_name}
                                </TableCell>
                                <TableCell className='past-cell' align="center"
                                    style={{ fontWeight: 'bold', fontSize: 12, color: 'grey', borderBottom: '2px solid grey', ...getStripedStyles(index) }}
                                >
                                    {row?.last_name}
                                </TableCell>
                                <TableCell align="center" className='past-cell'
                                    style={{ tableCellsStyle2, ...getStripedStyles(index), borderBottom: '2px solid grey' }}
                                >
                                    {row?.completed_class}
                                </TableCell>
                                <TableCell align="center" className='past-cell'
                                    style={{ tableCellsStyle2, ...getStripedStyles(index), borderBottom: '2px solid grey' }}
                                >
                                    {row?.completed_retakes}
                                </TableCell>
                                <TableCell align="center" className='past-cell'
                                    style={{ tableCellsStyle2, ...getStripedStyles(index), borderBottom: '2px solid grey' }}
                                >
                                    {row?.completed_class + row?.completed_retakes}
                                </TableCell>
                            </TableRow>
                        )) : null}
                    </TableBody>
                </Table>
            </TableContainer>

            
        </div>
    )
}

export default TeacherTableRetakesContent;


const tableCellsStyle2 = {
    color: 'grey',
    textAlign: 'center',
    fontSize: 12,
}