import React from 'react';
import { useHistory } from 'react-router-dom';

import './CleverTeachers.css';

import { 
    Table, 
    TableBody, 
    TableRow, 
    TableCell,
    makeStyles,
    Checkbox,
    Button,
} from '@material-ui/core';


const useStyles = makeStyles({
    tableCell: {
        borderBottom: 'none',
        fontWeight: 'bold',
        fontSize: '18px',
        height: '75px'
    },
});

const enteredAutomaticallyData = [
    {
        firstName: 'Sandra',
        lastName: 'B',
    },
    {
        firstName: 'David',
        lastName: 'R'
    }
]

const enteredByTeacher = [
    {
        id:120,
        firstName: 'Sandra',
        lastName: 'Benson', 
        email: 'sbenson@maxdistrict.gov',
        school: 'Franklin Middle School',
        match: true,
    },
    {
        id: 229,
        firstName: 'David',
        lastName: 'Robson', 
        email: 'davidrobson@spiralmath.net',
        school: 'Boston Elementary School',
        match: false,
    }
]


  
const CleverTeachers = () => {

    const history = useHistory();
    const classes = useStyles();

    const handleCheckboxChange = (event, id) => {
        console.log(id);
    };

    return (
        <div>
            <h2 className='title'>Check in Teachers</h2>

            <p className='admin-subtext'>Some information was entered automatically; some by the teacher. Please confirm that they match.</p>

            <div className='main'>

                <div className='left'>
                    <h5 className='table-title'>Entered automatically</h5>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell className={classes.tableCell} style={tableRowsStyle}>First</TableCell>
                                <TableCell className={classes.tableCell} style={tableRowsStyle}>Last</TableCell>
                            </TableRow>
                                {enteredAutomaticallyData && enteredAutomaticallyData.map((item) => (
                                    <TableRow key={item.id}>
                                        <TableCell className={classes.tableCell}>{item.firstName}</TableCell>
                                        <TableCell className={classes.tableCell}>{item.lastName}</TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </div>

                <div className='right'>
                    <h5 className='table-title'>Entered by teacher</h5>
                    <Table>
                        <TableBody>
                            <TableRow >
                                <TableCell className={classes.tableCell} style={tableRowsStyle}>First</TableCell>
                                <TableCell className={classes.tableCell} style={tableRowsStyle}>Last</TableCell>
                                <TableCell className={classes.tableCell} style={tableRowsStyle}>Email</TableCell>
                                <TableCell className={classes.tableCell} style={tableRowsStyle}>School</TableCell>
                                <TableCell className={classes.tableCell} style={tableRowsStyle}>Match</TableCell>
                            </TableRow>
                                {enteredByTeacher && enteredByTeacher.map((item) => (
                                    <TableRow key={item.id}>
                                        <TableCell className={classes.tableCell} style={tableRowsStyleFirstTable}>{item.firstName}</TableCell>
                                        <TableCell className={classes.tableCell}>{item.lastName}</TableCell>
                                        <TableCell className={classes.tableCell}>{item.email}</TableCell>
                                        <TableCell className={classes.tableCell}>{item.school}</TableCell>
                                        <TableCell className={classes.tableCell}>
                                            <Checkbox 
                                                onChange={(event) => handleCheckboxChange(event, item.id)} 
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </div>
            </div>
            <div className='button-div'>
                <Button
                    style={{
                        color: '#9932CC', 
                        fontWeight: 'bold'
                    }}
                    className='save-button'
                    onClick={() => history.push('/admin/dashboard')}
                >
                    Submit
                </Button>
            </div>
        </div>
    )
}

export default CleverTeachers;

const tableRowsStyle = {
    color: '#9932CC', 
    fontSize: '15px',
}

const tableRowsStyleFirstTable = {
    height: '50px'
}