import React, { useEffect, useState} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Header from '../Header/Header';
import QuizPage from './QuizPage';
import quizTypes from '../../../redux/quiz/quiz.types';
import '../Student.css';


import authHeader from '../../../helpers/authHeader';
import { handleResponse } from '../../../helpers/handleResponse';
import Footer from '../../Common/footer/footer.component';
import './QuizPage.css';

import io from "socket.io-client";
import { setActiveQuizDetailsAction, setActiveClassHeaderAction} from "../../../redux/quiz/quiz.actions";
import { AiOutlineConsoleSql } from 'react-icons/ai';
const SOCKETIO_ENDPOINT = process.env.REACT_APP_SOCKETIO_ENDPOINT;





const LandingPage = () => {

    const API_URL = process.env.REACT_APP_API_URL
    const dispatch = useDispatch();
    const history = useHistory();

    const gradeInfo = useSelector((state) => state.quiz.active_class_header?.grade)
    const tName = useSelector((state) => state.authentication.user?.userObj)
    const tSubject = useSelector((state) => state.quiz.active_class_header?.subject)
    const quizName = useSelector((state) => state.quiz_active_quiz_details?.title)
    const [data, setData] = useState(null)
    const [quiz, setQuiz] = useState("")

    const fetchQuiz = (qzId) => {
        const requestOptions = {
            method: "GET",
            headers: authHeader(),
            credentials: "include",
        };
        fetch(API_URL + '/quiz/'+qzId+'/', requestOptions)
            .then(
                handleResponse
            )
            .then((dataRes) => {
                dispatch({ type: quizTypes.SAVE_STUDENT_CURRENT_QUIZ, payload: dataRes})

                dispatch(setActiveQuizDetailsAction(dataRes))
                setData(dataRes)

            })
    }

    const checkActiveQuiz = () => {
        const requestOptions = {
            method: "GET",
            headers: authHeader(),
            credentials: "include",
        };
        fetch(API_URL + '/quiz/active/', requestOptions)
            .then(
                handleResponse
            )
            .then((dataRes) => {
                try {
                setQuiz(dataRes.results)
                } 
                catch{

                }
            })
    }

    const fetchSubject = () => {
        const requestOptions = {
            method: "GET",
            headers: authHeader(),
            credentials: "include",
        };
        fetch(API_URL + '/class/', requestOptions)
            .then(
                handleResponse
            )
            .then((dataRes) => {
                if(dataRes.results && dataRes.results[0]) {
                    dispatch(setActiveClassHeaderAction(dataRes.results[0]))
                }
           })
    }

    useEffect(() => {
        dispatch({ type: quizTypes.CLEAR_STUDENT_CURRENT_QUIZ })
        fetchSubject()
        checkActiveQuiz();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    /*useEffect(() => {
            // window.katex = katex;
            // history.push("/login");
            if(tName) {
                // io({
                //     transportOptions: {
                //         polling: {
                //             extraHeaders: {
                //                 'X-Username': tName.id
                //             }
                //         }
                //     }
                // });
                const socket = io(SOCKETIO_ENDPOINT,{
                    transportOptions: {
                        polling: {
                            extraHeaders: {
                                'X-Username': tName.id
                            }
                        }
                    }
                });
                socket.on("connect", () => {
                    console.log("connected")
                })
                socket.on("room",(data) =>{
                    socket.emit('checkIfTestAvailable',data, tName.id);
                })
                socket.once("availableTest",(dataQz) =>{
                    if(dataQz) {
                        console.log("available test", dataQz);
                        fetchQuiz(dataQz)
                    }
                    else {
                        setData(null)
                    }
                })
                // socket.on("getQuizzes", data => {//connect
                //         // socket.emit("sendEvent", "event sent from frontend");//sendEvent
                //          ;
                //         // socket.emit('submitTest',1);
                //         console.log("getQuizzes", data)
                //     });
                // socket.on("sendArray",data => {  //connect
                //     console.log("sendArray", data);
                // })
                // if(tName) {
                //     setInterval(() => {
                //         socket.emit('databaseQuiz',tName.id)
                //     }, 5000);
                // }
                // socket.on("quiz", dataQz=> {//databaseQuiz
                //         //  alert(data); //arata id ul testului de interes
                //         if(dataQz) {
                //             fetchQuiz(dataQz)
                //             fetchSubject()
                //         }
                //         else {
                //             setData(null)
                //         }
                //     },
                //     // socket.on("showStudentsCompleted",(data) =>{
                //     //     console.log("showStudentsCompleted",data);
                //     // })
                // )

            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [tName]
    );*/

    return (
            <div class="container-fluid" className='main-student-container'>
                <Header
                    grade={gradeInfo}
                    subject={tSubject}
                    title={quiz?.title}
                    name={tName ? tName.first_name + " " + tName.last_name : null}/>
                <QuizPage quizData={quiz} />
                <Footer />
            </div>  
    )
}



export default LandingPage;
