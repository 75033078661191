import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import { Container } from "react-bootstrap";
import { Nav } from 'react-bootstrap'; 
import { createStructuredSelector } from "reselect";

import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { userActions } from "../../../redux/user/user.actions";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import * as BsIcons from 'react-icons/bs';
import * as AiIcons from 'react-icons/ai';
import * as HiIcons from 'react-icons/hi';

import ContactUsModalComponent from '../../Common/contact-us-modal/contact-us-modal.component';
import { selectShow } from '../../../redux/modals/modal.select';
import { toggleShow } from '../../../redux/modals/modals.actions';
import './QuizPage.css';

const QuizPage = ({quizData, show, toggleShow}) => {


    const [reload, setReload] = useState(false);
    const history = useHistory();

    const dispatch = useDispatch();

    const quizId = quizData?.id;

    function refreshPage() {
        window.location.reload(false);
    }

    const handleLogout = () => {
        dispatch(userActions.logout());
    };

    const handleContactUs = () => {
        toggleShow();
    };


    
    return (
        <div className='quiz-page-container'>

           
            { quizData ? 
            <div className='quiz-page-info'>
                <TableContainer className='table-container' style={{maxHeight:"300px"}}>
                    <Table className='table-table' stickyHeader >
                        <TableHead className='table-head'>
                            <TableRow className='table-row'>
                                    <TableCell className='table-crt' style={{ color: 'grey', fontSize: 12, backgroundColor: 'white' }}> </TableCell>
                                    <TableCell className='table-crt' style={{ width:'30%',fontWeight: 'bold', fontSize: 12, backgroundColor: 'white' }}>Class name</TableCell>
                                    <TableCell className='table-quiz' style={{ fontWeight: 'bold', fontSize: 12, backgroundColor: 'white' }}> 
                                    Quiz <br />
                                </TableCell>
                                <TableCell style={{backgroundColor: 'white' }}> </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                quizData.map((quiz,index) => 
                                    <TableRow key={quiz.id}>
                                        <TableCell style={{ color: 'grey'}}>{index+1}</TableCell>
                                        <TableCell>{quiz?.school_class_name}</TableCell>
                                        <TableCell>{quiz ? quiz.title : null}</TableCell>
                                        <TableCell>
                                            <LinkContainer
                                                className="start-quiz-button"
                                                to={`/quiz-started/${quiz.school_class}/${quiz.id}`}
                                            >
                                                <Nav.Link>START</Nav.Link>
                                            </LinkContainer>
                                        </TableCell>
                                    </TableRow>                              
                                )
                            }

                        </TableBody>
                    </Table>
                </TableContainer>
                <div className='button-container'>
                <div className='left-button-quiz-page'>
                        <button 
                            className='past-quizzes-button'
                            onClick={() => { history.push(`/class-list`) }}>
                            See Classes
                        </button>
                    </div>
                    <div className='left-button-quiz-page'>
                        <button 
                            className='past-quizzes-button'
                            onClick={() => { history.push(`/past-quizzes`) }}>
                        
                            Past Quizzes
                        </button>
                    </div>
                    <div className='right-button-quiz-page'>
                        <button 
                            className='new-quiz-button' 
                            onClick={refreshPage}
                        >
                            Check for New Quiz
                        </button>
                    </div>
                </div>
            </div>
            :
            <div className='no-quiz-info' >
                <h4>There is no quiz available at the moment.</h4>
                    <div className='button-container-2'>
                    <div className='left-button-quiz-page-1'>
                            <button
                                className='past-quizzes-button'
                                onClick={() => { history.push(`/class-list`) }}>
                                See Classes
                            </button>
                        </div>
                        <div className='left-button-quiz-page-1'>
                            <button
                                className='past-quizzes-button'
                                onClick={() => { history.push(`/past-quizzes`) }}>

                                Past Quizzes
                            </button>
                        </div>
                        <div className='right-button-quiz-page'>
                            <button
                                className='new-quiz-button'
                                onClick={refreshPage}
                            >
                                Check for New Quiz
                            </button>
                        </div>
                    </div>
            </div> 
            }
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    show: selectShow,
});

const mapDispatchToProps = (dispatch) => {
    return {
        toggleShow: () => dispatch(toggleShow()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(QuizPage);
