import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import ReactHighcharts from "highcharts-react-official";
import Highcharts from "highcharts";

const PastQuizzesReportsChart = ({data, title}) => {
    const history = useHistory()

    const [dataMap, setDataMap] = useState("")


    const chartData = data ? data.filter((chart) => chart[0] === title) : null;
    const finalData = dataMap ? dataMap.map((item) => item.score) : null;

    if(finalData) {
    finalData[0] = {
            y: finalData[0],
            marker: {
                radius: 10,
                fillColor: 'white',
                lineColor: 'orange',
                lineWidth: 5,
            }
        }
    }


            const options = {
                title: {
                    text: ''
                },
                series: [
                    {
                        data: finalData,
                        marker: {
                            fillColor: 'white',
                            lineColor: 'blue',
                            lineWidth: 3,
                            radius: 10
                        },
                        color: 'blue',
                        name: 'Retakes'
                    }
                ],
                chart: {
                    height: '300px',
                    marginTop: 50,
                },
                xAxis: {
                    tickInterval: 1,
                    tickAmount: 9,
                    min: 0,
                    max: 8
                },
                yAxis: {
                    tickInterval: 1,
                    tickAmount: 6,
                    min: 0,
                    max: 5,

                    title: {
                        text: 'Correct'
                    }
                }
            };

    useEffect(() => {
        if(chartData) {
            setDataMap(chartData[0][1])
        }
    },[chartData])

    


    return (
        <div style={{ marginTop: 30, height: '70vh' }}>
            <button
                className="back-button"
                style={{ marginLeft: 30, marginBottom: 80 }}
                onClick={() => history.push(`/past-quizzes`)}
            >
                BACK TO PAST QUIZZES
            </button>
            <div style={{paddingLeft:'3rem',paddingRight:'3rem'}}>
                <ReactHighcharts options={options} highcharts={Highcharts} />
            </div>
        </div>
    );
}

export default PastQuizzesReportsChart
