import { handleResponse } from "../../helpers/handleResponse";
import authHeader from "../../helpers/authHeader";

const API_URL = process.env.REACT_APP_API_URL

const get_message_user_list = () => {
   const requestOptions = {
      method: "GET",
      headers: authHeader(),
      credentials: "include",
   };
   return fetch(
      API_URL + "/user/short-list/",
      requestOptions
   ).then(handleResponse);
};

const get_messages_id = () => {
   const requestOptions = {
      method: "GET",
      headers: authHeader(),
      credentials: "include",
   };
   return fetch(
      API_URL + "/messages/",
      requestOptions
   ).then(handleResponse);
};

const send_message = (data) => {
   const requestOptions = {
      method: "POST",
      headers: authHeader(),
      body: JSON.stringify(data),
      credentials: "include",
   };
   return fetch(API_URL + "/messages/send/", requestOptions).then(handleResponse);
};

const get_single_user_messages = (id) => {
   const requestOptions = {
      method: "GET",
      headers: authHeader(),
      credentials: "include",
   };
   return fetch(API_URL + `/messages/${id}/load/`, requestOptions).then(
      handleResponse
   );
};

export default {
   get_message_user_list,
   get_messages_id,
   get_single_user_messages,
   send_message,
};
