import { userTypes } from "../user/user.types";
import quizTypes from "./quiz.types";

const initialState = {
  deleteLoading: false,
  editLoading: false,
  getLoading: false,
  createLoading: false,
  error: "",
  quizzes: null,
  quizCount: 0,
  studentCurrentQuiz: null,
  studentQuizAnswers: [],
  retake: false,
  revision: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case quizTypes.GETALL_QUIZ_REQUEST:
      return { ...state, quizzes: null, quizCount: 0, loading: true };
    case quizTypes.GETALL_QUIZ_SUCCESS:
      return {
        ...state,
        quizzes: action.payload.quizzes,
        quizCount: action.payload.quizCount,
        getLoading: false,
      };
    case quizTypes.GETALL_QUIZ_FAILURE:
      return {
        ...state,
        quizzes: null,
        quizCount: 0,
        getLoading: false,
        error: "Failed to load quizzes",
      };
    case quizTypes.DELETE_QUIZ_REQUEST:
      return { ...state, deleteLoading: true };
    case quizTypes.DELETE_QUIZ_SUCCESS:
      return { ...state, deleteLoading: false };
    case quizTypes.DELETE_QUIZ_FAILURE:
      return { ...state, deleteLoading: false };
    case quizTypes.EDIT_QUIZ_REQUEST:
      return { ...state, editLoading: true };
    case quizTypes.EDIT_QUIZ_SUCCESS:
      return { ...state, editLoading: false };
    case quizTypes.EDIT_QUIZ_FAILURE:
      return { ...state, editLoading: false };
    case quizTypes.CREATE_QUIZ_REQUEST:
      return { ...state, createLoading: true };
    case quizTypes.CREATE_QUIZ_SUCCESS:
      return { ...state, createLoading: "success" };
    case quizTypes.CREATE_QUIZ_FAILURE:
      return { ...state, createLoading: "fail", error: action.error };
    case quizTypes.RESET_LOADINGS:
      return {
        ...state,
        createLoading: false,
        editLoading: false,
        deleteLoading: false,
        getLoading: false,
      };
    case quizTypes.CLEAR_QUIZ_ERROR:
      return { ...state, error: "" };
    case quizTypes.SAVE_STUDENT_CURRENT_QUIZ:
      return { ...state, studentCurrentQuiz: action.payload };
    case quizTypes.CLEAR_STUDENT_CURRENT_QUIZ:
      return { ...state, studentCurrentQuiz: null };
    case quizTypes.SET_STUDENT_QUIZ_ANSWERS:
      return { ...state, studentQuizAnswers: action.payload };
    case quizTypes.SET_ACTIVE_QUIZ_DETAILS:
      return { ...state, active_quiz_details: action.payload };
    case quizTypes.SET_ACTIVE_QUIZ_HEADER:
      return { ...state, active_quiz_header: action.payload };
    case quizTypes.SET_ACTIVE_CLASS_HEADER:
      return { ...state, active_class_header: action.payload };
    case quizTypes.SET_RETAKES:
      return { ...state, retake: true };
    case quizTypes.SET_REVISION:
      return { ...state, revision: true };
    case quizTypes.RESET_QUIZ_DATA:
      return {...state, studentQuizAnswers: []};
    case userTypes.LOGOUT:
      return { ...state, studentQuizAnswers: [], retake: false };
    default:
      return state;
  }
};

