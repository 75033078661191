import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";

import authHeader from '../../../helpers/authHeader';
import { handleResponse } from '../../../helpers/handleResponse';
import QuizShowModal from '../../Quizzes/quiz-modal';
import { userActions } from '../../../redux/user/user.actions';
import { selectShow } from '../../../redux/modals/modal.select';
import { toggleShow } from '../../../redux/modals/modals.actions';

import {
    Table, 
    TableBody,
    TableCell,
    TableSortLabel,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core'


import './ClassQuizzesContent.css';

const ClassQuizzesContent = ({ classPeriod, className, history }) => {

    const API_URL = process.env.REACT_APP_API_URL

    const [data, setData] = useState([])
    const [currentQuiz, setCurrentQuiz] = useState(null)
    const [questions, setQuestions] = useState(null)
    const [showQuiz, setShowQuiz] = useState(false);
    const [orderBySequence, setOrderBySequence] = useState(false);
    const [orderDirection, setOrderDirection ] = useState('asc');
    const [valueToOrderBy, setValueToOrderBy] = useState('sequence');
    const [ choice, setChoice ] = React.useState('');
    const [isAQuizActive, setIsAQuizActive] = React.useState();
    const handleChange = (event) => {
        setChoice(event.target.value);
    };

    
    const handleRequestSort = (event, property) => {
        const isAscending = (valueToOrderBy === property && orderDirection === 'asc')
        setValueToOrderBy(property)
        setOrderDirection(isAscending ? 'desc' : 'asc')
    } 

    const createSortHandler = (property, event) => {
        handleRequestSort(event, property)
    }

    // const constructQuery = (e) => {
    //     let queryStr;
    //     if (e) {
    //         queryStr = e.value !== "" ? `sequence=${e.sequence}` : "";
    //     }
    //     queryStr += orderBySequence ? "&ordering=-sequence" : "&ordering=sequence";
    //     return queryStr;
    // };

    const fetchData1 = () => {
        const requestOptions = {
            method: "GET",
            headers: authHeader(),
            credentials: "include",
        };
        fetch(API_URL + `/classquiz/?school_class=${classPeriod}&ordering=${'id'}`, requestOptions)
            .then(
                handleResponse
            )
            .then(
                (data) => {
                    setData(data.results)
                })
            .catch(() => {
                setData([]);
            })
    }

    const updateQuiz = (quizNow, quizId) => {

        const status = quizNow ? "start" : "stop";

        const requestOptions = {
            method: "PATCH",
            headers: authHeader(),
            credentials: "include",
        };
        fetch(API_URL + `/classquiz/${quizId}/${status}/`, requestOptions)
            .then(
                handleResponse
            )
            .then(() => {
                fetchData1();
                if (!quizNow) {
                    history.push('#');
                }
            })
    }

    const fetchQuiz = (quizId) => {
        const requestOptions = {
            method: "GET",
            headers: authHeader(),
            credentials: "include",
        };
        fetch(API_URL + `/quiz/${quizId}/`, requestOptions)
            .then(
                handleResponse
            )
            .then((data) => {
                setQuestions(data.questions)
                setCurrentQuiz(data)
                setShowQuiz(true)
            })
    }

    useEffect(() => {
        fetchData1();
        // constructQuery();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(()=>{
        setIsAQuizActive(data.find(item => item.quiz_now === true)?.id);
    },[data])

    const handleQuizShow = (quiz) => () => {
        fetchQuiz(quiz);

    };
    const checkIfQuizNowExists = () => {
        const activeQuiz = data.filter(item => item.quiz_now === true);
        return activeQuiz.length;
    }

    const checkQuiz = checkIfQuizNowExists()

    const getStripedStyles = (index) => {
        return { backgroundColor: index % 2 ? '#F8F8F8' : 'white' };
    }

    function descendingComparator(a,b,orderBy) {
        if(b[orderBy] < a[orderBy]){
            return -1
        }

        if (b[orderBy] < a[orderBy]) {
            return 1
        }

        return 0
    }

    function getComparator(order, orderBy){
        return order === "desc"
            ? (a,b) => descendingComparator(a,b,orderBy)
            : (b,a) => -descendingComparator(a,b,orderBy)
    }

    const sortedRowInformation = (rowArray, comparator) => {
        const stabilizedRowArray = rowArray.map((el, index) => [el, index])
        stabilizedRowArray.sort((a,b) => {
            const order = comparator(a[0], b[0])
            if(order !== 0) return order
            return a[1] - b[1]
        })
        return stabilizedRowArray.map((el) => el[0])
        
    }

    const goToPage = (page) => {
        if(page){
            history.push(page);
        }
    }



    const tableView = (
        <div className="teacher-quiz-container">

            <div className='buttons-container'>
                <div className='back'>
                    <button 
                        className='back-button-quizzes'
                        onClick={() => history.push(`/teacher-schedule`)}
                    >
                        BACK TO CLASSES
                    </button>

                </div>
                <div className='dropdown'>
                    <select
                        className="select-reports"
                        value={""}
                        label="Reports"
                        onChange={(e)=> goToPage(e.target.value)}
                    >
                        <option value={""} disabled>
                            Choose report
                        </option>
                        <option
                            value={`/teacher-schedule/teacher-table/${classPeriod}/${className}`}
                        >
                            Retakes Count
                        </option>
                        <option
                            value={`/class-quizzes/${classPeriod}/${className}/class-retakes`}
                        >
                            Retake Trends
                        </option>
                        <option
                            value={`/class-quizzes/${classPeriod}/${className}/class-standards`}
                        >
                            Standards
                        </option>
                    </select>
                </div>
            </div>

            <TableContainer className='quiz-table-container'>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" style={cellStyle} key="quiz_sequence">
                                <TableSortLabel
                                    style={{ backgroundColor: 'white', textAlign: 'center' }}
                                    active={valueToOrderBy === 'quiz_sequence'}
                                    direction={valueToOrderBy === 'quiz_sequence' ? orderDirection : 'asc'}
                                    onClick={() => createSortHandler('quiz_sequence')}
                                    hideSortIcon={true}
                                >
                                    Quiz Results
                                </TableSortLabel>
                                <p style={{ fontSize: 10, textAlign: 'left', marginBottom: -18, color: 'grey' }}>
                                    Click to reorder quizzes
                                </p>
                            </TableCell>
                            <TableCell align="center" style={cellStyle}>
                                Date Given
                            </TableCell>
                            <TableCell align="center" style={cellStyle}>
                                Students Completed
                            </TableCell>
                            <TableCell align="center" style={cellStyle}>
                                Show Questions
                            </TableCell>
                            <TableCell align="center" style={cellStyle}>
                                Quiz Now
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedRowInformation(data, getComparator(orderDirection, valueToOrderBy)).map((row, index) => (
                            <TableRow key={index}>
                                <TableCell style={{ ...getStripedStyles(index), borderBottom: '1px solid grey', width: 220 }}>
                                    <button
                                        style={{
                                            color: 'grey',
                                            textDecoration: 'underline',
                                            fontWeight: 'bold',
                                            border: 'none',
                                            backgroundColor: 'inherit'
                                        }}
                                        onClick={() =>
                                            history.push(
                                                `/results-page/${classPeriod}/${row.id}/${className}/${row.students_completed}/${row.quiz}/${row.quiz_title}`
                                            )
                                        }
                                    >
                                        {row.quiz_title}
                                    </button>
                                </TableCell>
                                <TableCell style={{ ...getStripedStyles(index), borderBottom: '1px solid grey' }} align="center">
                                    {row.date_given ? row.date_given.substring(0, 10) : row.date_given}
                                </TableCell>
                                <TableCell
                                    align="center"
                                    style={{ ...getStripedStyles(index), borderBottom: '1px solid grey', width: 20 }}
                                >
                                    {row.students_completed}
                                </TableCell>
                                <TableCell
                                    align="center"
                                    style={{ ...getStripedStyles(index), borderBottom: '1px solid grey' }}
                                >
                                    <button className="preview-button" onClick={handleQuizShow(row.quiz)}>
                                        Preview
                                    </button>
                                </TableCell>
                                {row.quiz_now === false ? (
                                    <TableCell
                                        align="center"
                                        style={{ ...getStripedStyles(index), borderBottom: '1px solid grey' }}
                                    >
                                            <button disabled={(row.date_given) || (typeof isAQuizActive !== "undefined" && isAQuizActive !== row.id)} className={(row.date_given) || (typeof isAQuizActive !== "undefined" && isAQuizActive !== row.id) ? "start-button-1" : 'start-button'} onClick={() => updateQuiz(true, row.id)}>
                                                Start
                                            </button>
                                    </TableCell>
                                ) : (
                                    <TableCell align="center" style={{ ...getStripedStyles(index) }}>
                                        <button className="stop-button" onClick={() => updateQuiz(false, row.id)}>
                                            Stop
                                        </button>
                                    </TableCell>
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <QuizShowModal show={showQuiz} setShow={setShowQuiz} questions={questions} quiz={currentQuiz} />
            </TableContainer>
            
            <p style={{ textAlign: 'center', marginTop: 80, color: 'grey' }}>
                All mathematical material is Copyright 2020 Joseph L. Mills, Jr.
            </p>
            <p style={{ textAlign: 'center', color: 'grey', marginTop: -10 }}>
                Do not make copies without written permission
            </p>
        </div>
    );

    return tableView;
};



const mapStateToProps = createStructuredSelector({
    show: selectShow,
});

const mapDispatchToProps = (dispatch) => {
    return {
        toggleShow: () => dispatch(toggleShow()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClassQuizzesContent);


const cellStyle = {
    backgroundColor: 'white',
    color: 'black',
    fontWeight: 'bold',
    fontSize: '14px',
}