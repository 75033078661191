const questionTypes = {
  ADD_CREATOR: "ADD_CREATOR",
  CREATE_QUESTION_REQUEST: "CREATE_QUESTION_REQUEST",
  CREATE_QUESTION_SUCCESS: "CREATE_QUESTION_SUCCESS",
  CREATE_QUESTION_FAILURE: "CREATE_QUESTION_FAILURE",
  CREATE_ANSWER_REQUEST: "CREATE_ANSWER_REQUEST",
  CREATE_ANSWER_SUCCESS: "CREATE_ANSWER_SUCCESS",
  CREATE_ANSWER_FAILURE: "CREATE_ANSWER_FAILURE",
  DELETE_QUESTION_REQUEST: "DELETE_QUESTION_REQUEST",
  DELETE_QUESTION_SUCCESS: "DELETE_QUESTION_SUCCESS",
  DELETE_QUESTION_FAILURE: "DELETE_QUESTION_FAILURE",
  UPDATE_QUESTION_REQUEST: "UPDATE_QUESTION_REQUEST",
  UPDATE_QUESTION_SUCCESS: "UPDATE_QUESTION_SUCCESS",
  UPDATE_QUESTION_FAILURE: "UPDATE_QUESTION_FAILURE",
  UPDATE_ANSWER_REQUEST: "UPDATE_ANSWER_REQUEST",
  UPDATE_ANSWER_SUCCESS: "UPDATE_ANSWER_SUCCESS",
  UPDATE_ANSWER_FAILURE: "UPDATE_ANSWER_FAILURE",
  GET_QUESTION_REQUEST: "GET_QUESTION_REQUEST",
  GET_QUESTION_SUCCESS: "GET_QUESTION_SUCCESS",
  GET_QUESTION_FAILURE: "GET_QUESTION_FAILURE",
  GET_ANSWER_REQUEST: "GET_ANSWER_REQUEST",
  GET_ANSWER_SUCCESS: "GET_ANSWER_SUCCESS",
  GET_ANSWER_FAILURE: "GET_ANSWER_FAILURE",
  GET_USERQUESTION_REQUEST: "GET_USERQUESTION_REQUEST",
  GET_USERQUESTION_SUCCESS: "GET_USERQUESTION_SUCCESS",
  GET_USERQUESTION_FAILURE: "GET_USERQUESTION_FAILURE",
  GETSTANDARDCODE_REQUEST: "GETSTANDARDCODE_REQUEST",
  GETSTANDARDCODE_SUCCESS: "GETSTANDARDCODE_SUCCESS",
  GETSTANDARDCODE_FAILURE: "GETSTANDARDCODEFAILURE",
  QUESTION_STATE_CHANGER: "QUESTION_STATE_CHANGER",
  GET_REVIEWERS_REQUEST: "GET_REVIEWERS_REQUEST",
  GET_REVIEWERS_SUCCESS: "GET_REVIEWERS_SUCCESS",
  GET_REVIEWERS_FAILURE: "GET_REVIEWERS_FAILURE",
  GET_CREATORS_REQUEST: "GET_CREATORS_REQUEST",
  GET_CREATORS_SUCCESS: "GET_CREATORS_SUCCESS",
  GET_CREATORS_FAILURE: "GET_CREATORS_FAILURE",
  GET_SINGLE_QUESTION_REQUEST: "GET_SINGLE_QUESTION_REQUEST",
  GET_SINGLE_QUESTION_SUCCESS: "GET_SINGLE_QUESTION_SUCCESS",
  GET_SINGLE_QUESTION_FAILURE: "GET_SINGLE_QUESTION_FAILURE",
  CLEAR_QUESTION_ERROR: "CLEAR_QUESTION_ERROR",
  GETQUIZSET_REQUEST: "GETQUIZSET_REQUEST",
  GETQUIZSET_SUCCESS: "GETQUIZSET_SUCCESS",
  GETQUIZSET_FAILURE: "GETQUIZSET_FAILURE",
  DELETE_ANSWER: "DELETE_ANSWER",
  DELETE_ANSWER_SUCCESS: "DELETE_ANSWER_SUCCESS",
  DELETE_ANSWER_FAILURE: "DELETE_ANSWER_FAILURE",
};

export default questionTypes;
