import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";

import PastQuizzesTable from './PastQuizzesTable';
import PastQuizzesHeader from './PastQuizzesHeader';
import { handleResponse } from '../../../helpers/handleResponse';
import authHeader from '../../../helpers/authHeader';
import { useSelector } from "react-redux";
import Header from '../Header/Header';
import Footer from '../../Common/footer/footer.component';


const PastQuizzesPage = () => {

    const API_URL = process.env.REACT_APP_API_URL
    const history = useHistory();

    const tSubject = useSelector((state) => state.quiz.active_class_header?.subject)
    const tGrade = useSelector((state) => state.quiz.active_class_header?.grade)
    const tName = useSelector((state) => state.authentication?.user?.userObj)
    let dataLength = 0;

    const [data, setData] = useState("")
    

    const fetchData = () => {
        const requestOptions = {
            method: "GET",
            headers: authHeader(),
            credentials: "include",
        };
        fetch(API_URL + '/stupastqz/retakes/', requestOptions)
            .then(
                handleResponse
            )
            .then((data) => {
                setData(data.results)
            })
    }

    

    useEffect(() => {
        fetchData();
        /*let data = window.performance.getEntriesByType("navigation")[0].type;
        if (data === "reload") {
            history.push("/landing-page")
        } */
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    dataLength = Object.keys(data).length

    return (
        <div class='container-fluid' className='main-student-container'>
            <Header
                quizNumber={dataLength}
                title={"Past Quizzes"}
                name={tName ? tName.first_name + " " + tName.last_name : null}
                gInfo={tGrade}
                subject={tSubject}
                back={true} />
            <PastQuizzesTable data={data} />
        </div>
    )
}

export default PastQuizzesPage;

