import authHeader from "../../helpers/authHeader";
import { handleResponse } from "../../helpers/handleResponse";

const API_URL = process.env.REACT_APP_API_URL

const getAllQuizzes = (pageNumber, queryStr = "") => {
  if (queryStr.split("=")[1] === "Any") queryStr = "";
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    credentials: "include",
  };
  return fetch(
    API_URL + `/quiz/?${queryStr},id&limit=5&offset=${pageNumber}`,
    requestOptions
  ).then(handleResponse);
};

const deleteQuiz = (id) => {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
    credentials: "include",
  };
  return fetch(API_URL + `/quiz/${id}/`, requestOptions).then(handleResponse);
};

const editQuiz = (id, data) => {
  const requestOptions = {
    method: "PATCH",
    headers: authHeader(),
    body: JSON.stringify(data),
    credentials: "include",
  };
  return fetch(API_URL + `/quiz/${id}/`, requestOptions).then(handleResponse);
};

const createQuiz = (data) => {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
    credentials: "include",
  };
  return fetch(API_URL + `/quiz/`, requestOptions).then(handleResponse);
};

const getQuiz = (id) => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    credentials: "include",
  };
  return fetch(API_URL + `/quiz/${id}/`, requestOptions).then(handleResponse);
};

export default {
  getAllQuizzes,
  createQuiz,
  deleteQuiz,
  editQuiz,
  getQuiz,
};
