import React, { useEffect, useState } from 'react';
import { connect, useSelector } from "react-redux";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';
import * as BsIcons from 'react-icons/bs';
import * as AiIcons from 'react-icons/ai';
import * as HiIcons from 'react-icons/hi';
import { Nav } from 'react-bootstrap';
import { LinkContainer } from "react-router-bootstrap";

import { GoHistory } from 'react-icons/go';

import ContactUsModalComponent from '../../Common/contact-us-modal/contact-us-modal.component';
import { selectShow } from '../../../redux/modals/modal.select';
import { toggleShow } from '../../../redux/modals/modals.actions';
import { createStructuredSelector } from "reselect";
import { userActions } from '../../../redux/user/user.actions';
import Footer from '../../Common/footer/footer.component';


import authHeader from '../../../helpers/authHeader';
import { handleResponse } from '../../../helpers/handleResponse';
import { setActiveQuizDetailsAction } from '../../../redux/quiz/quiz.actions';
import quizTypes from '../../../redux/quiz/quiz.types';
import { setRetakesAction } from '../../../redux/quiz/quiz.actions';

import './PastQuizzesTable.css';

const PastQuizzesTable = ({ data, show, toggleShow }) => {

    const API_URL = process.env.REACT_APP_API_URL

    const dispatch = useDispatch();
    const history = useHistory();

  

    const retakes = true;


    const handleLogout = (e) => {
        dispatch(userActions.logout());
    };

    const handleContactUs = () => {
        toggleShow();
    };

    const fetchQuiz = (quiz) => {
        const requestOptions = {
            method: "GET",
            headers: authHeader(),
            credentials: "include",
        };
        fetch(API_URL + `/quiz/${quiz.quiz}/`, requestOptions)
            .then(
                handleResponse
            )
            .then((item) => {
                dispatch({ type: quizTypes.SAVE_STUDENT_CURRENT_QUIZ, payload: item })
                dispatch(setActiveQuizDetailsAction(item))
                history.push(`/quiz-started/${quiz?.school_class}/${quiz?.quiz}`)
            })
    }





    const setQuiz = (item) => {
        fetchQuiz(item)
    }

    const getStripedStyles = (index) => {
        return { backgroundColor: index % 2 ? '#eeeeee' : 'white' };
    }

    const newTableView = (
        <div className='quiz-page-container'>
            <div className='past-quizzes-info'>
                <TableContainer className='past-table-container' >
                    <Table stickyHeader className='past-table'>
                        <TableHead className='past-table-head' >
                            <TableRow className='past-table-row'>
                                <TableCell
                                    className='past-table-crt'
                                    style={{ color: 'black', fontWeight: 'bold', fontSize: 14, backgroundColor: 'white' }}
                                ></TableCell>
                                <TableCell
                                    style={{ color: 'black', fontWeight: 'bold', fontSize: 12, backgroundColor: 'white', width:'15%' }}
                                    >
                                    Class
                                </TableCell>                               
                                <TableCell

                                    className='past-table-quiz'
                                    style={{ color: 'black', fontWeight: 'bold', fontSize: 12, backgroundColor: 'white' }}
                                >
                                    Quiz <br />
                                </TableCell>
                                <TableCell
                                    className='past-table-date-recent'
                                    style={tableCellsStyle}
                                >
                                    Date Of Most Recent
                                </TableCell>
                                <TableCell
                                    className='past-table-number-recent'
                                    style={tableCellsStyle}
                                >
                                    Number Of Times Taken
                                </TableCell>
                                <TableCell
                                    className='past-table-score-recent'
                                    style={tableCellsStyle}
                                >
                                    Score Of Most Recent
                                </TableCell>
                                
                                <TableCell
                                    className='past-table-button'
                                    style={tableCellsStyle}
                                >

                                </TableCell>
                                <TableCell
                                    className='past-table-score-recent'
                                    style={tableCellsStyle}
                                >
                                    Retake Summary
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody className='past-table-body' >
                            {data ? data.map((row, index) => (
                                <TableRow className='past-table-rows' key={index}>
                                    <TableCell className='past-cell'
                                        style={{ color: 'grey', ...getStripedStyles(index), borderBottom: '2px solid grey' }}
                                    >
                                        {index + 1}
                                    </TableCell>
                                    <TableCell className='past-cell'
                                        style={{ fontWeight: 'bold', fontSize: 12, color: 'grey', borderBottom: '2px solid grey', ...getStripedStyles(index) }}
                                    >
                                        {row?.school_class_name}
                                    </TableCell>
                                    <TableCell className='past-cell'
                                        style={{ fontWeight: 'bold', fontSize: 12, color: 'grey', borderBottom: '2px solid grey', ...getStripedStyles(index) }}
                                    >
                                        {row?.quiz_title}
                                    </TableCell>
                                    <TableCell align="center" className='past-cell'
                                        style={{ tableCellsStyle2, ...getStripedStyles(index), borderBottom: '2px solid grey' }}
                                    >
                                        {row?.modified.substring(0, 10)}
                                    </TableCell>
                                    <TableCell align="center" className='past-cell'
                                        style={{ tableCellsStyle2, ...getStripedStyles(index), borderBottom: '2px solid grey' }}
                                    >
                                        {row?.retake_counter}
                                    </TableCell>
                                    <TableCell align="center" className='past-cell'
                                        style={{ tableCellsStyle2, ...getStripedStyles(index), borderBottom: '2px solid grey' }}
                                    >
                                        {row?.score}
                                    </TableCell>
                                    <TableCell align="center" style={{ ...getStripedStyles(index), borderBottom: '2px solid grey' }} className='past-cell'>
                                        <button
                                            href="javascript:;"
                                            className='retake-button'
                                            onClick={() => {
                                                setQuiz(row)
                                                dispatch(setRetakesAction(retakes));
                                            }}>
                                            RETAKE
                                        </button>
                                    </TableCell>
                                    <TableCell align="center" className='past-cell'
                                        style={{ tableCellsStyle2, ...getStripedStyles(index), borderBottom: '2px solid grey' }}
                                    >
                                        <GoHistory size="30" onClick={() => history.push(`/past-quizzes/${row?.quiz_title}`)} />
                                    </TableCell>
                                </TableRow>
                            )) : null}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>

    )

    return newTableView;


};
const mapStateToProps = createStructuredSelector({
    show: selectShow,
});

const mapDispatchToProps = (dispatch) => {
    return {
        toggleShow: () => dispatch(toggleShow()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PastQuizzesTable);


const tableCellsStyle = {
    color: 'black',
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: 12,
    backgroundColor: 'white'
}

const tableCellsStyle2 = {
    color: 'grey',
    textAlign: 'center',
    fontSize: 12,
}

