import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { userActions } from '../../../redux/user/user.actions';
import { toggleShow } from '../../../redux/modals/modals.actions';
import { selectShow } from '../../../redux/modals/modal.select';
import { createStructuredSelector } from "reselect";
import { handleResponse } from '../../../helpers/handleResponse';
import authHeader from '../../../helpers/authHeader';

import ReactHighcharts from "highcharts-react-official";
import Highcharts from "highcharts";

import { ThemeProvider, createTheme } from '@mui/material/styles';

import ContactUsModalComponent from '../../Common/contact-us-modal/contact-us-modal.component';
import RetakeComponent from './RetakeComponent';

import * as AiIcons from 'react-icons/ai';
import * as HiIcons from 'react-icons/hi';

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { ClassNames } from '@emotion/react';
import './ClassRetakesContent.css';

const ClassRetakesContent = ({show, toggleShow, class_id, class_name }) => {
    const API_URL = process.env.REACT_APP_API_URL
    const dispatch = useDispatch();
    const history = useHistory();

    const [ data, setData ] = useState(null)
    const [ rowData, setRowData ] = useState(data && data[0]);
    const [ selectedRow, setSelectedRow] = useState(null);

    const handleLogout = () => {
        dispatch(userActions.logout());
    };

    const handleContactUs = () => {
        toggleShow();
    };

    const fetchData = () => {
        const requestOptions = {
            method: "GET",
            headers: authHeader(),
            credentials: "include",
        };
        fetch(API_URL + `/report/teacher/retakes/${class_id}/`, requestOptions)
            .then(
                handleResponse
            )
            .then((data) => {
                setData(data)
            })
    }

    useEffect(() => {
        fetchData();
    },[])

    useEffect(() => {
        if(data) {
            setRowData(data[0])
            setSelectedRow(0)
        }
    },[data])

    const name = rowData && rowData.first_name + ' ' + rowData.last_name;
    
    return (
        <div className="class-retakes-info">
            <button
                className="retakes-back-button"
                onClick={() => history.push(`/class-quizzes/${class_id}/${class_name}`)}
            >
                BACK TO QUIZZES
            </button>

            <div className='retakes-table' id="retakes-table">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{fontSize: 16, textAlign: 'center', fontWeight: 'bold'}}>Students
                                    <p style={{fontSize: 12, textAlign: 'center', fontWeight: 'normal', borderTop: '1px solid grey'}}>Click on student name to view their reports</p>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody >
                            {data &&
                                data.map((item, index) => (
                                    <TableRow
                                        key={index}
                                        onClick={() => setSelectedRow(index)}
                                        selected={selectedRow === index}
                                        className="selected-student"
                                    >
                                        <TableCell onClick={() => setRowData(item)} style={{ cursor: 'pointer' }}>
                                            {item.first_name + ' ' + item.last_name}
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
            </div>

            <div style={{ width: '70%', float: 'right', overflowX: 'scroll'}} id="retakes-chart">
                <p style={{ fontWeight: 'bold', textAlign: 'center', fontSize: '25px' }}>Quiz Progress</p>
                {name && (
                    <p style={{ fontWeight: 'bold' }}>
                        Student Name: <span style={{ paddingleft: '30px', color: 'grey' }}>{name}</span>
                    </p>
                )}
                {class_name && (
                    <p style={{ fontWeight: 'bold' }}>
                        Class Name: <span style={{ paddingleft: '30px', color: 'grey' }}>{class_name}</span>
                    </p>
                )}
                <RetakeComponent data={rowData} class_name={class_name} />
            </div>
        </div>
    );

    
}

const mapStateToProps = createStructuredSelector({
    show: selectShow,
});

const mapDispatchToProps = (dispatch) => {
    return {
        toggleShow: () => dispatch(toggleShow()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClassRetakesContent);