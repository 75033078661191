import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import './StudentRegistration.css';

import { Button } from '@mui/material';
import { Form } from 'react-bootstrap';
import authHeader from '../../../helpers/authHeader';
import { handleResponse } from '../../../helpers/handleResponse';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as Logo } from '../../../assets/img/logo.svg';
const StudentRegistration = props => {
    const API_URL = process.env.REACT_APP_API_URL;
    const authenticationToken = props.match.params.authenticationToken;

    const history = useHistory();
    const firstNameRef = useRef();
    const lastNameRef = useRef();
    const [firstNameError, setFirstNameError] = useState(null);
    const [lastNameError, setLastNameError] = useState(null);
    const [registerError, setRegisterError] = useState(null);
    const [submitted, setSubmitted] = useState(false);

    const registerStudent = () => {
        const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            credentials: 'include',
            body: JSON.stringify({
                first_name: firstNameRef.current.value.trim(),
                last_name: lastNameRef.current.value.trim(),

                registration_token: authenticationToken
            })
        };
        fetch(API_URL + `/clever/signup/signupStudent/`, requestOptions)
            .then(handleResponse)
            .then(data => {
                if (data.firstname || data.lastname) {
                    setRegisterError(data.firstname || data.lastname); setSubmitted(false)
                } else {setSubmitted(true)};
            })
            .catch(error => {
                setRegisterError(error);
            });
    };
    const handleFormSubmit = e => {
        e.preventDefault();
        setFirstNameError(null);
        setLastNameError(null);
        setRegisterError(null);
        const firstNameValue = firstNameRef.current.value.trim();
        const lastNameValue = lastNameRef.current.value.trim();
        if (firstNameValue !== '' && lastNameValue !== '') {
            registerStudent();
        } else {
            if (firstNameValue === '')
                setFirstNameError('Oh no! You forgot to enter your first name. Please enter it in order to continue!');
            if (lastNameValue === '')
                setLastNameError("Oops! You didn't write your last name. Please write it in order to continue!");
        }
    };
    const SubmittedResponse = () => {
        const [seconds, setSeconds] = useState(5);
        const [started, setStarted] = useState(false);
        function countdown() {
            const countInterval = setInterval(() => {
                setSeconds(prev => prev - 1);

                if (seconds <= 0) {
                    clearInterval(countInterval);
                }
            }, 1000);
        }
        if (submitted && !started) {
            setTimeout(() => {
                history.push('/login');
            }, 5000);
            countdown();
            setStarted(true);
        }

        return (
            <div>
                <h1 style={{ fontSize: '2em' }} className="text-center form-heading">
                    <FormattedMessage
                        id="pageRegistrationResponse"
                        defaultMessage="Congratulations!"
                        description="Header of the successful registration response"
                    />
                </h1>
                <h2 style={{ fontSize: '1.5em', textAlign: 'center', margin: '2rem 0' }}>You have been registered!</h2>
                <p style={{ textAlign: 'center', margin: '2rem 0' }}>Please sign in using the "Log in with Clever" button.</p>
                <p style={{ textAlign: 'center', margin: '2rem 0' }}>Returning to login page in...{seconds}</p>
            </div>
        );
    };
    useEffect(()=>{console.log(submitted)},[submitted])
    return (
        <div className="student-registration-page-container-form">
            <div className="student-registration-page-container-content-form">
                <Logo
                    style={{
                        display: 'block',
                        width: 'auto',
                        height: 'auto',
                        maxWidth: '100%',
                        maxHeight: '100%',
                        margin: '2rem auto 0'
                    }}
                />
                {!submitted ? (
                    <>
                        <h1 className="text-center form-heading">
                            <FormattedMessage
                                id="pageRegistrationForm"
                                defaultMessage="Create Your Account"
                                description="Header of the registration form page"
                            />
                        </h1>
                        <h2 className="text-center form-heading" style={{ fontSize: '1em' }}>
                            <FormattedMessage
                                id="pageRegistrationFormStudent"
                                defaultMessage="After registering you'll be able to join your class!"
                                description="Header of the registration form page for student"
                            />
                        </h2>
                        {registerError ? (
                            <p className="student-registration-page-container-form-register-error">{registerError}</p>
                        ) : null}
                        <div className="student-registration-page-container-content-form-style">
                            <Form>
                                <Form.Group controlId="formFirstName" className="relative" style={{ marginBottom: '2.5rem' }}>
                                    <Form.Control
                                        type="text"
                                        className="input-style input-text"
                                        name="firstName"
                                        ref={firstNameRef}
                                        style={{ marginBottom: '1rem' }}
                                    />

                                    <span className="floating-label" style={{ top: '-25px', width: '100%' }}>
                                        First Name
                                    </span>
                                    {firstNameError ? <p className="student-form-input-error">{firstNameError}</p> : null}
                                </Form.Group>
                                <Form.Group controlId="formLastName" className="relative" style={{ marginBottom: '2.5rem' }}>
                                    <Form.Control
                                        type="text"
                                        className="input-style input-text"
                                        name="lastName"
                                        ref={lastNameRef}
                                        style={{ marginBottom: '1rem' }}
                                    />
                                    <span className="floating-label" style={{ top: '-25px', width: '100%' }}>
                                        Last Name
                                    </span>
                                    {lastNameError ? <p className="student-form-input-error">{lastNameError}</p> : null}
                                </Form.Group>
                                <Button
                                    variant="primary"
                                    type="submit"
                                    className="text-center custom-btn student-registration-page-container-continue-button"
                                    onClick={handleFormSubmit}
                                >
                                    Register
                                </Button>
                            </Form>
                        </div>
                    </>
                ) : (
                    <SubmittedResponse />
                )}
            </div>
        </div>
    );
};
//     return (
//         <div>
//             <div className="class-code-div">
//                 <div className="class-code-box">
//                     <p className="class-code-p">Class Code</p>
//                 </div>
//                 <div className="class-code-box">
//                 </div>
//             </div>

//             <p className="instructions">Please enter the code given by your teacher.</p>

//             <div className="main">
//                 <div className="box" style={{}}>
//                     <p className="form-title">Your first name</p>
//                     <CustomTextField
//                         style={{ width: '30vw', marginLeft: '20px' }}
//                         id="outlined-basic"
//                         label="required"
//                         variant="outlined"
//                         required
//                         focused

//                         value={firstName}
//                         onChange={e => setFirstName(e.target.value)}
//                         error={submitted && firstName === '' ? true : false}
//                         helperText={
//                             submitted && firstName === '' ? 'Please enter a valid first name (must contain only letters) ' : ' '
//                         }
//                     ></CustomTextField>
//                 </div>

//                 <div className="box">
//                     <p className="form-title">Your last name</p>
//                     <CustomTextField
//                         style={{ width: '30vw', marginLeft: '20px', borderColor: '#B1B1FF' }}
//                         id="outlined-basic"
//                         label="required"
//                         variant="outlined"
//                         required
//                         focused
//                         value={lastName}
//                         onChange={e => setLastName(e.target.value)}
//                         error={submitted && firstName === '' ? true : false}
//                         helperText={
//                             submitted && firstName === '' ? 'Please enter a valid last name (must contain only letters) ' : ' '
//                         }
//                     ></CustomTextField>
//                 </div>
//             </div>

//             <button className="button-student-registration" onClick={handleSubmit}>
//                 Save
//             </button>

//             <Dialog open={open}>
//                 <div style={{ display: 'flex', alignItems: 'center', height: '10vh', width: '30vw' }}>
//                     <CelebrationIcon className="celebration-icon" fontSize="large" />
//                     <DialogTitle
//                         style={{
//                             color: '#9932CC',
//                             fontWeight: 'bold',
//                             fontSize: '30px',
//                             textAlign: 'center',
//                             fontStyle: 'oblique'
//                         }}
//                     >
//                         Thank you
//                     </DialogTitle>
//                     <CelebrationIcon className="celebration-icon" fontSize="large" />
//                 </div>
//                 <DialogContent>
//                     <DialogContentText
//                         style={{
//                             color: 'black',
//                             fontWeight: 'bold',
//                             fontStyle: 'italic',
//                             textAlign: 'center',
//                             marginTop: '10px',
//                             height: '5vh',
//                             fontSize: '22px'
//                         }}
//                     >
//                         Your Teacher will approve your account soon.
//                     </DialogContentText>
//                     <DialogContentText
//                         style={{
//                             color: 'grey',
//                             fontWeight: 'bold',
//                             fontStyle: 'italic',
//                             textAlign: 'center',
//                             marginTop: '20px',
//                             marginBottom: '20px'
//                         }}
//                     >
//                         You will be redirected to the login page.
//                     </DialogContentText>
//                 </DialogContent>
//             </Dialog>
//         </div>
//     );
// };

export default StudentRegistration;
