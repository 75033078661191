import React from 'react';
import { useSelector } from 'react-redux';
import TeacherHeader from './TeacherHeader';
import TeacherScheduleContent from './TeacherScheduleContent';

const TeacherSchedule = () => {

    const tName = useSelector((state) => state.authentication.user?.userObj)
    const role = useSelector((state) => state.authentication.user?.userObj?.role)

    return (
        <div>
            <TeacherHeader 
                title={"Classes"} 
                name={tName ? tName.first_name + " " + tName.last_name : null}
                role={role}
                />
            <TeacherScheduleContent />
        </div>
    )
}

export default TeacherSchedule;