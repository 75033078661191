import React, { useEffect, useCallback, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Select,
    Button,
    MenuItem,
    FormControl,
    Snackbar,
    Alert,
    IconButton,
    Modal
} from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@mui/icons-material/Close';
import authHeader from '../../../../helpers/authHeader';
import { handleResponse } from '../../../../helpers/handleResponse';
import './TeacherStudentsTable.css';

const useStyles = makeStyles(() => ({
    fieldsetLegend: {
        '& legend': {
            width: 'unset'
        }
    },
    buttonHover: {
        '&:hover': {
            backgroundColor: 'red'
        }
    }
}));

/**
 * Renders a table component that displays a list of students for a given class.
 *
 * @param {object} classId - The ID of the class.
 * @return {JSX.Element} The rendered table component.
 */

const TeacherStudentsTable = ({ classId }) => {
    const [classRoll, setClassRoll] = useState([]);
    const [selectedStudentIds, setSelectedStudentIds] = useState([]);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarVariant, setSnackbarVariant] = useState('success');
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [studentToRemove, setStudentToRemove] = useState({});
    const classes = useStyles();
    const initialStatuses = {};
    classRoll.forEach(student => {
        initialStatuses[student.student] = String(student.enrolled);
    });
    const [initialStatusesSet] = useState(initialStatuses);
    const defaultValues = {};

    classRoll.forEach(student => {
        defaultValues[student.student] = String(student.enrolled);
    });
    const handleStatusChange = (event, studentId) => {
        setSelectedStudentIds(prevIds => {
            const updatedIds = new Set(prevIds);

            if (event.target.value === 'true') {
                updatedIds.add(studentId);
            } else {
                updatedIds.delete(studentId);
            }

            return Array.from(updatedIds);
        });
    };
    const API_URL = process.env.REACT_APP_API_URL;

    const handleRemove = (classrollId, stdId) => {
        const requestOptions = {
            method: 'DELETE',
            headers: authHeader(),
            credentials: 'include'
        };

        fetch(API_URL + `/classroll/${classrollId}/`, requestOptions)
            .then(handleResponse)
            .then(data => {
                if (data.status_code === 400) {
                    setSnackbarMessage(data.detail);
                    setSnackbarVariant('error');
                    setOpenSnackbar(true);
                } else {
                    setSelectedStudentIds(prevIds => prevIds.filter(studentId => studentId !== stdId));
                    setSnackbarMessage(data.detail);
                    setSnackbarVariant('success');
                    setOpenSnackbar(true);
                    fetchClassRoll();
                }
            })
            .catch(error => {
                setSnackbarMessage(error || error.detail || error.message);
                setSnackbarVariant('error');
                setOpenSnackbar(true);
            });
    };
    const handleSaveChanges = () => {
        if (selectedStudentIds.length === 0) {
            setSnackbarMessage('You must select at least one student.');
            setSnackbarVariant('error');
            setOpenSnackbar(true);
            return;
        }
        const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            credentials: 'include',
            body: JSON.stringify({
                student_ids: selectedStudentIds
            })
        };

        fetch(API_URL + `/classroll/${classId}/approve/`, requestOptions)
            .then(handleResponse)
            .then(data => {
                if (data.status_code === 400) {
                    setSnackbarMessage(data.detail);
                    setSnackbarVariant('error');
                    setOpenSnackbar(true);
                } else {
                    setSnackbarMessage(data.detail);
                    setSnackbarVariant('success');
                    setOpenSnackbar(true);
                    setSelectedStudentIds([]);
                    handleDeleteModalClose();
                    fetchClassRoll();
                }
            })
            .catch(error => {
                setSnackbarMessage(error || error.detail || error.message);
                setSnackbarVariant('error');
                setOpenSnackbar(true);
            });
    };

    const fetchClassRoll = useCallback(() => {
        const requestOptions = {
            method: 'GET',
            headers: authHeader(),
            credentials: 'include'
        };
        fetch(API_URL + `/classroll/?school_class=${classId}`, requestOptions)
            .then(handleResponse)
            .then(data => {
                setClassRoll(data.results);
            });
    }, [API_URL, classId]);

    useEffect(() => {
        if (classId) fetchClassRoll();
    }, [classId, fetchClassRoll]);

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };
    const handleDeleteModalClose = () => {
        setOpenDeleteModal(false);
        setStudentToRemove({});
    };

    return (
        <div style={{ width: '100%' }}>
            <TableContainer className="table-container">
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell className="table-header-cell" align="center">
                                First Name
                            </TableCell>
                            <TableCell
                                className="table-header-cell"
                                align="center"
                                style={{ borderInlineStart: '2px solid #dee2e6' }}
                            >
                                Last Name
                            </TableCell>
                            <TableCell
                                className="table-header-cell"
                                align="center"
                                style={{ borderInlineStart: '2px solid #dee2e6' }}
                            >
                                Enrollment Status
                            </TableCell>
                            <TableCell
                                className="table-header-cell"
                                align="center"
                                style={{ borderInlineStart: '2px solid #dee2e6' }}
                            >
                                Remove from this list
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {classRoll.length > 0 ? (
                            classRoll.map(student => (
                                <TableRow key={student.student}>
                                    <TableCell className="table-body-cell" align="center">
                                        {student.student_first_name}
                                    </TableCell>
                                    <TableCell className="table-body-cell cell-border-left" align="center">
                                        {student.student_last_name}
                                    </TableCell>
                                    <TableCell
                                        className={`table-body-cell cell-border-left ${classes.fieldsetLegend}`}
                                        align="center"
                                    >
                                        <FormControl variant="standard">
                                            {student.enrolled ? (
                                                'Enrolled'
                                            ) : (
                                                <Select
                                                    value={
                                                        selectedStudentIds.includes(student.student)
                                                            ? 'true'
                                                            : initialStatusesSet[student.student] ||
                                                              defaultValues[student.student] ||
                                                              'false'
                                                    }
                                                    onChange={e => handleStatusChange(e, student.student)}
                                                >
                                                    <MenuItem value="true">Enrolled</MenuItem>
                                                    <MenuItem value="false">Inactive</MenuItem>
                                                </Select>
                                            )}
                                        </FormControl>
                                    </TableCell>
                                    <TableCell className="table-body-cell cell-border-left" align="center">
                                        <Button
                                            onClick={() => {
                                                setStudentToRemove(student);
                                                setOpenDeleteModal(true);
                                            }}
                                            className="student-remove-button"
                                        >
                                            Remove
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={4} className="table-body-cell" align="center">
                                    No data available.
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <div style={{ width: '100%', textAlign: 'center' }}>
                <Button onClick={handleSaveChanges} variant="outlined" className={'save-changes-button'}>
                    Save Changes
                </Button>
            </div>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert severity={snackbarVariant} sx={{ width: '100%', display: 'flex', alignItems: 'center' }} variant="filled">
                    {snackbarMessage}
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackbarClose}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </Alert>
            </Snackbar>
            <Modal open={openDeleteModal} onClose={handleDeleteModalClose} className="delete-student-modal">
                {studentToRemove && (
                    <div className="modal-content-class">
                        <div style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '1rem', marginTop: '2rem' }}>
                            Remove student
                        </div>
                        <p>
                            Are you sure you want to remove {studentToRemove.student_first_name}{' '}
                            {studentToRemove.student_last_name} from the list?
                        </p>
                        {studentToRemove.enrolled && (
                            <div className="modal-warning-text">
                                <p>
                                    Warning - This student is currently Enrolled in the class; if you continue, the student will
                                    be removed from the class and from this list. You may wish, instead, to set the student to
                                    Inactive, so you can restore the student to the class.{' '}
                                </p>
                            </div>
                        )}
                        <div className="modal-buttons-container">
                            <Button onClick={handleDeleteModalClose} className="delete-modal-cancel-button">
                                Cancel
                            </Button>
                            <Button
                                onClick={() => {
                                    handleRemove(studentToRemove.id, studentToRemove.student);
                                    handleDeleteModalClose();
                                }}
                                className="delete-modal-confirm-button"
                            >
                                Remove
                            </Button>
                        </div>
                    </div>
                )}
            </Modal>
        </div>
    );
};

export default TeacherStudentsTable;
