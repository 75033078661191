/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl";
import { SingleSelect } from "react-select-material-ui";
import { Col, Form, Button, Container } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import { setQuestionType } from "../../../redux/local/local.actions";
import questionActions, {
  clearQuestionError,
} from "../../../redux/question/question.action";
import {selectGradeLevel, selectStandardCode, selectStandardSet} from "../../../redux/question/question.select";
import MathquillInput from "../mathquill-input/mathquill-input.component";
import ReviewInput from "../../Reviewer/review-input/review-input.component";
import MessageBar from "../../ui/message-bar/message-bar.component";
import { validateCreateQuestion } from "./../../../helpers/validation/validationQuestion";
import { ReactComponent as A } from "../../../assets/img/A.svg";
import { ReactComponent as B } from "../../../assets/img/B.svg";
import { ReactComponent as CreateUserIcon } from "../../../assets/img/create-user-icon.svg";
import { ReactComponent as MinusCircle } from "../../../assets/img/minus-circle.svg";
import McOptionSerial from "../mc-option-serial/mc-option-serial.component";

import "./question.styles.css";
import { selectAnswerStatus } from "./../../../redux/question/question.select";
import { selectQuestionFormState } from "../../../redux/questionFormState/questionFormState.select";
import { selectAnswerFormState } from "./../../../redux/questionFormState/questionFormState.select";
import { selectUpdatingQuestion } from "./../../../redux/question/question.select";
import { selectCreatingQuestion } from "./../../../redux/question/question.select";
import { selectReviewers } from "./../../../redux/question/question.select";
import { selectCreators } from "./../../../redux/question/question.select";
import { selectQuestionError } from "./../../../redux/question/question.select";

import AuthorDetails from "../../Reviewer/author-details/author-details.component";
import Layout from "../../ui/layout/layout.component";
import {
  questionFormStateEditFalse,
  resetAnswerState,
} from "../../../redux/questionFormState/questionFormState.action";
import Footer from '../../Common/footer/footer.component';
// import settingActions from '../../../redux/setting/setting.actions';
import { selectCreatingCreator } from "../../../redux/setting/setting.select";
import {arrayToObject} from "../../../helpers/utils";

const millsDiffLevel = [
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
];
const DOK = [
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
];
const questionStyles = [
  { value: "Numeric", label: "Numeric" },
  { value: "Word", label: "Word" },
  { value: "Graphic", label: "Graphic" },
];

const Question = ({ questionType }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const imageRef = useRef(null);
  const apiErrorRef = useRef(null);
  const standardCode = useSelector(selectStandardSet);
  const standardCodeData = useSelector(selectStandardCode);
  const standardCodeDataGrade = useSelector(selectGradeLevel);
  const reviewers = useSelector(selectReviewers);
  const creators = useSelector(selectCreators);
  const creatingAnswer = useSelector(selectAnswerStatus);
  const initialFormState = useSelector(selectQuestionFormState);
  const initialAnswer = useSelector(selectAnswerFormState);
  const creatingCreator = useSelector(selectCreatingCreator);
  const error = useSelector(selectQuestionError);

  const updatingQuestion = useSelector(selectUpdatingQuestion);
  const creatingQuestion = useSelector(selectCreatingQuestion);
  const localUser =
    localStorage.getItem("user") && localStorage.getItem("user") !== "undefined"
      ? JSON.parse(localStorage.getItem("user"))
      : undefined;
  const isReview = localUser && localUser.userObj.reviewQuestions;
  const [formState, setFormState] = useState({
    ...initialFormState,
    question_type: questionType,
  });

  const [answer, setAnswer] = useState({});
  const [mcOptionField, setMcOptionField] = useState([
    { serial: 0, value: "", isChecked: false },
  ]);
  const [imageButtonText, setImageButtonText] = useState("Add Image");
  const [image, setImage] = useState({ file: "", url: "" });
  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
  
    //let data = window.performance.getEntriesByType("navigation")[0].type;
    if (performance.navigation.type === 1 && !isReview){
      history.push("/my-questions")
    } else if (performance.navigation.type === 1 && isReview){
      history.push("/my-reviews")
    }
    if (questionType === "mc") {
      if (
        initialAnswer &&
        Object.keys(initialAnswer).length > 0
      ) {
        const arrAnswers = Object.keys(initialAnswer).map((key, index) => {
          return { id: initialAnswer[key].id, serial: key, value: initialAnswer[key].content, isChecked: initialAnswer[key].correct }
        })

        setMcOptionField(arrAnswers);
      }
    } else {
      setAnswer(initialAnswer);
    }
    //standard_code: { standard_code: set, index: i },
    let stCode;
    let stGrade;
    let stSet;
    let stI;

    if(standardCode){
    standardCode.map((item, i) => {
      if (item.standard_code === initialFormState.standard_code && item.standard_set === initialFormState.standard_set && item.grade === initialFormState.grade_level) {
        stCode = item.standard_code;
        stSet = item.standard_set;
        stGrade = item.grade;
        stI = i;
      }
      return true;
    })
  }

    const initialObject = {
       ...initialFormState, 
        standard_code: { standard_code: stCode, index: stI}, 
        standard_set: { standard_set: stSet, index: stI },  
        grade: { grade: stGrade, index: stI }, 
      }
      
    setFormState((prevState) => ({ ...prevState, ...initialObject }));
  }, [initialAnswer, initialFormState, questionType]);

  useEffect(() => {
    dispatch(questionActions.getStandardCode());
    dispatch(questionActions.getReviewers());
    dispatch(questionActions.getCreators());
  }, []);

  useEffect(() => {
    if (creatingCreator === "success") {
      dispatch(questionActions.getCreators());
    }
  }, [creatingCreator]);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && submitted) {
      submit();
    }
  }, [errors]);

  useEffect(() => {
    if (apiErrorRef) {
      if (apiErrorRef.current) {
        apiErrorRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [creatingAnswer]);

  useEffect(() => {
    if (isReview) {
      setImageButtonText("Image Added");
      setImage({ file: "", url: formState.image });
    }
  }, [isReview]);

  useEffect(() => {
    if (formState.edit) {
      setImage({ file: "", url: formState.image });
    }
  }, [formState.edit]);

  const handleRadioChange = (e) => {
    const { value } = e.target;
    setFormState((prevForm) => ({
      ...prevForm,
      approved_status: parseInt(value),
    }));
  };

  const handleSelectChange = (name) => (e) => {
    if (e.set) {
      var { set, i } = e;
    }
    if (name === "standard_set") {
      /*const stCodeValue1 = formState && standardCode ?
        standardCode.filter((item, index) => item.standard_code === formState.standard_code).map(item => {
          return { index: standardCode.indexOf(item), standard_code: item }
        })[0] : null*/
      standardCode &&
        setFormState((prevFormState) => ({
          ...prevFormState,
          // standard_code: standardCode && standardCode['Standard Code'][e.i],
          // grade_level: standardCode && standardCode['Grade'][e.i],
          standard_set: { standard_set: set, index: i },
          standard_code: null,
          grade: null,
        }));
    } else if (name === "standard_code") {
      standardCode &&
      setFormState((prevFormState) => ({
        ...prevFormState,
        // standard_code: standardCode && standardCode['Standard Code'][e.i],
        // grade_level: standardCode && standardCode['Grade'][e.i],
        standard_code: { standard_code: set, index: i },
      }));
    } else if (name === "grade") {
      standardCode &&
      setFormState((prevFormState) => ({
        ...prevFormState,
        grade: {grade: set, index: i}
      }))
    }else {
      setFormState((prevFormState) => ({ ...prevFormState, [name]: e }));
    }

    setErrors({ ...errors, [name]: "" });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "summative_status" || name === "state_model") {
      const { checked } = e.target;
      setFormState((prevFormState) => ({
        ...prevFormState,
        [name]: checked,
      }));
    } else {
      setFormState((prevFormState) => ({ ...prevFormState, [name]: value }));
    }
    if (name === "add_creator") {
      // setCreatorValue(value);
    }
    // setCreatorError('');
    setErrors({ ...errors, [name]: "" });
    dispatch(clearQuestionError());
  };

  const handleQuestionChange = (e) => {
    setFormState((prev) => ({ ...prev, value: e.trim() }));
    setErrors({ ...errors, value: "" });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    !isReview ? setErrors(validateCreateQuestion(formState)) : setErrors({});
    setSubmitted(true);
    dispatch(clearQuestionError());
    
  };

  const handleCancel = () => {
    dispatch(setQuestionType(false));
    dispatch(questionFormStateEditFalse());
    dispatch(resetAnswerState());
    isReview ? history.push("/my-reviews") : history.push("#");
    dispatch(clearQuestionError());
  };

  const handleImage = (e) => {
    let image = e.target.files[0];
    setImageButtonText("Image added");
    setImage({ file: image, url: URL.createObjectURL(image) });
  };

  const handleImageClick = (e) => {
    if (!image.file) {
      imageRef.current.click();
    } else {
    }
  };

  const handleAnswerChange = (name) => (e) => {
    // let value;
    let checked;
    if (e.target) {
      checked = e.target.checked;
      // value = e.target.value;
    }
    if (questionType === "sa" || questionType === "la") {
      setAnswer((prevAnswer) => ({ ...prevAnswer, content: e.trim() }));
    } else if (questionType === "t/f") {
      if (name === "true") {
        setAnswer((prevAnswer) => ({
          ...prevAnswer,
          content: {
            true: !!checked,
            false: !checked,
          },
        }));
      } else {
        setAnswer((prevAnswer) => ({
          ...prevAnswer,
          content: {
            true: !checked,
            false: !!checked,
          },
        }));
      }
    }
  };

  const handleMcOptionChange = (serial, input) => (e) => {
    if (input === "text") {
      const { value } = e.target;
      const newMcOptionField = [...mcOptionField];
      newMcOptionField[serial].value = value;
      setMcOptionField(newMcOptionField);
      setAnswer({...answer, [serial]: {
        ...answer[serial],
        value: newMcOptionField[serial].value,
        id: newMcOptionField[serial].id,
        serial: serial,
      },
      });
    } else {
      let unchecked_obj_id = null
      const newMcOptionField = [...mcOptionField];
      newMcOptionField.forEach((obj, index) => {
        if(obj.serial != serial){
          if(obj.isChecked)
            unchecked_obj_id = index;
          obj.isChecked = false;
        }
        else
          obj.isChecked = !newMcOptionField[serial].isChecked;
      });
      
      Object.keys(answer).forEach(function(key,index){
        
        if(key != serial)
          answer[key].isChecked = false;
        
      });
      setMcOptionField(newMcOptionField);
      if(unchecked_obj_id !== null)
        setAnswer({...answer, [serial]: {
          ...answer[serial],
          isChecked: newMcOptionField[serial].isChecked,
          id: newMcOptionField[serial].id,
          serial: serial,
        },[unchecked_obj_id]: {
          ...answer[unchecked_obj_id],
          isChecked: newMcOptionField[unchecked_obj_id].isChecked,
          id: newMcOptionField[unchecked_obj_id].id,
          serial: unchecked_obj_id,
        },
        });
      else
        setAnswer({...answer, [serial]: {
          ...answer[serial],
          isChecked: newMcOptionField[serial].isChecked,
          id: newMcOptionField[serial].id,
          serial: serial,
        },
        });
    }
  };

  const submit = () => {
    let formData = new FormData();
    const formDataArray = Object.entries(formState);
    let stCode
    let stSet
    let stGrade
    for (const [key, value] of formDataArray) {
      if (
        !(key === "edit") &&
        !(key === "id") &&
        !(key === "deleted") &&
        !(key === "deleted_status") &&
        !(key === "approved_status") &&
        !(key === "reviewer_feedback")
      ) {
        if( key === "dok" && value === null){
          formData.append(key, '')
        }else if (key === "standard_code") {
          stCode = formDataArray.filter((item) => item[0] === key)[0][1].standard_code;
          formData.append('standard_code', stCode);
        } else if (key === "standard_set") {
          stSet = formDataArray.filter((item) => item[0] === key)[0][1].standard_set
          formData.append('standard_set', stSet );
        } else if (key === "grade") {
          stGrade = formDataArray.filter((item) => item[0] === key)[0][1].grade;
          formData.append('grade', stGrade);
        } else if (key === "question_type") {
          formData.append(key, questionType);
        } else if (!(key === "image")) {
          formData.append(key, value);
        }
      }
    }
    const standard = standardCode.filter((item) => item.standard_code === stCode && item.standard_set === stSet && item.grade === stGrade)

    if(standard.length) {
      formData.append("standard", standard[0].id)
      if (image.file) {
        formData.append("image", image.file);
      }

      if (formState.edit && isReview) {
        dispatch(
          questionActions.updateQuestion(
            formState.id,
            {
              approved_status: formState.approved_status,
              reviewer_feedback: formState.reviewer_feedback,
            },
            answer,
            true
          )
        );
      } else if (formState.edit) {
        dispatch(questionActions.updateQuestion(formState.id, formData, answer)).then(value => {
          if (value === true) setAnswer({})
        });
      } else {
        dispatch(questionActions.createQuestion(formData, answer))
      }
    }
  };


  const handleClearMessage = () => {
    dispatch(questionActions.questionStateChanger());
  };

  const addNewOption = () => {
    if (!isReview) {
      setMcOptionField([
        ...mcOptionField,
        {
          serial: parseInt(mcOptionField[mcOptionField.length - 1].serial) + 1,
          value: "",
          isChecked: false,
        },
      ]);
      if(answer.content) {
        setAnswer({
          content: {
            ...answer.content,
            [parseInt(mcOptionField[mcOptionField.length - 1].serial) + 1]: {
              serial: parseInt(mcOptionField[mcOptionField.length - 1].serial) + 1,
              content: "",
              isChecked: false,
            },
          },
        });
      } else {
        setAnswer({
          ...answer,
          [parseInt(mcOptionField[mcOptionField.length - 1].serial) + 1]: {
            serial: parseInt(mcOptionField[mcOptionField.length - 1].serial) + 1,
            isChecked: false,
          },
        });
      }
    } else {
      return;
    }
  };

  const removeMcOption = () => {
    if (mcOptionField.length > 1 && isReview !== true){
      if(mcOptionField[mcOptionField.length-1].id) {
        dispatch(questionActions.deleteAnswerAction(mcOptionField[mcOptionField.length-1].id))
      }
      if(answer[mcOptionField.length-1] !== null){
        let value = answer
        delete value[mcOptionField.length-1]
        setAnswer(value)
      }
      setMcOptionField(mcOptionField.slice(0, mcOptionField.length - 1));
  }};

  // const addCreator = () => {
  // 	if (!creatorValue) {
  // 		setCreatorError('This field is required');
  // 		return;
  // 	}
  // 	const creatorReqBody = {
  // 		first_name: creatorValue.split(' ')[0],
  // 		last_name: creatorValue.split(' ')[1],
  // 	};
  // 	dispatch(settingActions.createCreator(creatorReqBody));
  // 	setCreatorValue('');
  // };

  let {
    value,
    standard_set,
    standard_code,
    grade,
    dok,
    question_style,
    summative_status,
    state_model,
    grade_level,
    image_source,
    alt_text,
    copyright_status,
    content_source,
    mills_difficulty_level,
    author_memo,
    author_name,
    reviewer_name,
    created,
    reviewer_date,
    reviewer_feedback,
    approved_status,
    creator,
  } = formState;

  const gradeCodeField = standardCodeData &&
    standardCodeData.filter((item2) => item2.standard_set === standard_set.standard_set)
      .reduce((unique, item) => {
        const uf = unique.filter((ui) => ui.standard_set === item.standard_set);
        return uf.length && uf[0].grade === item.grade ? unique : [...unique, item]
      }, [])
      .map((set, i) => {
        return {
          value: { set: set.grade, i },
          label: set.grade,
        };
      })

  const standardCodeField = standardCodeData &&
    standardCodeData.filter((item1) =>
      item1.standard_set === standard_set.standard_set)
      .map((set, i) => {
        return {
          value: { set: set.standard_code, i },
          label: set.standard_code,
        };
      })

   

  const stCodeValue = formState && standardCodeData ?
        standardCodeData.filter((item, index) => item.standard_code === formState.standard_code).map(item =>{
            return {index: standardCodeData.indexOf(item), standard_code: item}
        })[0] : null

  const stGradeValue = formState && standardCodeData ?
        standardCodeData.filter((item, index) => item.grade === formState.grade).map(item => {
          return { index: standardCodeData.indexOf(item), grade: item }
        })[0] : null

  const standardSetsData = [];
  const standardSets = standardCode && standardCode.map((item) => { 
    const stSet = standardSetsData.filter((i) => i.standard_set === item.standard_set )
      if(!stSet.length){
        return standardSetsData.push(item);
      }
      return true;
  })

  if(standard_set){
    standardSetsData.map((item, index) => {
    if (item.standard_set === standard_set.standard_set) {
      standard_set = { standard_set: item.standard_set, index: index  }
    }
    return true;
    });
  }

  if (standard_code && standardCodeField) {
    standardCodeField.map((item, index) => {
      if (item.value.set === standard_code.standard_code) {
        standard_code = { standard_code: item.value.set, index: index }
      }
      return true;
    });
  }

  if (grade && gradeCodeField) {
    gradeCodeField.map((item, index) => {
      if (item.value.set === grade.grade) {
        grade = { grade: item.value.set, index: index }
      }
      return true;
    });
  }

  return (
    <Container>
      <Layout>
        {creatingAnswer === "success" && (
          <React.Fragment>
            <div ref={apiErrorRef}></div>
            <MessageBar
              messageType="SUCCESS"
              message={"Question created successfully"}
              handleClearMessage={handleClearMessage}
            />
          </React.Fragment>
        )}
        {creatingAnswer === "fail" && (
          <React.Fragment>
            <div ref={apiErrorRef}></div>
            <MessageBar
              messageType="ERROR"
              message={"Question creation failed. Try again"}
              handleClearMessage={handleClearMessage}
            />
          </React.Fragment>
        )}
        {updatingQuestion === "success" && (
          <React.Fragment>
            <div ref={apiErrorRef}></div>
            <MessageBar
              messageType="SUCCESS"
              message={"Updated Successfully"}
              handleClearMessage={handleClearMessage}
            />
          </React.Fragment>
        )}
        {updatingQuestion === "fail" && (
          <React.Fragment>
            <div ref={apiErrorRef}></div>
            <MessageBar
              messageType="ERROR"
              message={"Approved questions can't be edited."}
              handleClearMessage={handleClearMessage}
            />
          </React.Fragment>
        )}
        {error && (
          <React.Fragment>
            <div ref={apiErrorRef}></div>
            <MessageBar messageType="ERROR" message={error} />
          </React.Fragment>
        )}

        <div
          className={`px-4 py-4 border form-border border-color ${
            creatingAnswer || (updatingQuestion && "mt-4")
          } `}
        >
          {isReview && (
            <AuthorDetails
              author_name={author_name}
              reviewer_name={reviewer_name}
              created={created}
              reviewer_date={reviewer_date}
            />
          )}
          <Form.Row className="mb-3">
            <Form.Group as={Col} md="3">
              <SingleSelect
                value={mills_difficulty_level}
                label="Mills Difficulty Level"
                options={millsDiffLevel}
                onChange={handleSelectChange("mills_difficulty_level")}
                disabled={isReview}
              />
              {submitted && errors.mills_difficulty_level && (
                <p className="text-danger form-text-danger">
                  {errors.mills_difficulty_level}
                </p>
              )}
            </Form.Group>
            <Form.Group as={Col} md="2" style={{ zIndex: 10001}}>
              <SingleSelect
                value={dok}
                label="DOK"
                options={DOK}
                onChange={handleSelectChange("dok")}
                disabled={isReview}
              />
              {submitted && errors.dok && (
                <p className="text-danger form-text-danger">{errors.dok}</p>
              )}
            </Form.Group>
            <Form.Group as={Col} md="2" style={{ zIndex: 10001 }}>
              <SingleSelect
                value={question_style}
                label="Question Style"
                options={questionStyles}
                onChange={handleSelectChange("question_style")}
                disabled={isReview}
              />
              {submitted && errors.question_style && (
                <p className="text-danger form-text-danger">{errors.question_style}</p>
              )}
            </Form.Group>

            <Form.Group
              as={Col}
              md="2"
              className="mt-2 ml-5 d-flex align-items-end"
            >
              <Form.Check
                type="switch"
                id="ss"
                label="Summative Status"
                name="summative_status"
                onChange={handleChange}
                checked={summative_status}
                value={summative_status}
                readOnly={isReview}
              />
            </Form.Group>
            <Form.Group
              as={Col}
              md="2"
              className="mt-2 ml-4 d-flex align-items-end"
            >
              <Form.Check
                type="switch"
                id="sm"
                label="State Model"
                name="state_model"
                onChange={handleChange}
                checked={state_model}
                value={state_model}
                readOnly={isReview}
              />
            </Form.Group>
          </Form.Row>
          <Form.Row className="mb-3">
            <Form.Group as={Col} md="4">
              <SingleSelect
                value={
                  standard_set && {
                    set: standard_set.standard_set,
                    i: standard_set.index,
                  }
                }
                label="Standard Set"
                options={
                  standardSetsData &&
                  standardSetsData.map((set, i) => {
                    return {
                      value:{set: set.standard_set, i },
                      label: set.standard_set,
                    };
                  })
                }
                onChange={handleSelectChange("standard_set")}
                disabled={isReview}
              />
              {submitted && errors.standard_set && (
                <p className="text-danger form-text-danger">
                  {errors.standard_set}
                </p>
              )}
            </Form.Group>

            <Form.Group as={Col} md="4" style={{ zIndex: 10000 }}>
              <SingleSelect
                  value={
                    standard_code && {
                      set: standard_code.standard_code,
                      i: standard_code.index,
                    }
                  }
                  label="Standard Code"
                  options={standardCodeField}
                  onChange={handleSelectChange("standard_code")}
                  disabled={isReview}
              />
              {submitted && errors.standard_code && (
                <p className="text-danger form-text-danger">
                  {errors.standard_code}
                </p>
              )}
            </Form.Group>
            <Form.Group as={Col} md="4" className="d-flex align-items-end">
              <SingleSelect
                value={
                  grade && {
                    set: grade.grade,
                    i: grade.index,
                  }
                }
                label="Grade"
                onChange={handleSelectChange("grade")}
                className={`border-top-0 border-left-0 border-right-0 rounded-0`}
                readOnly={isReview}
                options={gradeCodeField}
                disabled={isReview}
              />
              {submitted && errors.grade_level && (
                <p className="text-danger form-text-danger">
                  {errors.grade_level}
                </p>
              )}
            </Form.Group>
          </Form.Row>
          <Form.Row className="mb-3">
            <Form.Group as={Col} md="4">
              <Form.Control
                type="text"
                name="copyright_status"
                value={copyright_status}
                onChange={handleChange}
                className={`border-top-0 border-left-0 border-right-0 rounded-0 ${
                  copyright_status.length && "label-up"
                }`}
                readOnly={isReview}
              />
              <span className="floating-label">Copyright status</span>
            </Form.Group>
            <Form.Group as={Col} md="4">
              <Form.Control
                type="text"
                name="content_source"
                onChange={handleChange}
                value={content_source}
                className={`border-top-0 border-left-0 border-right-0 rounded-0 ${
                  content_source.length && "label-up"
                }`}
                readOnly={isReview}
              />
              <span className="floating-label">Content source</span>
            </Form.Group>
            <Form.Group as={Col} md="4">
              <Form.Control
                type="text"
                name="author_memo"
                onChange={handleChange}
                value={author_memo}
                className={`border-top-0 border-left-0 border-right-0 rounded-0 ${
                  author_memo.length && "label-up"
                }`}
                readOnly={isReview}
              />
              <span className="floating-label">Memo</span>
            </Form.Group>
          </Form.Row>
          <Form.Row className="mb-3">
            <Form.Group as={Col} md="4">
              <Form.Control
                type="text"
                name="image_source"
                value={image_source}
                onChange={handleChange}
                className={`border-top-0 border-left-0 border-right-0 rounded-0 ${
                  image_source && image_source.length && "label-up"
                }`}
                readOnly={isReview}
              />
              <span className="floating-label">Image source</span>
            </Form.Group>
            <Form.Group as={Col} md="3">
              <Form.Control
                type="text"
                name="alt_text"
                value={alt_text}
                onChange={handleChange}
                className={`border-top-0 border-left-0 border-right-0 rounded-0 ${
                  alt_text && alt_text.length && "label-up"
                }`}
                readOnly={isReview}
              />
              <span className="floating-label">Alt text</span>
            </Form.Group>
            <Form.Group as={Col} md="3" className="alignment ">
              <input
                style={{ display: "none" }}
                type="file"
                name="file"
                ref={imageRef}
                onChange={handleImage}
                disabled={isReview}
              />
              <Button
                variant="outline-primary"
                className={`upload-excel ${
                  isReview ? "set-hover-false" : "set-hover-true"
                }`}
                onClick={handleImageClick}
                disabled={image.file}
              >
                {imageButtonText}
              </Button>
            </Form.Group>
            {image.url && (
              <Form.Group as={Col} md="2">
                <img
                  src={image.url}
                  className="roundimage"
                  alt="question related"
                />
              </Form.Group>
            )}
          </Form.Row>
          <Form.Row className="mb-3">
            <Form.Group as={Col} md="4" style={{ zIndex: 10000002 }}>
              <SingleSelect
                value={formState.reviewer_name}
                label="Reviewer"
                options={
                  reviewers &&
                  reviewers.map((reviewer) => {
                    return {
                      value: reviewer.id,
                      label: reviewer.first_name + " " + reviewer.last_name,
                    };
                  })
                }
                disabled={isReview}
                onChange={handleSelectChange("reviewer_name")}
              />
              {submitted && errors.reviewer_name && (
                <p className="text-danger form-text-danger">
                  {errors.reviewer_name}
                </p>
              )}
            </Form.Group>
            <Form.Group as={Col} md="4" style={{ zIndex: 10000002 }}>
              <SingleSelect
                value={creator}
                label="Creator"
                options={
                  creators &&
                  creators.map((creator) => {
                    return {
                      value: creator.id,
                      label: creator.first_name + " " + creator.last_name,
                    };
                  })
                }
                disabled={isReview}
                onChange={handleSelectChange("creator")}
              />
              {submitted && errors.creator && (
                <p className="text-danger form-text-danger">{errors.creator}</p>
              )}
            </Form.Group>
            {/* {!isReview && (
							<>
								<Form.Group as={Col} md="3" className="d-flex align-items-end">
									<Form.Control
										type="text"
										value={creatorValue}
										name="add_creator"
										onChange={handleChange}
										className={`border-top-0 border-left-0 border-right-0 rounded-0 ${
											creatorValue && 'label-up'
										}`}
										readOnly={isReview}
									/>
									<span className="floating-label">Add Creator</span>
									{creatorError && <p className="text-danger form-text-danger">{creatorError}</p>}
								</Form.Group>
								<Form.Group
									as={Col}
									md="0"
									className="align-self-center cursor-pointer"
									onClick={addCreator}
								>
									<span className="create-user-icon ipad-create-user-icon">
										<CreateUserIcon />
									</span>
								</Form.Group>
							</>
						)} */}
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="8">
              <Form.Label className="question-label">Enter question</Form.Label>
              <MathquillInput style={{zIndex: 9999}}
                name="value"
                handleQuestionChange={handleQuestionChange}
                isReview={isReview}
                value={value}
              />
              {submitted && errors.value && (
                <p className="text-danger form-text-danger">{errors.value}</p>
              )}
            </Form.Group>
          </Form.Row>
          <span style={{color: 'grey'}}>Please choose only 1 correct answer</span>
          {(questionType === "sa" || questionType === "la") && (
            <Form.Row>
              <Form.Group as={Col} md="8">
                <Form.Label className="question-label">Enter Answer</Form.Label>
                <MathquillInput
                  handleAnswerChange={handleAnswerChange("value")}
                  isReview={isReview}
                  value={answer.content}
                />
              </Form.Group>
            </Form.Row>
          )}
          {questionType === "mc" && (
            <React.Fragment>
              {mcOptionField.map((el) => (
                <McOptionSerial
                  key={el.serial}
                  serial={el.serial}
                  value={el.value}
                  isChecked={el.isChecked}
                  handleMcOptionChange={handleMcOptionChange}
                  isReview={isReview}
                />
              ))}
              <Form.Row>
                <Form.Group
                  as={Col}
                  md="0"
                  className="align-self-center cursor-pointer"
                  onClick={removeMcOption}
                >
                  <MinusCircle />
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="0"
                  className="align-self-center cursor-pointer"
                  onClick={addNewOption}
                >
                  <span className="create-user-icon ipad-create-user-icon">
                    <CreateUserIcon />
                  </span>
                </Form.Group>
                <Form.Group as={Col} md="3" className="align-self-center">
                  <h6>Add Option</h6>
                </Form.Group>
              </Form.Row>
            </React.Fragment>
          )}
          {questionType === "t/f" && (
            <React.Fragment>
              <Form.Row className="d-flex z-negative">
                <Form.Group as={Col} md="0" className="align-self-center">
                  <A />
                </Form.Group>
                <Form.Group as={Col} md="8" className="mr-5 z-negative">
                  <Form.Control
                    type="text"
                    value="True"
                    className=" border-top-0 border-left-0 border-right-0 rounded-0 z-negative"
                  />
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="1"
                  className="d-flex justify-content-between align-items-center"
                >
                  <Form.Check
                    label=""
                    type="switch"
                    id="custom-switch1"
                    onChange={handleAnswerChange("true")}
                    checked={answer.content && answer.content.true}
                    readOnly={isReview}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Row className="d-flex">
                <Form.Group as={Col} md="0" className="align-self-center">
                  <B />
                </Form.Group>
                <Form.Group as={Col} md="8" className="mr-5 z-negative">
                  <Form.Control
                    type="text"
                    value="False"
                    className=" border-top-0 border-left-0 border-right-0 rounded-0 "
                  />
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="1"
                  className="d-flex justify-content-between align-items-center"
                >
                  <Form.Check
                    label=""
                    type="switch"
                    id="custom-switch2"
                    onChange={handleAnswerChange("false")}
                    checked={answer.content && answer.content.false}
                    readOnly={isReview}
                  />
                </Form.Group>
              </Form.Row>
            </React.Fragment>
          )}
        </div>
        {isReview && (
          <ReviewInput
            handleChange={handleChange}
            handleRadioChange={handleRadioChange}
            reviewer_feedback={reviewer_feedback}
            approved_status={approved_status}
          />
        )}
        <div className="my-4 d-flex justify-content-end bottom-btn-grp">
          <Button className="mr-4 cancel-btn" onClick={handleCancel}>
            <FormattedMessage
              defaultMessage="Cancel"
              id="componentUsersTabCancelButton"
            />
          </Button>
          <Button className="transparent-btn btn-custom" 
            onClick={handleSubmit}>
            {(creatingAnswer === true ||
              updatingQuestion === true ||
              creatingQuestion === true) && (
              <span className="spinner-border spinner-border-sm mr-1"></span>
            )}
            
            Save & Send
          </Button>
        </div>
      </Layout>
      <Footer/>
    </Container>
  );
};

export default Question;
