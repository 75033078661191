export default {
   GET_MESSAGE_USER_LIST_REQUEST: "GET_MESSAGE_USER_LIST_REQUEST",
   GET_MESSAGE_USER_LIST_SUCCESS: "GET_MESSAGE_USER_LIST_SUCCESS",
   GET_MESSAGE_USER_LIST_FAILURE: "GET_MESSAGE_USER_LIST_FAILURE",

   GET_MESSAGES_ID_REQUEST: "GET_MESSAGES_ID_REQUEST",
   GET_MESSAGES_ID_SUCCESS: "GET_MESSAGES_ID_SUCCESS",
   GET_MESSAGES_ID_FAILURE: "GET_MESSAGES_ID_FAILURE",

   GET_SINGLE_USER_MESSAGES_REQUEST: "GET_SINGLE_USER_MESSAGES_REQUEST",
   GET_SINGLE_USER_MESSAGES_SUCCESS: "GET_SINGLE_USER_MESSAGES_SUCCESS",
   GET_SINGLE_USER_MESSAGES_FAILURE: "GET_SINGLE_USER_MESSAGES_FAILURE",

   SEND_MESSAGE_REQUEST: "SEND_MESSAGE_REQUEST",
   SEND_MESSAGE_SUCCESS: "SEND_MESSAGE_SUCCESS",
   SEND_MESSAGE_FAILURE: "SEND_MESSAGE_FAILURE",
};
