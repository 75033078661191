import React, { useEffect, useState } from 'react';


import ReactHighcharts from "highcharts-react-official";
import Highcharts from "highcharts";

const RetakeComponent = ({ data, class_name}) => {

    

    const chartValues = (title, chartData, index) => {
        const chData = chartData && chartData[1].map((item) => item.score)

        if (chData) {
            chData[0] = {
                y: chData[0],
                marker: {
                    radius: 10,
                    fillColor: 'white',
                    lineColor: 'orange',
                    lineWidth: 5,
                }
            }
        }

        const options = {
            title: {
                text: ""
            },
            series: [
                {
                    data: chData,
                    marker: {
                        fillColor: 'white',
                        lineColor: 'blue',
                        lineWidth: 3,
                        radius: 10,
                    },
                    color: 'blue',
                    name: 'Retakes'
                }
            ],
            chart: {
                height: '180px',
                scrollablePlotArea: {
                    minWidth: '300px',
                    scrollPositionX: 0,
                }
            },
            xAxis: {
                tickInterval: 1,
                tickAmount: 9,
                min:0,
                max: 8,
            },
            yAxis: {
                tickInterval: 1,
                tickAmount: 6,
                min:0,
                max: 5,

                title: {
                    text: 'Correct'
                }
            }
           
        };

        return (
            <div key={index} style={{ borderBottom: '5px solid black', display: 'flex', alignItems: 'center', gap: '3rem'}}>
                <div style={{fontWeight: 'bold', color: 'grey', width: '150px'}}>{title}</div>
                <div style={{alignItems: 'center'}}>
                    <ReactHighcharts options={options} highcharts={Highcharts}/>
                </div>
            </div>
        )
    }


    return (
        <div style={{marginRight: 30}}>
            {data && data.quizzes.map((item, index) => chartValues(item[0], item, index))}
        </div>
    );
}

export default RetakeComponent;
