import { handleResponse, handleResponseBadRequest } from "../../helpers/handleResponse";
import authHeader from "../../helpers/authHeader";
import { uploadHeader } from "./../../helpers/utils";

// const user = JSON.parse(localStorage.getItem("user"));
// const author = user && user !== "undefined" ? user.userObj.id : "";

const API_URL = process.env.REACT_APP_API_URL

const getQuizSet = () => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    credentials: "include",
  };
  return fetch(API_URL + `/quizset/`, requestOptions).then(
    handleResponse
  );
};

const getStandardCode = () => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    credentials: "include",
  };
  return fetch(API_URL + `/standard/`, requestOptions).then(
    handleResponse
  );
};

const getReviewers = () => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    credentials: "include",
  };
  return fetch(API_URL + `/user/reviewers-list/`, requestOptions).then(
    handleResponse
  );
};

const getCreators = () => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    credentials: "include",
  };
  return fetch(API_URL + `/creator/`, requestOptions).then(handleResponse);
};

const createQuestion = (formData) => {
  const requestOptions = {
    method: "POST",
    headers: uploadHeader(),
    body: formData,
    credentials: "include",
  };
  return fetch(API_URL + "/question/", requestOptions).then(handleResponseBadRequest);
};

const createAnswer = (data) => {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify(data),
    credentials: "include",
  };
  return fetch(API_URL + "/answer/", requestOptions).then(handleResponseBadRequest);
};

const getUserQuestions = (queryString, paginationQuery = "") => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    credentials: "include",
  };

  return fetch(
    API_URL + `/question/?${paginationQuery}&${queryString}&deleted=false&ordering=-created`,
    requestOptions
  ).then(handleResponse);
};

const getAllQuestions = (paginationQuery = "") => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    credentials: "include",
  };
  return fetch(
    API_URL + `/question/?${paginationQuery}&deleted=false&ordering=-created`,
    requestOptions
  ).then(handleResponse);
};

const getAnswer = (id) => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    credentials: "include",
  };
  return fetch(API_URL + `/question/${id}/answers/`, requestOptions).then(
    handleResponse
  );
};

const getSingleQuestion = (id) => {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
    credentials: "include",
  };
  return fetch(API_URL + `/question/${id}/`, requestOptions).then(handleResponse);
};

const deleteQuestion = (id) => {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
    credentials: "include",
  };
  return fetch(API_URL + `/question/${id}/`, requestOptions).then(handleResponse);
};

const updateQuestion = (id, data, isReview = false) => {
  const requestOptions = {
    method: "PATCH",
    headers: isReview
      ? { ...uploadHeader(), "Content-Type": "application/json" }
      : uploadHeader(),
    body: isReview ? JSON.stringify(data) : data,
    credentials: "include",
  };

  return fetch(API_URL + `/question/${id}/`, requestOptions).then(handleResponseBadRequest);
};

const updateAnswer = (id, data) => {
  const requestOptions = {
    method: "PATCH",
    headers: authHeader(),
    body: JSON.stringify(data),
    credentials: "include",
  };

  return fetch(API_URL + `/answer/${id}/`, requestOptions).then(handleResponseBadRequest);
};


const deleteAnswer = (id) => {
  const requestOptions = {
    method: "DELETE",
    headers: authHeader(),
    credentials: "include",
  };

  return fetch(API_URL + `/answer/${id}/`, requestOptions).then(handleResponseBadRequest);
};

export default {
  updateQuestion,
  updateAnswer,
  deleteQuestion,
  getUserQuestions,
  getAllQuestions,
  getStandardCode,
  getReviewers,
  getCreators,
  getAnswer,
  createQuestion,
  getSingleQuestion,
  createAnswer,
  getQuizSet,
  deleteAnswer,
};
