import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";

import PropTypes from 'prop-types';
import { userActions } from "../../../redux/user/user.actions";
import authHeader from '../../../helpers/authHeader';
import { handleResponse } from '../../../helpers/handleResponse';

import smallLogo from '../../../assets/img/small-logo.png';
import * as HiIcons from 'react-icons/hi';


import './TeacherHeader.css';


const TeacherHeader = ({ name,role,title, show, toggleShow, underLogout }) => {

    const dispatch = useDispatch();

    const handleLogout = () => {
        dispatch(userActions.logout());
    };

    const handleContactUs = () => {
        toggleShow();
    };

    return (
        <div className='teacher-header-schedule'
            style={{
                height: '15vh',
                alignItems: 'center',
                display: 'flex',
                borderBottom: '1.3px solid rgb(220, 217, 217)',
            }}    
        >
            <div className='logo-schedule'
                style={{
                    width: '8vw',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <img src={smallLogo} alt="smallLogo" height="80" width="80" className='logo-img' style={{ marginBottom: '30px', maxWidth: '100%', maxHeight: '100%'}}/>
            </div>

            <div className='menu'
                style={{
                    width: '20vw',
                    backgroundColor: '#EAEAFF',
                    height: 'inherit',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    paddingLeft: '1rem',
                }}
            >
                    <h4>{name}</h4>
                    <p style={{fontSize: '12px'}}>{role}</p>
            </div>

            <div className='info' style={{width: '60wv', paddingLeft: '1rem'}}>
                <p style={{fontSize: '26px'}}>{title}</p>
            </div>

            <div className='logout'
                style={{marginRight: '2vw'}}
            >
                <button className='logout-button-teacher' onClick={handleLogout}
                    style={{
                        borderRadius: '12px',
                        height: '40px',
                        width: '120px',
                        backgroundColor: 'white',
                        color:' #B1B1FF',
                        fontWeight: 'bold',
                        fontSize: '10px',
                        textAlign: 'center',
                        boxShadow: '0px 0px 16px #B8B8ED33',
                        border: '1px solid #B1B1FF',
                    }}
                >
                    <HiIcons.HiOutlineLogout className='icons-logout'  style={{
                        color: '#B1B1FF',
                        height: '1.5rem',
                        width: '1.5rem',
                        backgroundColor: 'transparent'
                    }}/>
                    <span style={{ marginLeft: '5px'}}>Logout</span>
                </button>
                {underLogout && (
                    <div className="under-logout">
                    {typeof underLogout === 'function' ? underLogout() : underLogout}
                    </div>
                )}
            </div>
        </div>
    )
}

export default TeacherHeader;
