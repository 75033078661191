import React, { useEffect, useState, useCallback } from 'react';
// import { useDispatch } from 'react-redux';

import authHeader from '../../../../helpers/authHeader';
import { handleResponse } from '../../../../helpers/handleResponse';
import { useSelector } from 'react-redux';

// import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';

import './TeacherCleverHeader.css';

const TeacherCleverHeader = classId => {
    const API_URL = process.env.REACT_APP_API_URL;
    // const tName = useSelector(state => state.authentication.user?.userObj);

    const [classInfo, setClassInfo] = useState('');
    // const [schoolInfo, setSchoolInfo] = useState('');

    // const schoolId2 = schoolId?.schoolId;

    // const fetchSchoolInfo = async () => {
    //     const requestOptions = {
    //         method: 'GET',
    //         headers: authHeader(),
    //         credentials: 'include'
    //     };
    //     await fetch(API_URL + `/school/${schoolId2}/`, requestOptions)
    //         .then(handleResponse)
    //         .then(data => {
    //             setSchoolInfo(data);
    //         });
    // };
    const fetchClassInfo = useCallback(async () => {
        const requestOptions = {
            method: 'GET',
            headers: authHeader(),
            credentials: 'include'
        };
        await fetch(API_URL + `/class/${classId?.classId}/`, requestOptions)
            .then(handleResponse)
            .then(data => {
                setClassInfo(data);
            });
    }, [API_URL, classId?.classId]);

    useEffect(() => {
        // fetchSchoolInfo();
        if(classId?.classId) fetchClassInfo();
    }, [classId?.classId, fetchClassInfo]);

    return (
        <div className="class-info-container">
            <div className="class-info-item">
                <h2 className="class-info-title">Class Code</h2>
                <p className="class-info-text">{classInfo.class_code}</p>
            </div>
            <div className="class-info-item">
                <h2 className="class-info-title">Year</h2>
                <p className="class-info-text">{classInfo.year}</p>
            </div>
            <div className="class-info-item">
                <h2 className="class-info-title">Period</h2>
                <p className="class-info-text">{classInfo.period}</p>
            </div>
        </div>
    );
};

export default TeacherCleverHeader;
