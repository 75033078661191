/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
//import katex from "katex";
import { Route, Switch, useHistory } from "react-router-dom";
import { Container } from "react-bootstrap";
import { RawIntlProvider } from "react-intl";
import { generateIntl } from "./helpers/intl";
import { useSelector } from "react-redux";

import en from "./translations/en";
import hindi from "./translations/hindi";

import PrivateRoute from "./components/Common/private-route/private-route.component";
import AdminRoute from "./components/Common/admin-route/admin-route.component.jsx";
import AdminInfo from "./components/Admin/admin-info/admin-info.component";
import Users from "./components/Admin/users-tab/users-tab.component.jsx";
import UserTypes from "./components/Admin/user-types/user-types.component.jsx";
import Settings from "./components/Admin/settings/settings.component.jsx";
import SignUp from "./pages/signup/signup.component";
import ConfirmEmail from "./pages/confirm-email/confirm-email.component";
import Login from "./pages/login/login.component";
import ForgotPassword from "./pages/forgot-password/forgot-password.component";
import MyQuestions from "./components/Author/my-questions/my-questions.component.jsx";
import AllQuestions from "./components/Author/all-questions/all-questions.component.jsx";
import { QuizList, CreateQuiz } from "./components";
import CreateQuestion from "./components/Author/question/question.component.jsx";
import ReviewQuestion from "./components/Author/question/question.component.jsx";
import MyReviews from "./components/Reviewer/my-reviews/my-reviews.component.jsx";
import AllReviews from "./components/Reviewer/all-questions-review/all-questions-reviews.compnent.jsx";
import Footer from "./components/Common/footer/footer.component";
import LandingPage from "./components/Student/LandingPage/LandingPage";
import PastQuizzesPage from "./components/Student/Past Quizzes/PastQuizzesPage";
import StudentClassList from "./components/Student/ClassList/ClassList";
import QuizStarted from "./components/Student/Quiz/QuizStarted";
import QuizFinalPage from "./components/Student/Quiz/QuizFinal/QuizFinalPage";
import FinishedQuiz from "./components/Student/Quiz/QuizFinished/FinishedQuiz";
import TeacherSchedule from "./components/Teacher/Teacher Schedule/TeacherSchedule";
import ClassQuizzes from "./components/Teacher/ClassQuizzes/ClassQuizzes";
import ResultsPage from "./components/Teacher/ResultsPage/ResultsPage";
import FalseQuizPage from "./components/Student/Quiz/QuizFinal/FalseQuizPage";
import PastQuizzesReports from "./components/Student/PastQuizzesReports/PastQuizzesReports";

import "../node_modules/react-quill/dist/quill.snow.css";
import "./App.css";
import TeacherTableRetakes from "./components/Teacher/TeacherRetakes/TeacherTableRetakes";
import ClassRetakes from "./components/Teacher/Class Retakes/ClassRetakes";
import ClassStandards from "./components/Teacher/ClassStandards/ClassStandards";
import TeacherRegistrationIntroduction from "./pages/register/TeacherRegistration/Introduction/TeacherRegistrationIntroduction";
import TeacherRegistrationForm from "./pages/register/TeacherRegistration/Registration/TeacherRegistrationForm";
import StudentRegistration from "./pages/register/StudentRegistration/StudentRegistration";
import CleverTeachers from "./components/Admin/clever-teachers/CleverTeachers";
import TeacherCleverStudents from "./components/Teacher/TeacherCleverStudents/TeacherCleverStudents";
import CleverRedirect from "./pages/clever-redirect/cleverredirect.component";
// import io from "socket.io-client";
// const SOCKETIO_ENDPOINT = process.env.REACT_APP_SOCKETIO_ENDPOINT;
// const socket = io.connect(SOCKETIO_ENDPOINT);
const messages = { en, hindi };
const intlValue = generateIntl({ locale: "en", messages: messages["en"] });

const NotFound = () => {
  const history = useHistory();
  history.push("/login");
  return null;
};

function App() {
  //const [response, setResponse] = useState("");
  //const history = useHistory();
  const questionType = useSelector((state) => state.local.questions);
  const history = useHistory();

  //var nr='';
  useEffect(() => {
    // window.katex = katex;
    // history.push("/login");

    // socket.on("getQuizzes", data => {//connect
    //   socket.emit("sendEvent", "event sent from frontend");//sendEvent
    //   socket.emit('databaseQuiz',5);
    //   socket.emit('submitTest',1);
    // },
    // socket.on("sendArray",data => {  //connect

    // },
    // socket.on("quiz", data=> {//databaseQuiz
    //   //  alert(data); //arata id ul testului de interes

    // },
    // socket.on("showStudentsCompleted",(data) =>{

    // })
    // )
    // ));
    if(window?.location?.search.split("=")[1]){
      history.push('/clever-redirect')
    }
  }, []
  );
  return (
    <RawIntlProvider value={intlValue}>
        <Switch>
          <Route path="/login" exact component={Login} />
          <AdminRoute path="/admin/dashboard" exact component={AdminInfo} />
          <AdminRoute path="/admin/users" exact component={Users} />
          <AdminRoute path="/admin/user-types" exact component={UserTypes} />
          <AdminRoute path="/admin/settings" exact component={Settings} />
          <PrivateRoute path="/my-questions" exact component={MyQuestions} />
          <PrivateRoute path="/all-questions" exact component={AllQuestions} />
          <PrivateRoute path="/quizzes" exact component={QuizList} />
          <PrivateRoute path="/create-quiz" exact component={CreateQuiz} />
          <PrivateRoute
            path="/create-question"
            exact
            component={CreateQuestion}
            questionType={questionType}
          />
          <PrivateRoute
            path="/review-question"
            exactV
            component={ReviewQuestion}
          />
          <PrivateRoute path="/my-reviews" exact component={MyReviews} />
          <PrivateRoute path="/all-reviews" exact component={AllReviews} />
          {/* <PrivateRoute path="/teachers-clever" exact component={CleverTeachers} /> */}
          <Route path="/register" exact component={SignUp} />
          <Route path="/reset-password" exact component={SignUp} />
          <Route path="/forgot-password" exact component={ForgotPassword} />
          <Route path="/confirm-email" exact component={ConfirmEmail} />
          <Route path="/teacher-registration-introduction/:authenticationToken" exact component={TeacherRegistrationIntroduction} />
          <Route path="/teacher-registration-form/:authenticationToken" exact component={TeacherRegistrationForm} />
          <Route path="/student-registration/:authenticationToken" exact component={StudentRegistration} />
          <Route path="/clever-redirect" exact component={CleverRedirect} />

          <PrivateRoute path="/landing-page" exact component={LandingPage} />
          <PrivateRoute path="/class-list" exact component={StudentClassList} />
          <PrivateRoute path="/past-quizzes" exact component={PastQuizzesPage} />
          <PrivateRoute path="/quiz-started/:classId/:quizId" exact component={QuizStarted} />
          <PrivateRoute path="/finishing-up/:classId/:quizId" exact component={QuizFinalPage} />
          <PrivateRoute path="/quiz-finish/:classId/:quizId" exact component={FinishedQuiz} />
          <PrivateRoute path="/quiz-stopped" exact component={FalseQuizPage} />
          <PrivateRoute path="/past-quizzes/:pastQuizTitle" exact component={PastQuizzesReports} />

          <PrivateRoute path="/teacher-schedule" exact component={TeacherSchedule} />
          <PrivateRoute path="/class-quizzes/:classPeriod/:className" exact component={ClassQuizzes} />
          <PrivateRoute path="/results-page/:classPeriod/:quizId/:className/:studentsCompleted/:previewQuizId/:quizName" exact component={ResultsPage} />
          <PrivateRoute path="/teacher-schedule/teacher-table/:classPeriod/:className" exact component={TeacherTableRetakes} />
          <PrivateRoute path="/class-quizzes/:classPeriod/:className/class-retakes" exact component={ClassRetakes} /> 
          <PrivateRoute path="/class-quizzes/:classPeriod/:className/class-standards" exact component={ClassStandards} /> 
          <PrivateRoute path="/teacher-clever-students/:classId" exact component={TeacherCleverStudents} />

          <Route component={NotFound} />
        </Switch>
    </RawIntlProvider>
  );
}

export default App;


