import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';

import { userActions } from '../../../redux/user/user.actions';
import { toggleShow } from '../../../redux/modals/modals.actions';
import { selectShow } from '../../../redux/modals/modal.select';
import { createStructuredSelector } from 'reselect';
import { handleResponse } from '../../../helpers/handleResponse';
import authHeader from '../../../helpers/authHeader';
import { useHistory } from 'react-router-dom';

import ContactUsModalComponent from '../../Common/contact-us-modal/contact-us-modal.component';

import * as AiIcons from 'react-icons/ai';
import * as HiIcons from 'react-icons/hi';

import Highcharts from 'highcharts/highmaps';
import HighchartsReact from 'highcharts-react-official';

const ClassRetakesContent = ({ show, toggleShow, class_id, class_name }) => {
    const API_URL = process.env.REACT_APP_API_URL;
    const dispatch = useDispatch();
    const history = useHistory();

    const [data, setData] = useState('');
    let standardsName = [];
    let standardsValues = [];
    let studentArray = [];
    let studentNames = [];
    let mapArray = [];

    const handleLogout = () => {
        dispatch(userActions.logout());
    };

    const handleContactUs = () => {
        toggleShow();
    };

    const fetchStandards = () => {
        const requestOptions = {
            method: 'GET',
            headers: authHeader(),
            credentials: 'include'
        };
        fetch(API_URL + `/report/teacher/standards/${class_id}/retakes/100/`, requestOptions)
            .then(handleResponse)
            .then(data => {
                setData(data);
            });
    };

    useEffect(() => {
        fetchStandards();
    }, []);

    data &&
        data.forEach(item => {
            standardsValues.push(Object.values(item.standards));
            studentArray.push(Object.values(item.standards));
            studentNames.push(item.first_name + ' ' + item.last_name);
        });

    studentArray.length !== 0 &&
        studentArray.forEach(student =>
            student.forEach(
                item =>
                    standardsName.indexOf(Object.getOwnPropertyNames(item)[0]) === -1 &&
                    standardsName.push(Object.getOwnPropertyNames(item)[0])
            )
        );

    const heatMapData = () => {
        data &&
            data.forEach((item, index) => {
                for (let i = 0; i < standardsName.length; i++) {
                    let ok = false;
                    for (const el in item.standards) {
                        if (standardsName[i] === Object.getOwnPropertyNames(item.standards[el])[0]) {
                            ok = true;
                            mapArray.push([i, index, Object.values(item.standards[el])[0]]);
                            break;
                        }
                    }
                    if (!ok) {
                        mapArray.push([i, index, null]);
                    }
                }
            });
    };
    heatMapData();

    const heatmapSizes = {
        height: studentNames.length * 150,
        minWidth: standardsName.length * 200
    };
    const options = {
        chart: {
            type: 'heatmap',
            marginTop: 105,
            marginBottom: 15,
            plotBorderWidth: 3,
            height: heatmapSizes.height < 850 ? heatmapSizes.height : 850,
            scrollablePlotArea: {
                minWidth: heatmapSizes.minWidth > 1250 ? heatmapSizes.minWidth * 0.5 : heatmapSizes.minWidth,
                scrollPositionX: 0
            }
        },
        title: {
            text: null
        },
        series: [
            {
                name: 'Standards per Student',
                borderWidth: 1,
                borderColor: '#ffffff',
                data: mapArray,
                dataLabels: {
                    enabled: true,
                    color: 'black',
                    style: {
                        textOutline: 'none',
                        fontSize: '1.1rem'
                    },
                    format: '{point.value}%',
                    nullFormat: '-',
                    overflow: 'none',
                    crop: true
                }
            }
        ],
        colors: ['#ff6e6c', '#ffb962', '#f7ffb6', '#c1f2c6', '#9cee78'],
        colorAxis: {
            dataClassColor: 'category',
            dataClasses: [
                { from: 0, to: 59, colorIndex: 0 },
                { from: 60, to: 69, colorIndex: 1 },
                { from: 70, to: 79, colorIndex: 2 },
                { from: 80, to: 89, colorIndex: 3 },
                { from: 90, to: 100, colorIndex: 4 }
            ]
        },
        xAxis: {
            categories: standardsName,
            labels: {
                rotation: -45,
                style: {
                    fontSize: '1.25rem',
                    color: '#000000',
                    fontWeight: '900'
                },
                x: -15
            },
            opposite: true
        },
        yAxis: {
            categories: studentNames,
            title: {
                text: '<b>Name<b>',
                align: 'high',
                y: -10,
                rotation: 0,
                offset: 15,
                style: {
                    fontSize: '1rem',
                    color: '#3a435e'
                }
            },
            reversed: true,
            labels: {
                style: {
                    fontSize: '1.25rem',
                    color: '#000000',
                    fontWeight: '900'
                }
            }
        },
        legend: {
            enabled: false
        }
    };

    return (
        <div className="class-retakes-container">
            <div className="class-retakes-info">
                <button className="back-button" onClick={() => history.push(`/class-quizzes/${class_id}/${class_name}`)}>
                    BACK TO QUIZZES
                </button>

                {data && (
                    <div className="class-retakes-info-standards">
                        <HighchartsReact
                            containerProps={{
                                style: {
                                    height: '100%',
                                    maxWidth: heatmapSizes.minWidth * 1.1,
                                    maxHeight: heatmapSizes.height * 1.1
                                }
                            }}
                            highcharts={Highcharts}
                            options={options}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    show: selectShow
});

const mapDispatchToProps = dispatch => {
    return {
        toggleShow: () => dispatch(toggleShow())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClassRetakesContent);
