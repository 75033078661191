import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import authHeader from '../../../../helpers/authHeader';
import { handleResponse } from '../../../../helpers/handleResponse';
import { ReactComponent as Logo } from '../../../../assets/img/logo.svg';
import './TeacherRegistrationForm.css';

import { Button, TextField } from '@mui/material';

import { Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

const TeacherRegistrationForm = props => {
    const API_URL = process.env.REACT_APP_API_URL;
    const authenticationToken = props.match.params.authenticationToken;

    const emailRef = useRef();
    const firstNameRef = useRef();
    const lastNameRef = useRef();
    const [firstNameError, setFirstNameError] = useState(null);
    const [lastNameError, setLastNameError] = useState(null);
    const [emailError, setEmailError] = useState(null);
    const [registerError, setRegisterError] = useState(null);

    const [submitted, setSubmitted] = useState(false);
    const history = useHistory();

    const registerTeacher = () => {
        const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            credentials: 'include',
            body: JSON.stringify({
                first_name: firstNameRef.current.value,
                last_name: lastNameRef.current.value,
                email: emailRef.current.value,
                registration_token: authenticationToken
            })
        };
        fetch(API_URL + `/clever/signup/signupTeacher/`, requestOptions)
            .then(handleResponse)
            .then(data => {
                if (data.status_code === 400) {
                    if (data.hasOwnProperty('email')) {
                        setEmailError(data.email[0]);
                    }
                    if (data.hasOwnProperty('first_name')) {
                        setFirstNameError(data.first_name[0]);
                    }
                    if (data.hasOwnProperty('last_name')) {
                        setLastNameError(data.last_name[0]);
                    }
                } else {
                    setSubmitted(true);
                }
            })
            .catch(error => {
                setRegisterError(`Error: ${error.detail}`);
            });
    };
    const handleFormSubmit = e => {
        e.preventDefault();
        setFirstNameError(null);
        setLastNameError(null);
        setEmailError(null);
        setRegisterError(null);
        let regex = new RegExp(
            "([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|\"([]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|[[\t -Z^-~]*])"
        );
        const firstNameValue = firstNameRef.current.value.trim();
        const lastNameValue = lastNameRef.current.value.trim();
        const emailValue = emailRef.current.value.trim();
        if (firstNameValue !== '' && lastNameValue !== '' && emailValue.match(regex)) {
            registerTeacher();
        } else {
            if (firstNameValue === '') setFirstNameError('First name is required!');
            if (lastNameValue === '') setLastNameError('Last name is required!');
            if (emailValue === '') setEmailError('Email is required!');
            else if (!emailValue.match(regex)) setEmailError('Email is invalid!');
        }
    };

    const SubmittedResponse = () => {
        const [seconds, setSeconds] = useState(5);
        const [started, setStarted] = useState(false);
        function countdown() {
            const countInterval = setInterval(() => {
                setSeconds(prev => prev - 1);

                if (seconds <= 0) {
                    clearInterval(countInterval);
                }
            }, 1000);
        }
        if (submitted && !started) {
            setTimeout(() => {
                history.push('/login');
            }, 5000);
            countdown();
            setStarted(true);
        }

        return (
            <div>
                <h1 style={{ fontSize: '2em' }} className="text-center form-heading">
                    <FormattedMessage
                        id="pageRegistrationResponse"
                        defaultMessage="Congratulations!"
                        description="Header of the successful registration response"
                    />
                </h1>
                <h2 style={{ fontSize: '1.5em', textAlign: 'center', margin: '2rem 0' }}>You have been registered!</h2>
                <p style={{ textAlign: 'center', margin: '2rem 0' }}>Please sign in using the "Log in with Clever" button.</p>
                <p style={{ textAlign: 'center', margin: '2rem 0' }}>Returning to login page in...{seconds}</p>
            </div>
        );
    };
    return (
        <div className="registration-page-container-form">
            <div className="registration-page-container-content-form">
                <Logo
                    style={{
                        display: 'block',
                        width: 'auto',
                        height: 'auto',
                        maxWidth: '100%',
                        maxHeight: '100%',
                        margin: '2rem auto 0'
                    }}
                />
                {!submitted ? (
                    <>
                        <h1 className="text-center form-heading">
                            <FormattedMessage
                                id="pageRegistrationForm"
                                defaultMessage="Create Your Account"
                                description="Header of the registration form page"
                            />
                        </h1>
                        {registerError ? (
                            <p className="registration-page-container-form-register-error">{registerError}</p>
                        ) : null}
                        <div className="registration-page-container-content-form-style">
                            <Form>
                                <Form.Group controlId="formFirstName" className="relative">
                                    <Form.Control
                                        type="text"
                                        className="input-style input-text"
                                        name="firstName"
                                        ref={firstNameRef}
                                    />

                                    <span className="floating-label" style={{ top: '-25px', width: '90%' }}>
                                        {firstNameError === '' ? (
                                            'First Name'
                                        ) : (
                                            <>
                                                <span style={{ float: 'left' }}>First Name</span>
                                                <span style={{ float: 'right', color: 'red' }}>
                                                    &nbsp;&nbsp;&nbsp;{firstNameError}
                                                </span>
                                            </>
                                        )}
                                    </span>
                                </Form.Group>
                                <Form.Group controlId="formLastName" className="relative">
                                    <Form.Control
                                        type="text"
                                        className="input-style input-text"
                                        name="lastName"
                                        ref={lastNameRef}
                                    />
                                    <span className="floating-label" style={{ top: '-25px', width: '90%' }}>
                                        {lastNameError === '' ? (
                                            'Last Name'
                                        ) : (
                                            <>
                                                <span style={{ float: 'left' }}>Last Name</span>
                                                <span style={{ float: 'right', color: 'red' }}>
                                                    &nbsp;&nbsp;&nbsp;{lastNameError}
                                                </span>
                                            </>
                                        )}
                                    </span>
                                </Form.Group>
                                <Form.Group controlId="formEmail" className="relative">
                                    <Form.Control type="email" className={`input-style input-text`} name="email" ref={emailRef} />
                                    <span className="floating-label" style={{ top: '-25px', width: '90%' }}>
                                        {emailError === '' ? (
                                            'Email'
                                        ) : (
                                            <>
                                                <span style={{ float: 'left' }}>Email</span>
                                                <span style={{ float: 'right', color: 'red' }}>
                                                    &nbsp;&nbsp;&nbsp;{emailError}
                                                </span>
                                            </>
                                        )}
                                    </span>
                                </Form.Group>
                                <Button
                                    variant="primary"
                                    type="submit"
                                    className="text-center custom-btn registration-page-container-continue-button"
                                    onClick={handleFormSubmit}
                                >
                                    Register
                                </Button>
                            </Form>
                        </div>
                    </>
                ) : (
                    <SubmittedResponse />
                )}
            </div>
        </div>
    );
};

export default TeacherRegistrationForm;
