export const alertTypes = {
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
  CLEAR: "CLEAR",
};

export const userTypes = {
  REGISTER_REQUEST: "USERS_REGISTER_REQUEST",
  REGISTER_SUCCESS: "USERS_REGISTER_SUCCESS",
  REGISTER_FAILURE: "USERS_REGISTER_FAILURE",

  GETALL_USER_TYPES: "GETALL_USER_TYPE",
  GETALL_USER_TYPES_SUCCESS: "GETALL_USER_TYPE_SUCCESS",
  GETALL_USER_TYPES_FAILURE: "GETALL_USER_TYPE_FAILURE",

  CREATE_USER_TYPE: "CREATE_USER_TYPE",
  CREATE_USER_TYPE_SUCCESS: "CREATE_USER_TYPE_SUCCESS",
  CREATE_USER_TYPE_FAILURE: "CREATE_USER_TYPE_FAILURE",

  UPDATE_USER_TYPE: "UPDATE_USER_TYPE",
  UPDATE_USER_TYPE_SUCCESS: "UPDATE_USER_TYPE_SUCCESS",
  UPDATE_USER_TYPE_FAILURE: "UPDATE_USER_TYPE_FAILURE",

  DELETE_USER_TYPE: "DELETE_USER_TYPE",
  DELETE_USER_TYPE_SUCCESS: "DELETE_USER_TYPE_SUCCESS",
  DELETE_USER_TYPE_FAILURE: "DELETE_USER_TYPE_FAILURE",

  LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USERS_LOGIN_FAILURE",

  LOGOUT: "USERS_LOGOUT",

  GETALL_USERS_REQUEST: "GETALL_USERS_REQUEST",
  GETALL_USERS_SUCCESS: "GETALL_USERS_SUCCESS",
  GETALL_USERS_FAILURE: "GETALL_USERS_FAILURE",

  UPDATE_USER_REQUEST: "UPDATE_USER_REQUEST",
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
  UPDATE_USER_FAILURE: "UPDATE_USER_FAILURE",

  DELETE_USER_REQUEST: " DELETE_USER_REQUEST",
  DELETE_USER_SUCCESS: " DELETE_USER_SUCCESS",
  DELETE_USER_FAILURE: " DELETE_USER_FAILURE",

  RESET_REQUEST: "USERS_RESET_REQUEST",
  RESET_SUCCESS: "USERS_RESET_SUCCESS",
  RESET_FAILURE: "USERS_RESET_FAILURE",

  RESET_PASSWORD_REQUEST: "USERS_RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS: "USERS_RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "USERS_RESET_PASSWORD_FAILURE",

  CONFIRMATION_REQUEST: "USERS_CONFIRMATION_REQUEST",
  CONFIRMATION_SUCCESS: "USERS_CONFIRMATION_SUCCESS",
  CONFIRMATION_FAILURE: "USERS_CONFIRMATION_FAILURE",

  CONTACT_REQUEST: "USERS_CONTACT_REQUEST",
  CONTACT_SUCCESS: "USERS_CONTACT_SUCCESS",
  CONTACT_FAILURE: "USERS_CONTACT_FAILURE",

  GET_SETTINGS: "GET_SETTINGS",
};
