const quizTypes = {
  GETALL_QUIZ_REQUEST: "GETALL_QUIZ_REQUEST",
  GETALL_QUIZ_SUCCESS: "GETALL_QUIZ_SUCCESS",
  GETALL_QUIZ_FAILURE: "GETALL_QUIZ_FAILURE",
  DELETE_QUIZ_REQUEST: "DELETE_QUIZ_REQUEST",
  DELETE_QUIZ_FAILURE: "DELETE_QUIZ_FAILURE",
  DELETE_QUIZ_SUCCESS: "DELETE_QUIZ_SUCCESS",
  EDIT_QUIZ_REQUEST: "EDIT_QUIZ_REQUEST",
  EDIT_QUIZ_FAILURE: "EDIT_QUIZ_FAILURE",
  EDIT_QUIZ_SUCCESS: "EDIT_QUIZ_SUCCESS",
  CREATE_QUIZ_REQUEST: "CREATE_QUIZ_REQUEST",
  CREATE_QUIZ_FAILURE: "CREATE_QUIZ_FAILURE",
  CREATE_QUIZ_SUCCESS: "CREATE_QUIZ_SUCCESS",
  GET_QUIZ_REQUEST: "GET_QUIZ_REQUEST",
  GET_QUIZ_FAILURE: "GET_QUIZ_FAILURE",
  GET_QUIZ_SUCCESS: "GET_QUIZ_SUCCESS",
  RESET_LOADINGS: "RESET_LOADINGS",
  RESET_QUIZ_DATA: "RESET_QUIZ_DATA",
  CLEAR_QUIZ_ERROR: "CLEAR_QUIZ_ERROR",
  SAVE_STUDENT_CURRENT_QUIZ: "SAVE_STUDENT_CURRENT_QUIZ",
  CLEAR_STUDENT_CURRENT_QUIZ: "CLEAR_STUDENT_CURRENT_QUIZ",
  SET_STUDENT_QUIZ_ANSWERS: "SET_STUDENT_QUIZ_ANSWERS",
  SET_ACTIVE_QUIZ_DETAILS: "SET_ACTIVE_QUIZ_DETAILS",
  SET_ACTIVE_QUIZ_HEADER: "SET_ACTIVE_QUIZ_HEADER",
  SET_ACTIVE_CLASS_HEADER: "SET_ACTIVE_CLASS_HEADER",
  SET_RETAKES: "SET_RETAKES",
  SET_REVISION: "SET_REVISION",
};

export default quizTypes;
