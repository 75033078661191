import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";

import Header from '../../Header/Header';
import QuizFinalPageContent from './QuizFinalPageContent';
import Footer from '../../../Common/footer/footer.component';

import authHeader from '../../../../helpers/authHeader';
import { handleResponse } from '../../../../helpers/handleResponse';

const QuizFinalPage = (props) => {
    const API_URL = process.env.REACT_APP_API_URL

    

    const [quiz, setQuiz] = useState("");
    const studentQuizName = useSelector((state) => state.quiz.studentCurrentQuiz)
    const gradeInfo = quiz?.grade;
    const titleInfo = quiz?.title;
    const tName = useSelector((state) => state.authentication.user?.userObj)
    const tSubject = useSelector((state) => state.quiz.active_class_header?.subject)
    const quizId = props.computedMatch.params.quizId;


    const checkActiveQuiz = () => {
        const requestOptions = {
            method: "GET",
            headers: authHeader(),
            credentials: "include",
        };
        fetch(API_URL + '/quiz/active/', requestOptions)
            .then(
                handleResponse
            )
            .then((dataRes) => {
                try{
                    setQuiz(dataRes?.results.some(result => parseInt(quizId) === result?.id))

                } catch {
                    
                }
            })
    }

    const foundQuiz = () => {

    }



    useEffect(() => {
        checkActiveQuiz();
    }, [])

    return (
        <div class='container-fluid' className='main-student-container'>
            <Header 
                title={studentQuizName ? studentQuizName.title : null} 
                grade={gradeInfo ? gradeInfo : null}
                name={tName ? tName.first_name + " " + tName.last_name : null}
                classN={tSubject}/>
            <QuizFinalPageContent 
                classId={props.computedMatch.params.classId} 
                quizId={props.computedMatch.params.quizId}
                title={titleInfo}
                numberOfQ={props.computedMatch.params.numberOfQ}
            />
            <Footer/>
        </div>
    )
}

export default QuizFinalPage;