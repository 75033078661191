import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import './TeacherRegistrationIntroduction.css';

import { Button, TextField } from '@mui/material';
import { ReactComponent as Logo } from '../../../../assets/img/logo.svg';
import { Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
const TeacherRegistrationIntroduction = props => {
    const API_URL = process.env.REACT_APP_API_URL;
    const authenticationToken = props.match.params.authenticationToken;
    const [currentContent, setCurrentContent] = useState(0);
    const emailRef = useRef();
    const firstNameRef = useRef();
    const lastNameRef = useRef();
    const [errors, setErrors] = useState({
        firstName: '',
        lastName: '',
        email: ''
    });

    const history = useHistory();

    // const registerTeacher = () => {
    //     const requestOptions = {
    //         method: 'POST',
    //         headers: authHeader(),
    //         credentials: 'include',
    //         body: JSON.stringify({
    //             first_name: firstName,
    //             last_name: lastName,
    //             email: email,
    //             registration_token: authenticationToken
    //         })
    //     };
    //     fetch(API_URL + `/clever/signup/signupTeacher/`, requestOptions)
    //         .then(handleResponse)
    //         .then(data => {
    //             history.push('/login');
    //         });
    // };

    // const handleSubmit = () => {
    //     setSubmitted(true);

    //     if (firstName.trim() === '') {
    //         return;
    //     }
    //     if (lastName.trim() === '') {
    //         return;
    //     }
    //     if (email.trim() === '' || !/\S+@\S+\.\S+/.test(email)) {
    //         return;
    //     }

    //     registerTeacher();
    // };

    const handleFormSubmit = e => {
        e.preventDefault();
        setErrors({
            firstName: '',
            lastName: '',
            email: ''
        });
        const firstNameValue = firstNameRef.current.value;
        const lastNameValue = lastNameRef.current.value;
        const emailValue = emailRef.current.value;
        setErrors(prevErrors => ({
            ...prevErrors,
            firstName: firstNameValue === '' ? 'The first name is required.' : '',
            lastName: lastNameValue === '' ? 'The last name is required.' : '',
            email: emailValue === '' ? 'The email is required.' : ''
        }));

        // console.log(errors)
    };

    const MessageComponent = () => {
        return (
            <div>
                <p>
                    SpiralMath is a new teacher tool that dramatically strengthens students retention of math skills. It is normal
                    for a class to reach competence in basic skills, then forget much of what they learned (which is the reason
                    teachers review before an exam).
                </p>
                <p>
                    SpiralMath uses several principles of learning psychology that are rarely applied in K-12
                    classrooms....specifically
                </p>
                <ul>
                    <li>Retrieval practice</li>
                    <li>Spaced repetition</li>
                    <li>Interleaving</li>
                    <li>Formative assessment</li>
                </ul>

                <p>
                    When SpiralMath was piloted in a Grade 3 in a Baltimore City school, the class pass rate on the Maryland state
                    exam increased 280%. This was achieved while reducing the teacher’s workload.
                </p>
                <p>
                    If your students seem to struggle when learning new math skills; reach a moderate degree of mastery; but then
                    display very poor achievement at the end of the year, SpiralMath can help.
                </p>
                <p>
                    SpiralMath was developed by two experienced teachers. Joe and David have completed implementation for grades 3
                    and 4; next year they will add grades 5 and 6, with higher grades to come in later years.
                </p>
                <p>
                    The best way to become familiar with SpiralMath is to view the three videos at{' '}
                    <a href="bit.ly/spiralmath-intro">Introduction to SpiralMath - List of Links.pdf</a> [opens in separate
                    window]
                </p>
                <p>
                    If you are teaching grade 3 or grade 4 and would like to try SpiralMath... or you would like to receive future
                    updates about our progress, please click continue to register.
                </p>
            </div>
        );
    };

    return (
        <div className="registration-page-container">
            <div className="registration-page-container-content">
                <Logo
                    style={{
                        display: 'block',
                        width: 'auto',
                        height: 'auto',
                        maxWidth: '100%',
                        maxHeight: '100%',
                        margin: '2rem auto 0'
                    }}
                />
                <>
                    <h1 className="text-center form-heading">
                        <FormattedMessage
                            id="pageRegistrationIntro"
                            defaultMessage="Welcome to SpiralMath"
                            description="Header of the registration page"
                        />
                    </h1>

                    <div>
                        <MessageComponent />
                    </div>
                    <Link to={`/teacher-registration-form/${authenticationToken}`}>
                        <Button variant="primary" className="text-center custom-btn registration-page-container-continue-button">
                            Continue
                        </Button>
                    </Link>
                </>
            </div>
        </div>
        // <div>
        //     <h1 className='title-teacher'>Teacher's first login for SpiralMath</h1>

        //     <div className='messages'>
        //         <p>You will need to complete this form just once; </p>
        //         <p className='second-p'>your later sign-ins will be via a single click.</p>
        //         <br/>
        //         <p>Your first access to your account will be delayed while we confirm your account;</p>
        //         <p className='second-p'> you will receive an email when the confirmation is complete.</p>
        //     </div>

        //     <div className='main'>
        //         <div className='box'>
        //             <p className='form-title'>Your first name</p>
        //             <CustomTextField
        //                 style={{width: '23vw', marginLeft: '20px'}}
        //                 id="outlined-basic"
        //                 label="required"
        //                 variant="outlined"
        //                 required
        //                 focused
        //                 color="secondary"
        //                 value={firstName}
        //                 type="text"
        //                 onChange={(e) => setFirstName(e.target.value)}
        //                 error = { submitted && firstName === "" ? true : false}
        //                 helperText = { submitted && firstName === "" ? "Please enter a valid first name (must contain only letters) " : " "}
        //             ></CustomTextField>
        //         </div>

        //         <div className='box'>
        //             <p className='form-title'>Your last name</p>
        //                 <CustomTextField
        //                     style={{width: '23vw', marginLeft: '20px'}}
        //                     id="outlined-basic"
        //                     label="required"
        //                     variant="outlined"
        //                     required
        //                     focused
        //                     color="secondary"
        //                     type="text"
        //                     value={lastName}
        //                     onChange={(e) => setLastName(e.target.value)}
        //                     error = { submitted && lastName === "" ? true : false}
        //                     helperText = { submitted && lastName === "" ? "Please enter a valid last name (must contain only letters) " : " "}
        //                 ></CustomTextField>
        //         </div>

        //         <div className='box'>
        //             <p className='form-title'>Your work email</p>
        //             <CustomTextField
        //                 style={{width: '23vw', marginLeft: '20px'}}
        //                 id="outlined-basic"
        //                 label="required"
        //                 variant="outlined"
        //                 required
        //                 focused
        //                 color="secondary"
        //                 type="email"
        //                 value={email}
        //                 onChange={(e) => setEmail(e.target.value)}
        //                 error = { submitted && (email === '' || !/\S+@\S+\.\S+/.test(email))? true : false}
        //                 helperText = { submitted && (email === '' || !/\S+@\S+\.\S+/.test(email)) ? "Please enter a valid email " : " "}
        //             ></CustomTextField>
        //         </div>
        //     </div>
        //     <button className="button-student-registration" onClick={handleSubmit}>
        //         Save
        //     </button>

        // </div>
    );
};

export default TeacherRegistrationIntroduction;
