export const filterInitialState = {
  grade_level: "_Any",
  mills_difficulty_level: "_Any",
  dok: "_Any",
  question_style: "_Any",
  summative_status: "_Any",
  state_model: "_Any",
  standard_code: "_Any",
  content_source: "",
  author_memo: "",
};
