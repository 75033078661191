import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';

import ClassQuizzesHeader from './ClassQuizzesHeader';
import ClassQuizzesContent from './ClassQuizzesContent';
import TeacherHeader from '../Teacher Schedule/TeacherHeader';
import {useHistory} from "react-router-dom";

const ClassQuizzes = (props) => {

    const tName = useSelector((state) => state.authentication.user?.userObj)
    const role = useSelector((state) => state.authentication.user?.userObj?.role)

    const history = useHistory();

    
    return (
        <div className="container-fluid">
            <TeacherHeader 
                name={tName ? tName.first_name + " " + tName.last_name : null}
                role={role}
                title={`Quizzes for ${props.computedMatch.params.className}`}
            />
            <ClassQuizzesContent 
                classPeriod={props.computedMatch.params.classPeriod} 
                className={props.computedMatch.params.className} 
                history={history}
            />
        </div>
    )
}

export default ClassQuizzes;
