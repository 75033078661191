import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { createStructuredSelector } from "reselect";
import parse from "html-react-parser";

import authHeader from '../../../helpers/authHeader';
import { handleResponse } from '../../../helpers/handleResponse';
import { alphabetArray } from '../../../helpers/utils';
import ContactUsModalComponent from '../../Common/contact-us-modal/contact-us-modal.component';
import { selectShow } from '../../../redux/modals/modal.select';
import { toggleShow } from '../../../redux/modals/modals.actions';
import { userActions } from '../../../redux/user/user.actions';
import FooterComponent from '../../Common/footer/footer.component';

import * as AiIcons from 'react-icons/ai';
import * as HiIcons from 'react-icons/hi';

import {
    Table, 
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';

import { 
    BarChart, 
    Bar, 
    XAxis, 
    YAxis, 
    Tooltip, 
    Cell 
} from 'recharts';

import './ResultsTable.css';


const ResultsTable = ({ classPeriod, className, quizId, previewQuizId, show, toggleShow }) => {

    const API_URL = process.env.REACT_APP_API_URL;
    const history = useHistory();
    const dispatch = useDispatch();

    const [data, setData] = useState([])
    const [data2, setData2] = useState()
    const [questions, setQuestions] = useState(null)


    const handleLogout = () => {
        dispatch(userActions.logout());
    };

    const handleContactUs = () => {
        toggleShow();
    };

    const fetchData = () => {
        const requestOptions = {
            method: "GET",
            headers: authHeader(),
            credentials: "include",
        };
        fetch(API_URL + `/classqzresult/${quizId}/result/`, requestOptions)
            .then(
                handleResponse
            )
            .then(
                (data) => {
                    if (data && data.status_code !== 400) {
                        setData(data)
                    } else {
                        setData(null);
                    }
                })
        // .then((data) => setData(data))
    }

    const fetchQuizPreview = (quizId) => {
        const requestOptions = {
            method: "GET",
            headers: authHeader(),
            credentials: "include",
        };
        fetch(API_URL + `/quiz/${quizId}/`, requestOptions)
            .then(
                handleResponse
            )
            .then((data) => {
                setQuestions(data.questions)
                setData2(data)
            })
    }

    useEffect(() => {
        fetchData();
        fetchQuizPreview(previewQuizId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const firstRow = data && data.no_errors ? data?.no_errors : {};
    const answerRow = data && data.result ? Object.values(data?.result) : []; 
    

    const tableView = (
        <div className='results-container'>
            <div className='back-results'>
                <button 
                    className='back-button-results'
                    onClick={() => history.push(`/class-quizzes/${classPeriod}/${className}`)}
                >
                    BACK TO QUIZZES
                </button>
                <h5>Students with No Errors</h5>
                <ol>
                    {firstRow && firstRow.no_errors_count && firstRow.no_errors_data.length ? firstRow.no_errors_data.map(item => 
                        <li>{item.name}</li>
                    ) : <p>No Students with all Questions Correct</p>}
                </ol>
            </div>
            

            <TableContainer className='results-table-container'>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center" style={{ fontWeight: 'bold', color: 'black'}} >Question</TableCell>
                            <TableCell align="center" style={{ fontWeight: 'bold', color: 'black' }}> Standard </TableCell>
                            <TableCell align="center" style={{ fontWeight: 'bold', color: 'black', width: 240 }}> Answer Selections </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {answerRow.length ? answerRow.map((item, index) => {
                            
                            var myData = Object.keys(item).map(key => {
                                return item[key];
                            })

                            var studentAnswers = 0;
                            myData && myData.map((it) => studentAnswers += it.nAnswers)

                            return (
                                <TableRow key={index}>
                                    <TableCell align="center" style={{ width: 20 }} className="question">{index+1}</TableCell>
                                    <TableCell align="center" style={{ borderBottom: '2px solid grey' }} className="standard">{item[0].standard_code}
                                        <p>{item[0].standard_brief}</p>    
                                    </TableCell>
                                    <TableCell align="center" style={{ borderBottom: '2px solid grey' }} className="bar-chart">
                                        <BarChart
                                            width={200}
                                            height={100}
                                            data={myData}
                                            margin={{
                                                top: 5,
                                                left: 10,
                                                bottom: 5,
                                            }}
                                            barSize={20}
                                        >
                                            <XAxis dataKey="label" scale="point" padding={{ left: 10, right: 10 }} />
                                            <YAxis allowDecimals={false} tickCount={studentAnswers+1}/>
                                    
                                            <Tooltip/>
                                            
                                            <Bar dataKey="nAnswers"> 
                                                {myData && myData.map((it) => (
                                                    <Cell fill={it.correct === true ? 'green' : 'grey' } />
                                                ))}
                                            </Bar>
                                        </BarChart>
                                    </TableCell>
                                </TableRow> 
                        )}) 
                        : 
                        <p style={{color: 'grey', marginTop: 30, textAlign: 'center'}}>There are no results at this moment.</p>
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <div className='results-answers'>
                <p className='answers-title'>{data2?.title}</p>
                <div className='answers-content'>
                    {questions ? questions.map((item) => {
                        return (
                            <div style={{ marginBottom: '1.5rem' }}>
                                <p style={{ color: 'red', fontWeight: 'bold', marginTop: 20, marginLeft: 40 }}>{item.order}.</p>
                                <p style={{ marginLeft: 40 }}> {parse(item.question.value)}</p>
                                {item.question.answers.map((ans, index, key) => {
                                    return ans.correct === true ?
                                        <ul style={{ fontWeight: 'bold', marginLeft: 40 }}>{`${alphabetArray[index]} ${ans.content}`}</ul>
                                        :
                                        <ul style={{ marginLeft: 40 }}>{`${alphabetArray[index]} ${ans.content}`}</ul>
                                })}
                            </div>)
                    }) : <p>No data</p>}
                </div>
            </div>
            <div className='results-footer'>
                <FooterComponent />
            </div>
        </div>
    );

    return tableView;
};

const mapStateToProps = createStructuredSelector({
    show: selectShow,
});

const mapDispatchToProps = (dispatch) => {
    return {
        toggleShow: () => dispatch(toggleShow()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResultsTable);


const tableContainerStyles = {
    overflow: 'scroll', 
    maxHeight: 600, 
    width: 1140, 
    height: 600, 
    marginLeft: -15,
     marginTop: 30
}

const span1Styles = {
    color: 'grey',
    marginLeft: 20, 
    fontWeight: 'bold'
}

const span2styles = {
    marginRight: '10%', 
    fontWeight: 'bold', 
    float: 'right'
}

const div1Styles = {
    width: 1140, 
    marginLeft: -15,
    marginTop: 60, 
    border: '2px solid', 
    borderColor: '#DEDEDE', 
    borderRadius: 4, 
    boxShadow: ' 0 2px 2px #DEDEDE', 
    textAlign: 'center'
}

const p1Styles = {
    fontSize: 30, 
    marginTop: 20, 
    marginBottom: 30
}

const div2Styles = { 
    width: '50%', 
    textAlign: 'justify',
     margin: 'auto', 
     borderTop: '2px solid #DEDEDE'
}