import React from "react";

import { ReactComponent as Logo } from "../../../assets/img/logo.svg";

const LogoAboveBox = () => {
   return (
      <h4 className="text-center">
         <div className="logo">
            <Logo />
         </div>
      </h4>
   );
};

export default LogoAboveBox;
