import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { FormattedMessage } from 'react-intl';

import { handleResponse } from '../../../helpers/handleResponse';
import authHeader from '../../../helpers/authHeader';
import ContactUsModalComponent from '../../Common/contact-us-modal/contact-us-modal.component';
import { selectShow } from '../../../redux/modals/modal.select';
import { toggleShow } from '../../../redux/modals/modals.actions';
import { userActions } from '../../../redux/user/user.actions';
import { createStructuredSelector } from "reselect";

import FooterComponent from '../../Common/footer/footer.component';
import { gradesCreateClass } from '../../../content';

import {
    Table, 
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Snackbar,
    IconButton,
    Alert
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import { Dialog, Button } from '@mui/material';
import { Form } from 'react-bootstrap';

import { FaPlus } from "react-icons/fa";

import './TeacherScheduleContent.css';




const TeacherScheduleContent = ({ show, toggleShow}) => {

    const API_URL = process.env.REACT_APP_API_URL

    const history = useHistory();
    const dispatch = useDispatch();

    const [ data, setData ] = useState("");
    const [ isModalOpen, setIsModalOpen ] = useState(false);
    const [ createClassOpen, setCreateClassOpen ] = useState(false);
    const [ dialogData, setDialogData ] = useState({id: null, class_name: null})

    const nameRef = useRef();
    const gradeRef = useRef();
    const periodRef = useRef();
    const locationRef = useRef();
    const yearRef = useRef();

    const [ nameError, setNameError ] = useState(null);
    const [ gradeError, setGradeError ] = useState(null);
    const [ periodError, setPeriodError ] = useState(null);
    const [ locationError, setLocationError ] = useState(null);
    const [ yearError, setYearError ] = useState(null);  

    const [ creationError, setCreationError ] = useState(null);
    // const [ submitted, setSubmitted ] = useState(false);

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarVariant, setSnackbarVariant] = useState('success');

    const createClass = () => {
        const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            credentials: 'include',
            body: JSON.stringify({
                name: nameRef?.current?.value,
                grade: gradeRef?.current?.value,
                period: periodRef?.current?.value,
                location: locationRef?.current?.value,
                year: yearRef?.current?.value,
            })
        };
        fetch(API_URL + `/class/`, requestOptions)
            .then(handleResponse)
            .then( data => {
                if(data.status_code === 400) {
                    if(data.hasOwnProperty('name')) {
                        setNameError(data.name[0])
                    }
                    if(data.hasOwnProperty('grade')) {
                        setGradeError(data.grade[0])
                    }
                    if(data.hasOwnProperty('period')) {
                        setPeriodError(data.period[0])
                    }
                    if(data.hasOwnProperty('location')) {
                        setLocationError(data.location[0])
                    }
                    if(data.hasOwnProperty('year')) {
                        setYearError(data.year[0])
                    }
                } else {
                    handleCreateClassClose();
                    setCreateClassOpen(false);
                    setSnackbarMessage('A new class has been created');
                    setSnackbarVariant('success');
                    setOpenSnackbar(true);
                    fetchData();
                }
            })
            .catch(error => {
                setCreationError(`Error: ${error.detail}`);
            })
    }

    const handleFormSubmit = e => {
        e.preventDefault();

        setNameError(null);
        setGradeError(null);
        setPeriodError(null)
        setLocationError(null);
        setYearError(null);

        const nameValue = nameRef?.current?.value.trim();
        const gradeValue = gradeRef?.current?.value.trim();
        const periodValue = periodRef?.current?.value.trim();
        const locationValue = locationRef?.current?.value.trim();
        const yearValue = yearRef?.current?.value.trim();

        if (nameValue && gradeValue && periodValue && locationValue && yearValue) {
            createClass();
            // setCreateClassOpen(false);
        } else {
            if (!nameValue) setNameError('Name is required');
            if (!gradeValue) setGradeError('Grade is required');
            if (!locationValue) setLocationError('Location is required');
            if (!periodValue) setPeriodError('Period is required');
            if (!yearValue) setYearError('Year is required');
        }
    }

    // const SubmittedResponse = () => {
    //     const [seconds, setSeconds] = useState(5);
    //     const [started, setStarted] = useState(false);
    //     function countdown() {
    //         const countInterval = setInterval(() => {
    //             setSeconds(prev => prev - 1);

    //             if (seconds <= 0) {
    //                 clearInterval(countInterval);
    //             }
    //         }, 1000);
    //     }
    //     if (submitted && !started) {
    //         setTimeout(() => {
    //             history.push('/login');
    //         }, 2000);
    //         countdown();
    //         setStarted(true);
    //     }

    //     return (
    //         <div style={{ border: 'none'}}>
    //             <h1 style={{ fontSize: '2em' }} className="text-center form-heading">
    //                 <FormattedMessage
    //                     id="pageCreationResponse"
    //                     defaultMessage="Congratulations!"
    //                     description="Header of the successful creation response"
    //                 />
    //             </h1>
    //             <h2 style={{ fontSize: '1.5em', textAlign: 'center', margin: '2rem 0' }}>You created a new class</h2>
    //         </div>
    //     );
    // };

    const fetchData = () => {
        const requestOptions = {
            method: "GET",
            headers: authHeader(),
            credentials: "include",
        };
        fetch( API_URL + '/class/?ordering=created', requestOptions)
            .then(
                handleResponse
            )
            .then(
                (data) => {
                    setData(data.results)
                }
            )
    }


    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(()=>{
        if(!isModalOpen){
            setTimeout(()=>setDialogData({id:null, class_name:null}),150);
        }
    },[isModalOpen])

    const getStripedStyles = (index) => {
        return { backgroundColor: index % 2 ? '#F8F8F8' : 'white' };
    }

    const deleteClass = id => {
        if (!id) {
            setIsModalOpen(false);
            return;
        }
        fetch(API_URL + `/class/${id}/`, {
            method: 'DELETE',
            headers: authHeader(),
            credentials: 'include'
        })
            .then(handleResponse)
            .then(resp => {
                if (resp.status_code >= 400) {
                    setSnackbarMessage(resp.detail);
                    setSnackbarVariant('error');
                    setOpenSnackbar(true);
                } else {
                    fetchData();
                    setSnackbarMessage(resp.detail);
                    setSnackbarVariant('success');
                    setOpenSnackbar(true);
                }
            })
            .finally(() => {
                setIsModalOpen(false);
            })
            .catch(error => {
                setSnackbarMessage(error.detail);
                setSnackbarVariant('error');
                setOpenSnackbar(true);
            });
    };

    const handleCreateClassClose = () => {
        setCreateClassOpen(false);
        setNameError(null);
        setGradeError(null);
        setPeriodError(null)
        setLocationError(null);
        setYearError(null);
        setCreationError(null);
    }

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    const tableView = (
        <div className='teacher-schedule-container'>

            <div className='schedule-buttons-container'>
                <button 
                    className='classes-buttons'
                    onClick={() => setCreateClassOpen(prev=>!prev)}
                    >
                    <FaPlus className='schedule-icon-class' />
                    CREATE A CLASS
                </button>
            </div>

            <div className='schedule-container'>
                <TableContainer className='schedule-table-container' style={{borderTop: '2px solid rgb(220, 217, 217)', borderBottom: '2px solid rgb(220, 217, 217)', borderLeft:'1px solid rgb(220, 217, 217)', borderRight:'1px solid rgb(220, 217, 217)'}}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell style={cellStyle} > Class Code </TableCell>
                                <TableCell style={cellStyle} > Class Name </TableCell>
                                <TableCell style={{backgroundColor: 'white', color: 'black', fontWeight: 'bold',fontSize: '14px',textAlign:'center'}} > Grade </TableCell>
                                <TableCell style={cellStyle} > Quiz Set </TableCell>
                                <TableCell style={cellStyle} > Year </TableCell>
                                <TableCell style={cellStyle} ></TableCell>
                                <TableCell style={cellStyle} ></TableCell>
                                <TableCell style={cellStyle} ></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data ? data.map((row, index) => (
                                <TableRow key={index}> 
                                    <TableCell style={{...getStripedStyles(index), borderBottom: '2px solid grey'}} > {row?.class_code} </TableCell>
                                    <TableCell style={{...getStripedStyles(index), borderBottom: '2px solid grey', borderLeft: '2px solid grey'}} > {row?.name} </TableCell>
                                    <TableCell style={{...getStripedStyles(index), borderBottom: '2px solid grey', borderLeft: '2px solid grey', textAlign:'center'}} > {row?.grade} </TableCell>
                                    <TableCell style={{...getStripedStyles(index), borderBottom: '2px solid grey', borderLeft: '2px solid grey'}} > {row?.quiz_set_title} </TableCell>
                                    <TableCell style={{...getStripedStyles(index), borderBottom: '2px solid grey', borderLeft: '2px solid grey'}} > {row?.year} </TableCell>
                                    <TableCell style={{...getStripedStyles(index), borderBottom: '2px solid grey', borderLeft: '2px solid grey', width: '10%'}} >
                                        <button
                                            className='preview-button'
                                            onClick={() => history.push(`/class-quizzes/${row.id}/${row.name}`)}
                                        >   
                                            Quizzes
                                        </button>
                                    </TableCell>
                                    <TableCell style={{...getStripedStyles(index), borderBottom: '2px solid grey', width: '10%'}}>
                                        <button 
                                            className='classes-buttons-1'
                                            onClick={() => history.push(`/teacher-clever-students/${row.id}`)}    
                                        >
                                            Students                                            
                                        </button>
                                    </TableCell>
                                    <TableCell style={{...getStripedStyles(index), borderBottom: '2px solid grey', width: '10%', borderLeft: '2px solid grey'}} >
                                        <button
                                            className='delete-button'
                                            onClick={() => {setIsModalOpen(prev=>!prev); setDialogData({id:row.id, class_name:row.name})}}
                                        >   
                                            Delete Class
                                        </button>
                                    </TableCell>
                                </TableRow>
                            )) : null }
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            
            <div className='teacher-footer' style={{marginTop:'1rem'}}>
                <FooterComponent />
            </div>

            <Dialog open={isModalOpen} onClose={()=>{setIsModalOpen(false)}} PaperProps={{style: { height: '15vh', width: '30vw', borderRadius: '30px' }   }}>
                    <p style={{marginTop:'2vh', textAlign: 'center', fontSize: '20px', marginBottom: '3vh'}}>{`Are you sure you want to delete ${dialogData?.class_name}?`}</p>
                
                    <div className='dialog-buttons-row' style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                            <button className='preview-button' onClick={() => { setIsModalOpen(false) }} style={{ width: '120px', fontSize: '0.9em', textAlign: 'center' }}>
                            Cancel
                            </button>
                        </div>
                        <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                            <button className='delete-button' style={{ width: '120px', fontSize: '0.9em', color: '#ff5555', textAlign: 'center' }} onClick={() => { deleteClass(dialogData?.id); }}>
                            Delete
                            </button>
                        </div>
                    </div>
            </Dialog>

            <Dialog 
                className='create-class-dialog' 
                open={createClassOpen}
                onClose={handleCreateClassClose}
                PaperProps={{style: { height: '80vh', width: '50vw', borderRadius: '30px', backgroundImage: '../../' }   }}
            >
                <div className='registration-page-container-form'>
                    <div>
                            <>
                                <h1 className='text-center form-heading'>
                                    <FormattedMessage 
                                        id="classCreationForm"
                                        defaultMessage="Create a Class"
                                        description="Header of Class Creation"
                                    />
                                </h1>
                                {creationError ? (
                                    <p className="class-creation-page-container-form-error" style={{color:'#f36161', textAlign:'center'}}>{creationError}</p>
                                ) : null }
                                <div className="class-creation-page-container-content-form-style">
                                    <Form>
                                        <Form.Group controlId="formName" className="relative">
                                            <Form.Control 
                                                type="text"
                                                className={`input-style input-text ${
                                                    nameRef?.current?.value.length && "label-up"
                                                 }`}
                                                name="name"
                                                ref={nameRef}
                                            />
                                            <div 
                                                className='floating-label'
                                                style={{ top: '-25px', display:'flex', justifyContent:'space-between', width:'100%'}}
                                            >
                                                {nameError === '' ? (
                                                    'Class Name'
                                                ) : (
                                                    <>
                                                        <span style={{ float: 'left'}}>Class Name</span>
                                                        <span style={{ float: 'right', color: 'red', marginRight:'3rem'}}>{nameError} </span>
                                                    </>
                                                )}
                                            </div>
                                        </Form.Group>
                                        <Form.Group className='relative' style={{marginTop:'-1.5rem'}}>
                                            <Form.Label>
                                                <div className='floating-label' style={{top:'-1px', display:'flex', justifyContent:'space-between', width:'100%'}}>
                                                {gradeError === '' ? (
                                                    'Grade'
                                                ) : (
                                                    <>
                                                        <span style={{ float: 'left'}}>Grade</span>
                                                        <span style={{color: 'red', marginRight:'3rem' }}>{gradeError} </span>
                                                    </>
                                                )}
                                                </div>
                                            </Form.Label>
                                            <Form.Control
                                                as="select"
                                                className="input-style input-text"
                                                name="grade"
                                                ref={gradeRef}
                                                style={{ borderRadius: '35px', height: '48px', boxShadow: 'none', color: 'grey'}}
                                                >
                                                
                                                <option value="">Select grade</option>
                                                {gradesCreateClass && gradesCreateClass.map((item, index) => (
                                                    <option key={index} value={item}>{item}</option>
                                                ))}
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group className="relative" style={{paddingTop: '20px'}}>
                                            <Form.Control 
                                                type="text"
                                                className="input-style input-text"
                                                name="period"
                                                ref={periodRef}
                                            />
                                            <div 
                                                className='floating-label'  
                                                style={{ top: '-5px',display:'flex', justifyContent:'space-between', width:'100%'}}
                                            >
                                                {periodError === '' ? (
                                                    'Period'
                                                ) : (
                                                    <>
                                                        <span style={{ float: 'left'}}>Period</span>
                                                        <span style={{ float: 'right', color: 'red', marginRight:'3rem'}}>{periodError} </span>
                                                    </>
                                                )}
                                            </div>
                                        </Form.Group>
                                        <Form.Group className="relative">
                                            <Form.Control 
                                                type="text"
                                                className="input-style input-text"
                                                name="location"
                                                ref={locationRef}
                                            />
                                            <div 
                                                className='floating-label'
                                                style={{ top: '-25px', display:'flex', justifyContent:'space-between', width:'100%'}}  
                                            >
                                                {locationError === '' ? (
                                                    'Location in School'
                                                ) : (
                                                    <>
                                                        <span style={{ float: 'left'}}>Location in School</span>
                                                        <span style={{ float: 'right', color: 'red', marginRight: '3rem'}}>{locationError} </span>
                                                    </>
                                                )}
                                            </div>
                                        </Form.Group>
                                        <Form.Group className="relative">
                                            <Form.Control 
                                                type="text"
                                                className="input-style input-text"
                                                name="year"
                                                ref={yearRef}
                                            />
                                            <div 
                                                className='floating-label' 
                                                style={{ top: '-25px', display:'flex', justifyContent:'space-between', width:'100%'}} 
                                            >
                                                {yearError === '' ? (
                                                    'School Year'
                                                ) : (
                                                    <>
                                                        <span style={{ float: 'left'}}>School Year</span>
                                                        <span style={{ color: 'red', marginRight: '3rem'}}> {yearError} </span>
                                                    </>
                                                )}
                                            </div>
                                        </Form.Group>
                                        <Button
                                            variant="primary"
                                            type="submit"
                                            className="text-center custom-btn registration-page-container-continue-button mt-3"
                                            onClick={handleFormSubmit}
                                        >
                                            Create Class
                                        </Button>
                                    </Form>
                                </div>
                            </>    
                    </div>
                </div>
                <style>
                    {`
                    .input-style.input-text:focus {
                        border: 2px solid blue;
                    }
                    `}
                </style>
            </Dialog>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert severity={snackbarVariant} sx={{ width: '100%', display: 'flex', alignItems: 'center' }} variant="filled">
                    {snackbarMessage}
                    <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackbarClose}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </Alert>
            </Snackbar>
        </div>
    );

    return tableView;
};

const mapStateToProps = createStructuredSelector({
    show: selectShow,
});

const mapDispatchToProps = (dispatch) => {
    return {
        toggleShow: () => dispatch(toggleShow()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeacherScheduleContent);


const cellStyle = {
   backgroundColor: 'white', 
   color: 'black', 
   fontWeight: 'bold',
   fontSize: '14px',
}

const cellStyle2 = {
    backgroundColor: 'white', 
    color: 'black', 
    fontWeight: 'bold',
    fontSize: '14px',
    borderLeft: '2px solid rgb(220, 217, 217)'
 }