import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Table } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";

import logo from '../../ui/assets/less-than.png';
import {useSelector} from "react-redux";


const TeacherHeader = ({ className, year, classPeriod }) => {

    const history = useHistory();

    const user = useSelector((state) => state.authentication.user?.userObj);


    return (
        <Row>
            <Col style={headerStyles}>
                <div>
                    <Table >
                        <thead>
                            <tr>
                                <td style={{ border: 0 }}>
                                    <button
                                        style={buttonStyle}
                                        onClick={() => { history.push(`/class-quizzes/${classPeriod}/${className}`) }}>
                                        <img style={{ width: 20, height: 20 }} src={logo} alt="" />
                                        Back To Quiz List
                                    </button>
                                </td>
                                <th scope="col" className="border-0 thead status-color">
                                    <FormattedMessage
                                        defaultMessage={className}
                                        id="className"
                                    />
                                </th>
                                <th
                                    scope="col"
                                    className="border-0 thead status-color"
                                >
                                    <FormattedMessage
                                        defaultMessage={year}
                                        id="year"
                                    />
                                </th>
                                <th
                                    scope="col"
                                    className="border-0 thead status-color"
                                >
                                    {user ? user.first_name + " " + user.last_name : null}
                                </th>
                            </tr>
                        </thead>
                    </Table>
                </div>
            </Col>
        </Row>
    )
}


TeacherHeader.propTypes = {
    className: PropTypes.string.isRequired,
    subject: PropTypes.string.isRequired,
    year: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
}

TeacherHeader.defaultProps = {
    className: 'Period 1 Results',
    subject: 'Math',
    year: '2021-2022',
    name: 'Michael Doyle',
}

export default TeacherHeader;



const headerStyles = {
    width: 'auto',
    height: 'auto',
    textAlign: 'center',
    position: 'relative',
    marginTop: 20,
}

const buttonStyle = {
    border: 0,
    textDecoration: 'underline',
    color: 'black',
    backgroundColor: 'inherit',
    fontSize: 15,
    fontWeight: 'bold',
    marginLeft: -30
}
