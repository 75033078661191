import React, { useState, useEffect } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { useHistory } from "react-router-dom";
import parse from "html-react-parser";
import { connect } from "react-redux";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Link } from 'react-router-dom';
import Box from "@mui/material/Box";
import * as BsIcons from 'react-icons/bs';
import * as AiIcons from 'react-icons/ai';
import * as HiIcons from 'react-icons/hi';
import logo from '../../ui/assets/less-than.png';
import authHeader from '../../../helpers/authHeader';
import { handleResponse } from '../../../helpers/handleResponse';
import { useDispatch, useSelector } from "react-redux";
import { setStudentQuizAnswersAction } from "../../../redux/quiz/quiz.actions";

import ContactUsModalComponent from '../../Common/contact-us-modal/contact-us-modal.component';
import { selectShow } from '../../../redux/modals/modal.select';
import { toggleShow } from '../../../redux/modals/modals.actions';
import { createStructuredSelector } from 'reselect';
import { userActions } from '../../../redux/user/user.actions';


import './BeginQuizQuestions.css'


const QuizQuestions = ({classId, quizId, activeQuizId, show, toggleShow, numberOfQ }) => {
    const API_URL = process.env.REACT_APP_API_URL;
    const history = useHistory();
    const dispatch = useDispatch();
    const [newQuizData, setNewQuizData] = useState("");
    const answers = useSelector((state) => state.quiz.studentQuizAnswers);
    const dataQuestions = newQuizData?.questions;
    const retake = useSelector((state) => state.quiz.retake);
    const classData = useSelector((state) => state.quiz?.active_class_header)
    const stateFirstAnswer = answers && answers.length ? answers[0].answer : null; 

    


    const [value, setValue] = React.useState(stateFirstAnswer);
    const [currQuestion, setCurrQuestion] = useState(0);
    const [data, setData] = [dataQuestions];
    const [classData2, setClassData2] = useState("")
    const [errorMessage, setErrorMessage] = useState("")
    const [arrAnswers, setArrAnswers] = useState(answers);
    const [currentStep, updateCurrentStep] = useState(1);

    let selected = currentStep;

    const handleLogout = () => {
        dispatch(userActions.logout());
    };

    const handleContactUs = () => {
        toggleShow();
    };

    const checkActiveQuiz = () => {
        const requestOptions = {
            method: "GET",
            headers: authHeader(),
            credentials: "include",
        };
        fetch(API_URL + `/quiz/${quizId}/`, requestOptions)
            .then(
                handleResponse
            )
            .then((dataRes) => {
                try{
                setNewQuizData(dataRes)
                } catch{

                }
            })
    }

    const saveData = (class_id, quiz_id, question_id, answer_id) => {

        const requestOptions = {
            method: "GET",
            headers: authHeader(),
            credentials: "include",
        };
        fetch(API_URL + '/quiz/active/', requestOptions)
            .then(
                handleResponse
            )
            .then((dataRes) => {
                const dataObj = {
                    "question": question_id,
                    "answer": dataRes.active === true || retake === true ? parseInt(answer_id) : null,
                }
                const index = arrAnswers.findIndex(o => o.question === dataObj.question);

                if (index >= 0) {
                    arrAnswers[index] = dataObj;
                } else {
                    arrAnswers.push(dataObj);
                }
                setArrAnswers(arrAnswers);

                nextQuestion();
            })
            .catch((err) => alert("error"))
        
    }


    const fetchSubject = () => {
        const requestOptions = {
            method: "GET",
            headers: authHeader(),
            credentials: "include",
        };
        fetch(API_URL + '/class/', requestOptions)
            .then(
                handleResponse
            )
            .then((dataRes) => {
                if (dataRes.results && dataRes.results[0]) {

                    setClassData2(dataRes.results[0]?.id)
                }
            })
    }

    const nextQuestion = () => {
        if (value === null) {
            setErrorMessage("Please choose an option")
        } else {

            const nextQuestion = currQuestion + 1;
            setCurrQuestion(nextQuestion);
            setErrorMessage("")


            if (data && data[nextQuestion] && arrAnswers.length) {              
                const index = arrAnswers.findIndex(o => {
                    return o.question === data[nextQuestion].question.id
                });
                if (index >= 0) {
                    setValue(arrAnswers[index].answer.toString())
                } else {
                    setValue(null)
                }
            } else {
                setValue(null)
            }
            if (currQuestion === data.length - 1) {

                dispatch(setStudentQuizAnswersAction(arrAnswers));
                history.replace(`/finishing-up/${classId}/${quizId}`)
            }
        }
    }

    const handleChange = (event) => {
        setValue(event.target.value.toString());
    };


    const prevQuestion = () => {
        const prevQuestion = currQuestion - 1;
        setCurrQuestion(prevQuestion);

        if (data && data[prevQuestion] && arrAnswers.length) {
            const index = arrAnswers.findIndex(o => {
                return o.question === data[prevQuestion].question.id
            });
            if (index >= 0) {
                setValue(arrAnswers[index].answer.toString())
            }
        }
    }

    useEffect(() => {
        fetchSubject();
        checkActiveQuiz();

        if (data && data[0] && arrAnswers.length) {
            const index = arrAnswers.findIndex(o => o.question === data[0]?.question?.id);
            if (index >= 0) {
                setValue(arrAnswers[index].answer.toString())
            }
        } else if(arrAnswers.length){
            setValue(arrAnswers[0]?.answer.toString())
        }
        
        // if (performance.navigation.type === 0 ) {
        //     history.push(`/quiz-started/${quizId}`);
        // } else if (performance.navigation.type === 0 && currQuestion === data.length - 1) {
        //     history.push(`/finishing-up/${classData2}/${quizId}`)
        // } else if (performance.navigation.type === 1) {
        //     history.push('/landing-page')
        // } 

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    
    const answerData = data && data[currQuestion] && data[currQuestion].question.answers ? data[currQuestion].question.answers : []
    const str = data && data[currQuestion] ? (data[currQuestion].question.value) : null
    const find = 'tbody'
    const tableFormatt = str ? str.toString().includes(find) : null;

    return (
       /* <div className="container" style={containerStyles} >
            <p align="left" style={pStyles}>{currQuestion + 1}</p>

            <FormControl component="fieldset" >
                <FormLabel style={questionStyles}>
                    {data && data[currQuestion] ? parse(data[currQuestion].question.value) : null}
                    {data && data[currQuestion] && data[currQuestion].question.image ? <img src={data[currQuestion].question.image} alt="image" height="150" /> : null }
                </FormLabel>

                <p style={p1Styles}> {errorMessage !== "" ? errorMessage : null} </p>

                <RadioGroup 
                    name="controlled-radio-buttons-group"
                    value={value}
                    onChange={handleChange}
                    row
                    style={{ paddingLeft: 100 }}
                >
                    {answerData.length && answerData.map((row, index) => (
                        <FormControlLabel 
                            control={<Radio />} 
                            value={row.id.toString()} 
                            label={String.fromCharCode(row.order + 65) + "\t"+ row.content} 
                            key={index} 
                            style={answerStyles} />
                    ))}
                </RadioGroup>
            </FormControl>

            {currQuestion !== 0 ?
                <div>
                    <button
                        onClick={() => prevQuestion()}
                        style={button1Styles}
                    >
                        <img style={{ width: 20, height: 20 }} src={logo} alt="" /> Back

                    </button>
                </div> 
                : null}

            {currQuestion < data?.length - 1 ?
                <p align="right" style={{ marginTop: 20 }}>
                    <button 
                        onClick={() => saveData(classData.id, data.id, data[currQuestion].question.id, value)} 
                        style={buttonStyles}
                    > 
                        Save & Next 
                    </button>
                </p>
                :
                <p align="right" style={{ marginTop: 20 }}>
                    <button 
                        onClick={() => saveData(classData.id, data.id, data[currQuestion].question.id, value) }
                        style={buttonStyles}
                    >
                        Save & Next
                    </button>
                </p>
            }
        </div>*/

        <div className='quiz-questions-container'>

            <div className="questions-page-info" > 
                <FormControl className='control-form-1'>
                    <p className='left' style={pStyles}>{currQuestion + 1}</p>
                    <p className='question-title'>
                        {data && data[currQuestion] && tableFormatt ? (
                            <div style={{alignItems: 'center', textAlign: 'center', justifyContent: 'center'}}>
                                <table style={{marginLeft: '43%', alignItems: 'center', margin: 'auto' }}>{parse(data[currQuestion].question.value)}</table>
                            </div>
                        ) : <p>{data && data[currQuestion] ? parse(data[currQuestion].question.value): null}</p>}
                        {data && data[currQuestion] && data[currQuestion].question.image ? <img src={data[currQuestion].question.image} alt="image" height="200" /> : null}
                        
                    </p>

                    <p className='error-msg'> {errorMessage !== "" ? errorMessage : null} </p>

                    <RadioGroup style={{ border: '2px solid rgb(220, 217, 217)', paddingLeft: 80, paddingRight: 80, backgroundColor: 'rgb(248, 248, 255)', borderRadius: 20}}
                        className='radio-buttons'
                        name="controlled-radio-buttons-group"
                        value={value}
                        onChange={handleChange}
                        column
                        
                    >
                        {answerData.length && answerData.map((row, index) => (
                        <div key={row?.id}>
                                <FormControlLabel 
                                    className='control-label'
                                    control={<Radio />}
                                    value={row.id.toString()}
                                    label={<Box style={{ fontWeight: 'bold' }}>
                                        <span style={ {marginRight: 26}}>{String.fromCharCode(index + 65)}</span>
                                        <span style={{ fontWeight: 'normal' }}>  {" " + row.content}</span>
                                    </Box> 
                                    }
                                    key={index} />
                                
                            </div>
                        ))}
                    </RadioGroup>
                </FormControl>
                
                
                <div className='footer-quiz'>
                    {currQuestion !== 0 ?
                    <div className='back-quiz-button'>
                        <button class="btn-prev-1" id="prev" 
                            onClick={() => {
                                prevQuestion()
                                updateCurrentStep(currQuestion)
                            }} >Go Back</button>
                            </div>
                        :
                        <div className='back-quiz-button' > <button class="btn-prev-1" id="prev" disabled >Go Back</button></div>
                    }

                    

                    {currQuestion < data?.length - 1 ?
                    <div  className='next-quiz-button'>
                        <button class="btn-next-1" id="next" 
                            onClick={() => {
                                saveData(classData2, newQuizData.id,data[currQuestion].question.id, value)
                                updateCurrentStep(currQuestion)
                            }} >
                                Save & Next
                        </button>
                        </div>
                        :
                        <div className='next-quiz-button' >
                        <button class="btn-next-1" id="next"
                            onClick={() => {
                                saveData(classData2, newQuizData.id, data[currQuestion].question.id, value)
                                updateCurrentStep(currQuestion)
                            }} >
                                Save & Next
                        </button>
                        </div>
                        }
                </div>

            </div>

           
        </div>
    );
}
const mapStateToProps = createStructuredSelector({
    show: selectShow,
});

const mapDispatchToProps = (dispatch) => {
    return {
        toggleShow: () => dispatch(toggleShow()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(QuizQuestions);

const containerStyles = {
    height: '60%',
    width: '110%',
    marginLeft: -15,
    borderBottom: '3px solid black',
    borderLeft: '3px solid black',
    borderRight: '3px solid black',
}

const button1Styles = {
    border: 0,
    width: '25%',
    fontFamily: 'Apple Chancery, cursive',
    fontWeight: 'bold',
    fontSize: 25,
    color: 'black',
    cursor: 'pointer',
    textAlign: 'center',
    backgroundColor: 'inherit',
    float: 'left',
    marginTop: 40
}

const buttonStyles = {
    border: '0 !important',
    width: '25%',
    fontFamily: 'Apple Chancery, cursive',
    fontWeight: 'bold',
    fontSize: 25,
    color: 'black',
    backgroundColor: '#E8E8E8',
    cursor: 'pointer',
    borderRadius: 20,
    boxShadow: '0 9px #999',
    textAlign: 'center',
    marginBottom: 10,
    marginRight: 40,
}

const pStyles = {
    fontSize: 50,
    paddingLeft: 20,
    color: 'black',
    width: 70,
}

const p1Styles = {
    color: 'red', 
    fontWeight: 'bold', 
    minHeight: '25px'
}

const questionStyles = {
    marginTop: '-3%',
    paddingLeft: 100,
    color: 'black',

}

const answerStyles = {
    marginTop: -10,
    color: 'black',
    minWidth: '100%',

}


