export default {
  pageLoginHeader: "Teachers and Students, click button",
  pageLoginSecondHeader: "Administrators & others, login",
  pageLoginEmailLabel: "Email",
  pageLoginEmailRequiredValidation: "Email is Required",
  pageLoginEmailInvalidValidation: "Email is invalid",
  pageLoginPasswordRequiredValidation: "Password is required",
  pageLoginPasswordLabel: "Password",
  pageLoginForgotPassword: "Forgot Password? ",
  pageLoginResetPasswordLink: "Reset",
  pageLoginHaveIssue: "Have issues? ",
  pageLoginContactUs: "Contact Us",
  pageLoginLoginButton: "Log In",

  pageSignupTermsHeader: "Terms and Conditions",
  pageSignupCreateYourAccount: "Create Your Account",
  pageSignupPasswordLabel: "Create Password",
  pageSignupPasswordConfirmLabel: "Confirm Password",
  pageSignupIhaveread: "I have read and accept the",
  pageSignupTermsLink: "Terms of use",
  pageSignupSignupLink: "Sign up",
  pageSignupAlreadyHave: "Already have an account? ",
  pageSignupLoginLink: "Login ",
  pageSignupHaveIssue: "Have issues? ",
  pageSignupContactus: "Contact Us",
  pageSignupPasswordRequiredvalidation: "Password is required",
  pageSignupPasswordTooShortValidation:
    "Password is too short. Password should contain at least 8 characters long.",
  pageSignupPasswordVariationValidation:
    "Password should contains at least one capital letter, 1 small letter and a special character.",
  pageSignupPasswordConfirmRequiredValidation: "Confirm Password is required",
  pageSignupPasswordSameValidation: "Passwords does not match.",
  pageSignupTermsvalidation: "You should agree to the Terms of Use.",

  pageForgotHeader: "Reset Your Password",
  pageForgotInstructions1: "We have sent you the instructions to your email to",
  pageForgotInstructions2:
    "reset your password. If you do not recieve an email,",
  pageForgotInstructions3: "check your spam folder or make sure your email",
  pageForgotInstructions4: "from SpiralMath are approved.",
  pageForgotEmailLabel: "Email",
  pageForgotResetButton: "Send Password Reset",
  pageForgotAlreadyHaveAnAccount: "Already have an account? ",
  pageForgotLoginLink: "Login",
  pageForgotHaveIssue: "Have issues? ",
  pageForgotContactUs: "Contact Us",
  pageForgotEmailRequiredValidation: "Email is required",
  pageForgotEmailInvalidValidation: "Email is invalid",
  pageForgotInstructions1_1: "Please enter your email and we will send you an",
  pageForgotInstructions1_2: "instruction email.",

  pageUsersManagementHeader: "Users Management",
  pageUsersDashboardTab: "Dashboard",
  pageUsersUsersTab: "Users",
  pageUsersUserTypesTab: "User Types",
  pageUsersSettingsTab: "Settings",
  pageUsersLogout: "Logout",

  pageQuestionsHeader: "Questions Management",
  pageQuestionsMyQuestionsTab: "My Questions",
  pageQuestionsAllQuestionsTab: "All Questions",
  pageQuestionsQuestionsTab: "Questions",
  pageQuestionsReviewsTab: "Reviews",
  pageQuestionsLogout: "Logout",

  pageResetLink: "Reset Password",

  componentSettingsContact: "Contact Us Email",
  componentSettingsNonRegUser: " Non Registered Users",
  componentSettingsRegUser: "Registered Users",

  componentContactHeader: "Contact Us",
  componentContactEmailLable: "Email",
  componentContactMessageLabel: "Message",
  componentContactSubmitButton: "Submit",
  componentContactEmailRequiredValidation: "Email is required",
  componentContactEmailInvalidValidation: "Email is invalid",
  componentContactMessageRequiredValidation: "This field is required",

  componentFooterText: "2020 Spiralmath Inc. All rights reserved.",

  componentUserTypesUserTypeLabel: "User Type",
  componentUserTypesCreateQuestionsLabel: "Can Create Questions",
  componentUserTypesReviewQuestionsLabel: "Can Review Questions",
  componentUserTypesCancelButton: "Cancel",
  componentUserTypesSaveButton: "Save",
  componentUserTypesTableHeadUserTypes: "User Type",
  componentUserTypesTableHeadDescription: "Description",
  componentUserTypesTableHeadCreateUserType: "Create User Type",
  componentUserTypesDeleteModalWarning: "User Type will be deleted.",

  componentUserTypesDeleteModalCancelbutton: "Cancel",
  componentUserTypesDeleteModalConfirmbutton: "Confirm",
  componentUserTypesDescription1: "Can create and review questions",
  componentUserTypesDescription2: "Can create questions",
  componentUserTypesDescription3: "Can review questions",
  componentUserTypesUserTypeValidation: "User Type is required",

  componentUsersTabFirstNameLabel: "First Name",
  componentUsersTabLastNameLabel: "Last Name",
  componentUsersTabEmailLabel: "Email",
  componentUsersTabCancelButton: "Cancel",
  componentUsersTabSaveButton: "Save",
  componentUsersTabTableHeadUserId: "User ID",
  componentUsersTabTableHeadUser: "User",
  componentUsersTabTableHeadEmail: "Email",
  componentUsersTabTableHeadRole: "Role",
  componentUsersTabTableHeadStatus: "Status",
  componentUsersTabTableHeadCreateUser: "Create User",
  componentUsersTabTableNoUser: "No User",
  componentUsersTabDeleteModalWarning: "User will be deleted",
  omponentUsersTabActive: "Active",
  omponentUsersTabSent: "Sent",
  omponentUsersTabInActive: "InActive",

  componentUsersTabDeleteModalCloseButton: "Close",
  componentUsersTabDeleteModalDeleteButton: "Delete",
  componentUsersTabEmailRequiredValidation: "Email is required",
  componentUsersTabEmailInvalidValidation: "Email is invalid",
  componentUsersTabFirstNameRequiredValidation: "First name is required",
  componentUsersTabLastnameRequiredValidation: "Last name is required",
  componentUsersTabRoleRequiredValidation: "Role is required",
  componentUsersTabDefaultSelect2: "Select Role",
  componentUsersTabDefaultSelect1: "Select Role",
  componentReviewerQuestionsTableReview: "Review Question",

  componentDeleteModalDeleteModalHeader: "Are You Sure?",
  questionDeleteMessage: "Question will be deleted",
  reviewsTab: "My Reviews",
  requiredFieldError: "This field is required",
  addCreatorLabel: "Add Creator",
  creatorFirstNameLabel: "First Name",
  creatorLastNameLabel: "Last Name",
  QuizDeleteModalWarning: "Quiz will be deleted",

  pageRegistrationFormStudent:"After registering you'll be able to join your class!",
  pageRegistrationIntro: "Welcome to SpiralMath!",
  pageRegistrationForm: "Create your account",
  pageRegistrationResponse: "Congratulations!"
};
