import { intl } from "../intl";

export default function validateLogin(values) {
  let errors = {};
  if (!values.email) {
    errors.email = intl.formatMessage({
      id: "pageLoginEmailRequiredValidation",
      defaultMessage: "Email is required",
    });
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = intl.formatMessage({
      id: "pageLoginEmailInvalidValidation",
      defaultMessage: "Email is invalid",
    });
  } else if (/(\.\.)/.test(values.email)) {
    errors.email = intl.formatMessage({
      id: "pageLoginEmailInvalidValidation",
      defaultMessage: "Email is invalid",
    });
  } /*else if (/\S+:/.test(values.email)) {
    errors.email = intl.formatMessage({
      id: "pageLoginEmailInvalidValidation",
      defaultMessage: "Email is invalid",
    });
  }
  else if (/(.*@.*){2,}/.test(values.email)) {
    errors.email = intl.formatMessage({
      id: "pageLoginEmailInvalidValidation",
      defaultMessage: "Email is invalid",
    });
  }
  else if (/\S+~/.test(values.email)) {
    errors.email = intl.formatMessage({
      id: "pageLoginEmailInvalidValidation",
      defaultMessage: "Email is invalid",
    });
  }*/
  if (!values.password) {
    errors.password = intl.formatMessage({
      id: "pageLoginPasswordRequiredValidation",
      defaultMessage: "Password is required",
    });
  }
  return errors;
}
