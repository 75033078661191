import React, { useEffect, useState} from 'react';
import { useSelector } from 'react-redux';

import authHeader from '../../../helpers/authHeader';
import { handleResponse } from '../../../helpers/handleResponse';

import ClassRetakesContent from './ClassRetakesContent';
import ReportsHeader from '../Teacher Schedule/ReportsHeader';
import TeacherHeader from '../Teacher Schedule/TeacherHeader';

import './ClassRetakesContent.css';

const ClassRetakes = (props) => {
    const API_URL = process.env.REACT_APP_API_URL

    const tName = useSelector((state) => state.authentication.user?.userObj)
    const role = useSelector((state) => state.authentication.user?.userObj?.role)

    const class_id = props.computedMatch.params.classPeriod;
    const class_name = props.computedMatch.params.className;


    return (
        <div>
            <TeacherHeader 
                name={tName ? tName.first_name + " " + tName.last_name : null}
                role={role}
                title="Retake Trends"
            />
            <ClassRetakesContent class_id={class_id} class_name={class_name} />
        </div>
    );
}

export default ClassRetakes;