import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { Button } from '@mui/material';
import Header from '../../Header/Header'

import authHeader from '../../../../helpers/authHeader';
import { handleResponse } from '../../../../helpers/handleResponse';
import './FalseQuizPage.css'
import quizActions from '../../../../redux/quiz/quiz.actions';
const FalseQuizPage = () => {
    const API_URL = process.env.REACT_APP_API_URL
    const dispatch = useDispatch();

    const gradeInfo = useSelector((state) => state.quiz.active_class_header?.grade)
    const tName = useSelector((state) => state.authentication.user?.userObj)
    const tSubject = useSelector((state) => state.quiz.active_class_header?.subject)
    const [quiz, setQuiz] = useState("")
    const history = useHistory();
    useEffect(()=>{

        return ()=>{
            dispatch(quizActions.resetQuizData())
        }
    },[])
    const checkActiveQuiz = () => {
        const requestOptions = {
            method: "GET",
            headers: authHeader(),
            credentials: "include",
        };
        fetch(API_URL + '/quiz/active/', requestOptions)
            .then(
                handleResponse
            )
            .then((dataRes) => {
                try {
                    setQuiz(dataRes.results[0])
                }
                catch {

                }
            })
    }

    useEffect(() => {
        checkActiveQuiz();
    }, [])

    return (
        <div class="container-fluid" className='main-student-container'>
            <Header
                grade={gradeInfo}
                subject={tSubject}
                title={quiz?.title}
                name={tName ? tName.first_name + " " + tName.last_name : null}
            />
            <div style={{height: '70vh', textAlign:'center'}}>
                <h2 style={h2}>We are sorry!</h2>
                <h5 style={h4}>You ran out of time before your submission.</h5>

                <Button className='return-button' onClick={()=>{history.replace('/landing-page')}}>Back to Homepage</Button>
            </div>

        </div>
    )
}

export default FalseQuizPage;

const h2 = {
    textAlign: 'center',
    marginTop: 40,
}

const h4 = {
    textAlign: 'center',
    marginTop: 40,
}