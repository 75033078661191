import userService from "../redux/user/user.services";

export const handleResponse = (response, isLogout = true) => {
   return response.text().then((text) => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
         if (response.status === 401 || response.status === 403) {
            //auto logout if 401 response returned from api
            isLogout && userService.logout();
         }
         if (response.status === 400) {
            return Promise.resolve(data);
         } else {
            return Promise.reject(data);
         }
      }

      return Promise.resolve(data);
   });
}

export const handleResponseDownload = (response, isLogout = true) => {
   return response.arrayBuffer().then((text) => {
      const data = text;
      if (!response.ok) {
         if (response.status === 401 || response.status === 403) {
            //auto logout if 401 response returned from api
            isLogout && userService.logout();
         }
         if (response.status === 400) {
            return Promise.resolve(null);
         } else {
            return Promise.reject(null);
         }
      }

      return Promise.resolve(data);
   });
}



export const handleResponseBadRequest = (response, isLogout = true) => {
   return response.text().then((text) => {
      const data = text && JSON.parse(text);

      if (!response.ok) {
         if (response.status === 401) {
            //auto logout if 401 response returned from api
            isLogout && userService.logout();
         }
         return Promise.reject(data);
      }

      return Promise.resolve(data);
   });
}
