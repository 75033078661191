import questionTypes from "./question.type";
import questionFormStateTypes from "../questionFormState/questionFormState.type";
import questionService from "./question.service";
import { questionFormStateEdit } from "../questionFormState/questionFormState.action";
import { setQuestionType } from "../local/local.actions";


const getQuizSet = () => {
  return (dispatch) => {
    questionService.getQuizSet().then((data) => {
      dispatch({
        type: questionTypes.GETQUIZSET_SUCCESS,
        payload: data.results,
      });
    });
  };


};
const getStandardCode = () => {
  return (dispatch) => {
    questionService.getStandardCode().then((data) => {
      dispatch({
        type: questionTypes.GETSTANDARDCODE_SUCCESS,
        payload: data.results,
      });
    });
  };
};

const getReviewers = () => {
  return (dispatch) => {
    questionService.getReviewers().then((data) => {
      dispatch({
        type: questionTypes.GET_REVIEWERS_SUCCESS,
        payload: data.detail,
      });
    });
  };
};

const getCreators = () => {
  return (dispatch) => {
    questionService.getCreators().then((data) => {
      dispatch({
        type: questionTypes.GET_CREATORS_SUCCESS,
        payload: data.results,
      });
    });
  };
};

const createQuestion = (formData, answer) => {
  return (dispatch) => {
    dispatch({ type: questionTypes.CREATE_QUESTION_REQUEST });
    questionService.createQuestion(formData).then(
      (data) => {
        dispatch({ type: questionTypes.CREATE_QUESTION_SUCCESS });

        const newAnswer = Object.values(answer);
        let answers = []
        newAnswer.forEach((ans) => {
          const answerObj = {
            "content": ans.value,
            "correct": ans.isChecked,
            "order": ans.serial,
            "question": data.id
          }
          answers.push(answerObj)
        })
        dispatch(createAnswer(answers));
      },
      (error) => {
        const key = Object.keys(error)[0];
        error = { key, message: error[key][0] };
        dispatch({ type: questionTypes.CREATE_QUESTION_FAILURE, error });
      }
    );
  };
};

const createAnswer = (data) => {
  return (dispatch) => {
    dispatch({ type: questionTypes.CREATE_ANSWER_REQUEST });
    questionService.createAnswer(data).then(
      (data) => {
        dispatch({ type: questionTypes.CREATE_ANSWER_SUCCESS });
      },
      (error) => {
        dispatch({ type: questionTypes.CREATE_ANSWER_FAILURE });
      }
    );
  };
};

const getUserQuestions = (queryString, paginationQuery = "") => {
  return (dispatch) => {
    dispatch({ type: questionTypes.GET_USERQUESTION_REQUEST });
    questionService.getUserQuestions(queryString, paginationQuery).then(
      (data) => {
        dispatch({ type: questionTypes.GET_USERQUESTION_SUCCESS, data });
      },
      (error) => {
        dispatch({ type: questionTypes.GET_USERQUESTION_FAILURE });
      }
    );
  };
};

const getAllQuestions = (paginationQuery = "") => {
  return (dispatch) => {
    dispatch({ type: questionTypes.GET_QUESTION_REQUEST });
    questionService.getAllQuestions(paginationQuery).then(
      (data) => {
        dispatch({ type: questionTypes.GET_QUESTION_SUCCESS, data });
      },
      (error) => {
        dispatch({ type: questionTypes.GET_QUESTION_FAILURE });
      }
    );
  };
};

const deleteQuestion = (id) => {
  return (dispatch) => {
    dispatch({ type: questionTypes.DELETE_QUESTION_REQUEST });
    questionService.deleteQuestion(id).then(
      (data) => {
        dispatch({ type: questionTypes.DELETE_QUESTION_SUCCESS });
      },
      (error) => {
        dispatch({ type: questionTypes.DELETE_QUESTION_FAILURE });
      }
    );
  };
};
const updateQuestion = (id, data, answer, isReview = false) => {
  return (dispatch) => {
    dispatch({ type: questionTypes.UPDATE_QUESTION_REQUEST });
    return questionService.updateQuestion(id, data, isReview).then(
      (data) => {
        dispatch({ type: questionTypes.UPDATE_QUESTION_SUCCESS });
        const newAnswer = Object.values(answer);

        if (!isReview) {
          newAnswer.forEach((ans) => {
            const answerObj = {
              "content": ans.value,
              "correct": ans.isChecked || false,
              "order": ans.serial,
              "question": id
            }
            if (ans.id) {
              dispatch(updateAnswer(ans.id, answerObj));
            } else {
              dispatch(createAnswer(answerObj));
            }
          })
        }
        return true
      },
      (error) => {
        const key = Object.keys(error)[0];
        error = { key, message: error[key][0] };
        dispatch({ type: questionTypes.UPDATE_QUESTION_FAILURE, error });
        return false
      }
    );
  };
};

const updateAnswer = (id, data) => {
  return (dispatch) => {
    dispatch({ type: questionTypes.UPDATE_ANSWER_REQUEST });
    questionService.updateAnswer(id, data).then(
      (data) => {
        dispatch({ type: questionTypes.UPDATE_ANSWER_SUCCESS });
      },
      (error) => {
        dispatch({ type: questionTypes.UPDATE_ANSWER_FAILURE });
      }
    );
  };
};
const deleteAnswerAction = (id) => {
  return (dispatch) => {
    dispatch({ type: questionTypes.DELETE_ANSWER });
    questionService.deleteAnswer(id).then(
      (data) => {
        dispatch({ type: questionTypes.DELETE_ANSWER_SUCCESS });
      },
      (error) => {
        dispatch({ type: questionTypes.DELETE_ANSWER_FAILURE });
      }
    );
  };
};

const getAnswer = (id) => {
  return (dispatch) => {
    dispatch({ type: questionTypes.GET_ANSWER_REQUEST });
    questionService.getAnswer(id).then(
      (data) => {

        dispatch({
          type: questionFormStateTypes.EDIT_ANSWER_TRUE,
          data: data,
        });
      },
      (error) => {
        dispatch({ type: questionTypes.GET_ANSWER_FAILURE });
      }
    );
  };
};

const questionStateChanger = () => ({
  type: questionTypes.QUESTION_STATE_CHANGER,
});

const getSingleQuestion = (id) => {
  return (dispatch) => {
    dispatch({ type: questionTypes.GET_SINGLE_QUESTION_REQUEST });
    questionService.getSingleQuestion(id).then(
      (data) => {
        dispatch(questionFormStateEdit(data));
        dispatch(setQuestionType(data.question_type));
      },
      (error) => {
        dispatch({ type: questionTypes.GET_SINGLE_QUESTION_FAILURE });
      }
    );
  };
};

export const clearQuestionError = () => {
  return { type: questionTypes.CLEAR_QUESTION_ERROR };
};

export default {
  getStandardCode,
  getReviewers,
  getCreators,
  getUserQuestions,
  getAllQuestions,
  getAnswer,
  createQuestion,
  createAnswer,
  deleteQuestion,
  updateQuestion,
  updateAnswer,
  questionStateChanger,
  getSingleQuestion,
  clearQuestionError,
  getQuizSet,
  deleteAnswerAction,
};
