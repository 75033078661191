import { handleResponse, handleResponseDownload } from '../../helpers/handleResponse';
import authHeader from '../../helpers/authHeader';
import { uploadHeader } from '../../helpers/utils';

const API_URL = process.env.REACT_APP_API_URL

const upload_file = (data) => {
	const requestOptions = {
		method: 'POST',
		headers: uploadHeader(),
		body: data,
		credentials: "include",
	};
	return fetch(API_URL + '/standard/upload/', requestOptions).then(handleResponse);
};

const download_file_excel = () => {
	const requestOptions = {
		method: 'GET',
		headers: authHeader(),
		credentials: "include",
		contentType: 'application/vnd.ms-excel'
	};
	return fetch(API_URL + `/standard/download/xls/`, requestOptions).then(handleResponseDownload);
};

const download_file_csv = () => {
	const requestOptions = {
		method: 'GET',
		headers: authHeader(),
		credentials: "include",
		contentType: 'text/csv'
	};
	return fetch(API_URL + '/standard/download/csv/', requestOptions).then(handleResponseDownload);
};

const upload_terms = (data, path) => {
	const request = {
		path: 'terms-condition',
		value: data,
		credentials: "include",
	};

	const requestOptions = {
		method: 'PUT',
		headers: authHeader(),
		body: JSON.stringify(request),
		credentials: "include",
	};
	return fetch(API_URL + '/settings/path/terms-condition/', requestOptions).then(handleResponse);
};

const upload_non_registered = (data, path) => {
	const request = {
		path: 'non-registered-email',
		value: data.non_registered,
		credentials: "include",
	};

	const requestOptions = {
		method: 'PUT',
		headers: authHeader(),
		body: JSON.stringify(request),
		credentials: "include",
	};
	return fetch(API_URL + '/settings/path/non-registered-email/', requestOptions).then(handleResponse);
};

const upload_registered = (data, path) => {
	const request = {
		path: 'registered-email',
		value: data.registered,
		credentials: "include",
	};

	const requestOptions = {
		method: 'PUT',
		headers: authHeader(),
		body: JSON.stringify(request),
		credentials: "include",
	};
	return fetch(API_URL + '/settings/path/registered-email/', requestOptions).then(handleResponse);
};

const get_settings = () => {
	const requestOptions = {
		method: 'GET',
		headers: authHeader(),
		credentials: "include",
	};
	return fetch(API_URL + '/settings/', requestOptions).then(handleResponse);
};

const createCreator = (data) => {
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify(data),
		credentials: "include",
	};
	return fetch(API_URL + '/creator/', requestOptions).then(handleResponse);
};

export default {
	upload_file,
	download_file_csv,
	download_file_excel,
	upload_non_registered,
	upload_registered,
	upload_terms,
	createCreator,
	get_settings,
};
