import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";

import Header from '../Header/Header';
import QuizQuestion from './QuizQuestions';
import Footer from '../../Common/footer/footer.component';

import { handleResponse } from '../../../helpers/handleResponse';
import authHeader from '../../../helpers/authHeader';

import './BeginQuizQuestions.css'

const QuizStarted = (props) => {

    const API_URL = process.env.REACT_APP_API_URL

    const quizStartedId = props.computedMatch.params.quizId;
    const [quiz, setQuiz] = useState("")
    const [quizStarted, setQuizStarted] = useState("")
    const gradeInfo = quizStarted?.grade;
    const tName = useSelector((state) => state.authentication.user?.userObj)
    const tSubject = useSelector((state) => state.quiz.active_class_header?.subject)
    const quizName = quizStarted?.title;
    const quizQ = quizStarted?.questions;
    


    const quizQN = quizQ;

    const checkActiveQuiz = () => {
        const requestOptions = {
            method: "GET",
            headers: authHeader(),
            credentials: "include",
        };
        fetch(API_URL + '/quiz/active/', requestOptions)
            .then(
                handleResponse
            )
            .then((dataRes) => {
                try{
                setQuiz(dataRes.results[0])
                } catch{

                }
            })
    }
    
    const checkQuizStarted = () => {
        const requestOptions = {
            method: "GET",
            headers: authHeader(),
            credentials: "include",
        };
        fetch(API_URL + `/quiz/${quizStartedId}/`, requestOptions)
            .then(
                handleResponse
            )
            .then((dataRes) => {
                try {
                setQuizStarted(dataRes)
                } catch {

                }
            })
    }

    useEffect(() => {
        checkActiveQuiz();
        checkQuizStarted();
    },[])

    return (
        <div class='container-fluid' className='main-student-container'>
            <Header 
                title={quizName}
                name={tName ? tName.first_name + " " + tName.last_name : null}
                grade={gradeInfo} 
                classN={tSubject}/>
            <QuizQuestion 
                quizId={props.computedMatch.params.quizId}
                classId={props.computedMatch.params.classId}
                numberOfQ={quizQN}
            />
        </div>
    )
}

export default QuizStarted;
