import React, { useState, useEffect } from 'react';
import { LinkContainer } from "react-router-bootstrap";
import { Nav } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";

import logo from '../../../ui/assets/lessThan.png';
import authHeader from '../../../../helpers/authHeader';
import { handleResponse } from '../../../../helpers/handleResponse';
import { setStudentQuizAnswersAction, setRetakesAction} from '../../../../redux/quiz/quiz.actions';
import { Link } from 'react-router-dom';
import * as BsIcons from 'react-icons/bs';
import * as AiIcons from 'react-icons/ai';
import * as HiIcons from 'react-icons/hi';

import ContactUsModalComponent from '../../../Common/contact-us-modal/contact-us-modal.component';
import { selectShow } from '../../../../redux/modals/modal.select';
import { toggleShow } from '../../../../redux/modals/modals.actions';
import { createStructuredSelector } from 'reselect';
import { userActions } from '../../../../redux/user/user.actions';
import { connect } from "react-redux";

import './QuizFinalPageContent.css'




const QuizFinalPageContent = ({ classId, quizId, title, show, toggleShow }) => {

    const API_URL = process.env.REACT_APP_API_URL;

    const answers = useSelector((state) => state.quiz.studentQuizAnswers);
    //const quizQ = useSelector((state) => state.quiz.studentCurrentQuiz.questions);
    const [quizA, setQuizA] = useState("")
    const retake = useSelector((state) => state.quiz.retake)
    const active = false;

    const dispatch = useDispatch();
    const history = useHistory();

    const handleLogout = () => {
        dispatch(userActions.logout());
    };

    const handleContactUs = () => {
        toggleShow();
    };



    const saveData = () => {
        const dataObj = answers;
        const requestOptions = {
            method: "POST",
            headers: authHeader(),
            credentials: "include",
            body: JSON.stringify(dataObj),
        };
        fetch(API_URL + `/stuqnreply/class/${classId}/quiz/${quizId}/`, requestOptions)
            .then((result) => {
                return handleResponse(result)
            })
            .then((data) => {
                dispatch(setStudentQuizAnswersAction([data]));
                history.replace(`/quiz-finish/${classId}/${quizId}`)
            })
    }

    const checkActive = () => {
        const requestOptions = {
            method: "GET",
            headers: authHeader(),
            credentials: "include",
        };
        fetch(API_URL + '/quiz/active/', requestOptions)
            .then(
                handleResponse
            )
            .then((dataRes) => {
                try {
                    setQuizA(dataRes?.results.some(result => parseInt(quizId) === result?.id))
                } catch{

                }
            })
    }

    useEffect(() => {
        checkActive();
        // eslint-disable-next-line react-hooks/exhaustive-deps
        // if (performance.navigation.type === 1) {
        //     history.push('/landing-page')
        // } 
    }, [])


    /*return (
        <div className="container" style={containerStyles}>
            {quizA === true ?
                <div
                    className="content-text"
                    style={finishingStyles}>
                    Finishing the quiz...

                    <LinkContainer
                        to={`/quiz-started/${quizId}`}
                        style={linkContainerStyles}
                    >
                        <Nav.Link><img style={{ width: 20, height: 20 }} src={logo} alt="" />Back to questions</Nav.Link>

                    </LinkContainer>

                    <p align="right" style={p1Styles}>Answers are complete</p>
                    <p align="right">
                        <button 
                            onClick={() => saveData()}
                            style={saveQuizzButtonStyles}
                        >
                            Save Quiz
                        </button>
                    </p>
                </div> 
                :
                <div
                    className="content-text"
                    style={finishingStyles2}
                >
                    Finishing the quiz...

                    <p align="right" style={p2Styles}> Answers are complete </p>
                    <p align="right">
                        <button 
                            onClick={() => saveData() }
                            style={saveQuizzButtonStyles}
                        >
                            Save Quiz
                        </button>
                    </p>
                </div>
            }
        </div>
    )*/
    return (
        <div className='finishing-page-container'>

            <div className='finishing-page-info'>
                <h3>Finishing the quiz...</h3>
                <p>Answers are complete</p>
                {quizA === true || retake === true ?
                    <div className='pre-finish-footer-quiz' >
                        <LinkContainer className="btn-prev"
                            to={`/quiz-started/${classId}/${quizId}`}
                        >
                            <Nav.Link>Go Back</Nav.Link>

                        </LinkContainer>

                        <button className='btn-next' id="next"
                            onClick={() => quizA === true || retake === true ? saveData() : history.replace('/quiz-stopped')}
                        >
                            Save Quiz
                        </button>
                    </div>
                    :
                    <div className='pre-finish-footer-quiz' >
                        <button className='btn-next' id="next"
                            onClick={() => quizA === true || retake === true ? saveData() : history.replace('/quiz-stopped')}
                        >
                            Save Quiz
                        </button>
                    </div>
                }
                </div>
            </div>
    )

}

const mapStateToProps = createStructuredSelector({
    show: selectShow,
});

const mapDispatchToProps = (dispatch) => {
    return {
        toggleShow: () => dispatch(toggleShow()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(QuizFinalPageContent);


const finishingStyles = {
    textAlign: 'center',
    paddingBottom: 200,
    fontFamily: 'Monaco',
    fontWeight: 'bold',
    fontSize: 30,
    paddingTop: 190
}

const finishingStyles2 = {
    textAlign: 'center',
    paddingBottom: 100,
    fontFamily: 'Monaco',
    fontWeight: 'bold',
    fontSize: 30,
    paddingTop: 100
}

const p1Styles = {
    marginTop: -40, 
    marginRight: 100, 
    fontSize: 25, 
    fontFamily: 'Apple Chancery, cursive'
}

const p2Styles = {
    marginTop: 200, 
    marginRight: 100, 
    fontSize: 25, 
    fontFamily: 'Apple Chancery, cursive'
}

const linkContainerStyles = {
    border: '0 !important',
    width: '25%',
    fontFamily: 'Apple Chancery, cursive',
    fontWeight: 'bold',
    fontSize: 25,
    color: 'black',
    cursor: 'pointer',
    textAlign: 'center',
    marginTop: 100,
    marginLeft: 30
}

const saveQuizzButtonStyles = {
    border: '2px solid grey',
    width: '27%',
    fontFamily: 'Apple Chancery, cursive',
    fontWeight: 'bold',
    fontSize: 23,
    color: 'black',
    backgroundColor: '#E8E8E8',
    cursor: 'pointer',
    borderRadius: 25,
    boxShadow: '0 9px #999',
    textAlign: 'center',
    marginRight: 40,
    height: 45,
}

const containerStyles = {
    justifyContent: "center",
    alignItems: "center",
    borderLeft: '3px solid black',
    borderRight: '3px solid black',
    borderBottom: '3px solid black',
    height: 500,
    width: 1500,
    marginLeft: -15,
}