import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import authHeader from '../../../helpers/authHeader';
import { handleResponse } from '../../../helpers/handleResponse';

import ClassStandardsContent from './ClassStandardsContent';
import ReportsHeader from '../Teacher Schedule/ReportsHeader';
import TeacherHeader from '../Teacher Schedule/TeacherHeader';



const ClassStandards = (props) => {

    const tName = useSelector((state) => state.authentication.user?.userObj)
    const role = useSelector((state) => state.authentication.user?.userObj?.role)
    const class_id = props.computedMatch.params.classPeriod;
    const class_name = props.computedMatch.params.className;

    return (
        <div>
            <TeacherHeader 
                title={"Standards"} 
                name={tName ? tName.first_name + " " + tName.last_name : null}
                role={role}
            />
            <ClassStandardsContent  class_id={class_id} class_name={class_name} />
        </div>
    )
}

export default ClassStandards;