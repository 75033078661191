import React from 'react';
import { useSelector } from 'react-redux';
import TeacherHeader from '../Teacher Schedule/TeacherHeader';
import TeacherTableRetakesContent from './TeacherTableRetakesContent';

const TeacherTableRetakes = (props) => {


    const tName = useSelector((state) => state.authentication.user?.userObj)
    const role = useSelector((state) => state.authentication.user?.userObj?.role)
    const classId = props.computedMatch.params.classPeriod;
    const className = props.computedMatch.params.className;


    return (
        <div class="container-fluid">
            <TeacherHeader
                title={"Number of Quizzes Taken"}
                name={tName ? tName.first_name + " " + tName.last_name : null}
                role={role}
            />
            <TeacherTableRetakesContent classId={classId} className={className} />
        </div>
    )
}

export default TeacherTableRetakes;