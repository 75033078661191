import React from 'react';
import PropTypes from 'prop-types';

import { Row, Col, Table } from "react-bootstrap";
import { useHistory } from "react-router-dom";

const Header = ({  name, subject, grade }) => {

    const history = useHistory();

    const headerStyles = {
        width: 960,
        height: 'auto',
        textAlign: 'center',
        position: 'relative',
        backgroundImage: 'linear-gradient(to top, #C8C8C8, #E8E8E8)',
        border: '3px solid black',
        marginTop: 20,
    }

    const buttonStyle = {
        textDecoration: 'underline',
        fontWeight: 'bold',
        backgroundColor: 'inherit',
        color: 'black',
        marginLeft: -20,
        border: 0,
    }

    return (
        <Row>
            <Col style={headerStyles}>
                <div>
                    <Table >
                        <thead>
                            <tr>
                                <td>
                                    <button onClick={() => { history.push("/landing-page") }} style={buttonStyle}>Home</button>
                                </td>
                               
                                <th scope="col" className="border-0 thead status-color">
                                    <p>
                                        {grade}
                                    </p>
                                </th>
                                <th scope="col" className="border-0 thead status-color" >
                                    <p>
                                        {subject}
                                    </p>
                                </th>
                                <th
                                    scope="col"
                                    className="border-0 thead status-color"
                                >
                                    <p>
                                        {name}
                                    </p>
                                </th>
                            </tr>
                        </thead>
                    </Table>
                </div>
            </Col>
        </Row>
    )
}


Header.propTypes = {
    name: PropTypes.string,
    subject: PropTypes.string,
    grade: PropTypes.string,
}

Header.defaultProps = {
    subject: 'Math',
    name: 'Jarrod Dakin',
}
export default Header;