import React from 'react';
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types';
import { Row, Col, Table } from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import { userActions } from "../../../redux/user/user.actions";
import { FormattedMessage } from "react-intl";
import io from "socket.io-client";
import logo from '../../../assets/img/logo.svg';
import studentPhoto from '../../../assets/img/student-photo.png';
import './Header.css';

const SOCKETIO_ENDPOINT = process.env.REACT_APP_SOCKETIO_ENDPOINT;

const Header = ({name, title, quizNumber, grade, classN, gInfo, subject, back}) => {

    const history = useHistory();
    const dispatch = useDispatch();
    const tName = useSelector((state) => state.authentication.user?.userObj)

    const handleLogout = () => {
        const socket = io(SOCKETIO_ENDPOINT,{
            transportOptions: {
                polling: {
                    extraHeaders: {
                        'X-Username': tName.id
                    }
                }
            }
        });
        socket.disconnect();
        dispatch(userActions.logout());
    };



    return (
       <div className='header-student'>
           <div className='header-student-logo'>
                <img src={logo} alt="logo"/>
           </div>
           <div className='header-student-info'>
                <div 
                    className='quiz-header-info'
                    style={titleStyles}
                >
                  {title}
                    <p style={{ color: 'grey' }}>{quizNumber ? `${quizNumber} quiz` : null} </p>
                    { grade && classN ? 
                    <p style={quizNumber ? { color: 'grey' } : {color: 'grey', marginTop: '-2%'}}>Grade {grade} | {classN}</p>
                    : null}
                    {grade && subject ?
                            <p style={quizNumber ? { color: 'grey' } : { color: 'grey', marginTop: '-2%' }}>Grade {grade} | {subject}</p>
                            
                        : null}
                    { back ? 
                        <button 
                        onClick={() => history.push('/landing-page')}
                        className='back-button-1'
                        >
                            BACK TO MAIN PAGE
                        </button> : null}
                </div>
                <div className='quiz-header-image'>
                    <h6 className='student-name'>{name}</h6>
                    <button className='logout-button' onClick={handleLogout}>
                        <span>Logout</span>
                    </button>
                </div>
           </div>
       </div>
    )
}

export default Header;

const titleStyles = {
    fontSize: 18,
    color: 'black',
    fontWeight: 'bold',
    marginLeft: '5%',
    marginTop: '2%'
}