import React, { useState, useRef } from 'react';
import './ClassListTable.css';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Dialog } from '@mui/material';
import { Form, Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import authHeader from '../../../helpers/authHeader';
import { handleResponse } from '../../../helpers/handleResponse';

function ClassListTable({ data, refetchData }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [classCodeError, setClassCodeError] = useState(null);
    const classCodeInputRef = useRef();
    const API_URL = process.env.REACT_APP_API_URL;

    const submitClassCode = () => {
        const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            credentials: 'include',
            body: JSON.stringify({ class_code: classCodeInputRef.current.value.trim() }),
        };
        fetch(API_URL + '/classroll/class_code/', requestOptions)
            .then(handleResponse)
            .then((data) => {
                if(data.status_code !== 400){
                    setIsModalOpen(false);
                    setClassCodeError(null);
                    refetchData();
                } else {
                    setClassCodeError(data.detail);
                }
            })
            .catch(error => {
                setClassCodeError(error.detail);
            });
    };

    const handleAddClassSubmit = e => {
        e.preventDefault();
        setClassCodeError(null);
        const classCodeInputValue = classCodeInputRef.current.value.trim();
        if (classCodeInputValue !== '') {
            submitClassCode();
        } else {
            setClassCodeError('You forgot to write a class code!');
        }
    };

    return (
        <>
            <Dialog
                open={isModalOpen}
                onClose={() => {
                    setIsModalOpen(false);
                    setClassCodeError(null);
                }}
                PaperProps={{ style: { borderRadius: '24px', padding: '1rem', textAlign: 'center' } }}
            >
                <h1 style={{ textAlign: 'center', padding: '1rem 0' }}>Add class</h1>
                <p style={{ marginBottom: '2.5rem' }}>
                    Please enter the code given by your teacher in the text box bellow this message then click "Submit class code"
                </p>
                <Form className="text-center" onSubmit={handleAddClassSubmit} noValidate style={{ padding: '0 2rem' }}>
                    <Form.Group controlId="formClassCode" className="relative">
                        <Form.Control
                            type="text"
                            className={`input-style input-text label-up `}
                            name="classCode"
                            ref={classCodeInputRef}
                        />
                        <span className="floating-label" style={{ top: '-25px', fontSize: '1em' }}>
                            <FormattedMessage id="addClassCodeLabel" defaultMessage="Class Code" />
                        </span>
                        {classCodeError && (
                            <div className="text-danger" style={{ bottom: '-32px', fontSize: '1em' }}>
                                {classCodeError}
                            </div>
                        )}
                    </Form.Group>
                    <Button variant="primary" type="submit" className="text-center custom-btn" style={{ marginTop: '2rem' }}>
                        <FormattedMessage defaultMessage="Submit Class Code" id="addClassCodeButtonLabel" />
                    </Button>
                </Form>
            </Dialog>
            <div className="quiz-page-container">
                <div className="past-quizzes-info">
                    <TableContainer className="past-table-container">
                        <Table stickyHeader className="past-table">
                            <TableHead className="class-list-table-head">
                                <TableRow className="class-list-table-row">
                                    <TableCell
                                        className="class-list-table-number"
                                        style={{
                                            color: 'black',
                                            fontWeight: 'bold',
                                            fontSize: 14,
                                            backgroundColor: 'white',
                                            textAlign: 'center'
                                        }}
                                    >
                                        #
                                    </TableCell>
                                    <TableCell
                                        className="class-list-table-classname"
                                        style={{
                                            color: 'black',
                                            fontWeight: 'bold',
                                            fontSize: 12,
                                            backgroundColor: 'white',
                                            textAlign: 'center'
                                        }}
                                    >
                                        Class Name
                                    </TableCell>
                                    <TableCell
                                        className="class-list-table-grade"
                                        style={{
                                            color: 'black',
                                            fontWeight: 'bold',
                                            fontSize: 12,
                                            backgroundColor: 'white',
                                            textAlign: 'center'
                                        }}
                                    >
                                        Grade
                                    </TableCell>
                                    <TableCell className="class-list-table-subject" style={tableCellsStyle}>
                                        Subject
                                    </TableCell>

                                    <TableCell className="class-list-table-year" style={tableCellsStyle}>
                                        Year
                                    </TableCell>
                                    <TableCell className="class-list-table-status" style={tableCellsStyle}>
                                        Enrolled in class
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className="class-list-table-body">
                                {data
                                    ? data.map((row, index) => (
                                          <TableRow className="class-list-rows" key={index}>
                                              <TableCell
                                                  className="past-cell"
                                                  style={{
                                                      color: row.classroll_enrolled ? '#6a6a6a':'gray',
                                                      borderBottom: '2px solid',
                                                      textAlign: 'center',
                                                      backgroundColor: row.classroll_enrolled ? 'white' : '#efefef',
                                                    }}
                                              >
                                                  {index + 1}
                                              </TableCell>
                                              <TableCell
                                                  className="past-cell"
                                                  style={{
                                                      fontWeight: 'bold',
                                                      fontSize: 12,
                                                      color: row.classroll_enrolled ? '#6a6a6a':'gray',
                                                      textAlign: 'center',
                                                      borderBottom: '2px solid',
                                                      backgroundColor: row.classroll_enrolled ? 'white' : '#efefef',    
                                                  }}
                                              >
                                                  {row?.name}
                                              </TableCell>
                                              <TableCell
                                                  className="past-cell"
                                                  style={{
                                                      fontWeight: 'bold',
                                                      fontSize: 12,
                                                      color: row.classroll_enrolled ? '#6a6a6a':'gray',
                                                      textAlign: 'center',
                                                      borderBottom: '2px solid',
                                                      backgroundColor: row.classroll_enrolled ? 'white' : '#efefef',    
                                                  }}
                                              >
                                                  {row?.grade}
                                              </TableCell>
                                              <TableCell
                                                  className="past-cell"
                                                  style={{
                                                      fontWeight: 'bold',
                                                      fontSize: 12,
                                                      color: row.classroll_enrolled ? '#6a6a6a':'gray',
                                                      textAlign: 'center',
                                                      borderBottom: '2px solid',
                                                      backgroundColor: row.classroll_enrolled ? 'white' : '#efefef',    
                                                  }}
                                              >
                                                  {row?.subject}
                                              </TableCell>
                                              <TableCell
                                                  className="past-cell"
                                                  style={{
                                                      fontWeight: 'bold',
                                                      fontSize: 12,
                                                      color: row.classroll_enrolled ? '#6a6a6a':'gray',
                                                      textAlign: 'center',
                                                      borderBottom: '2px solid',
                                                      backgroundColor: row.classroll_enrolled ? 'white' : '#efefef',}}
                                              >
                                                  {row?.year}
                                              </TableCell>
                                              <TableCell
                                                  className="past-cell"
                                                  style={{
                                                      fontWeight: 'bold',
                                                      fontSize: 12,
                                                      color: row.classroll_enrolled ? '#6a6a6a':'gray',
                                                      textAlign: 'center',
                                                      borderBottom: '2px solid',
                                                      backgroundColor: row.classroll_enrolled ? 'white' : '#efefef',    
                                                  }}
                                              >
                                                  {row?.classroll_enrolled ? 'Enrolled' : 'Pending'}
                                              </TableCell>
                                          </TableRow>
                                      ))
                                    : null}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <button
                        className="addclass-button"
                        style={{ float: 'right' }}
                        onClick={() => {
                            setIsModalOpen(true);
                        }}
                    >
                        Add Class
                    </button>
                </div>
            </div>
        </>
    );
}

export default ClassListTable;

const tableCellsStyle = {
    color: 'black',
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: 12,
    backgroundColor: 'white'
};

const tableCellsStyle2 = {
    color: 'grey',
    textAlign: 'center',
    fontSize: 12
};
