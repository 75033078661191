import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";

import Header from '../../Header/Header';
import FinishedQuizContent from './FinishedQuizContent';
import Footer from '../../../Common/footer/footer.component';

const FinishedQuiz = (props) => {

    const gradeInfo = useSelector((state) => state.quiz.studentCurrentQuiz?.grade);
    const tName = useSelector((state) => state.authentication.user?.userObj)
    const tSubject = useSelector((state) => state.quiz.active_class_header?.subject)
    const quizName = useSelector((state) => state.quiz.active_quiz_details?.title)
    
    return (
        <div class="container-fluid" className='main-student-container'>
            <Header 
                title={quizName ? quizName : null}
                grade={gradeInfo ? gradeInfo : null}
                classN={tSubject ? tSubject : null}
                name={tName ? tName.first_name + " " + tName.last_name : null}
           />
            <FinishedQuizContent 
                classId={props.computedMatch.params.classId} 
                quizId={props.computedMatch.params.quizId}/>
        </div>
    )
}

export default FinishedQuiz;
