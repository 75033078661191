export default {
   FILE_UPLOAD_REQUEST: "FILE_UPLOAD_REQUEST",
   FILE_UPLOAD_SUCCESS: "FILE_UPLOAD_SUCCESS",
   FILE_UPLOAD_FAILURE: "FILE_UPLOAD_FAILURE",

   FILE_DOWNLOAD_REQUEST: "FILE_DOWNLOAD_REQUEST",
   FILE_DOWNLOAD_SUCCESS: "FILE_DOWNLOAD_SUCCESS",
   FILE_DOWNLOAD_FAILURE: "FILE_DOWNLOAD_FAILURE",

   TERMS_UPLOAD_REQUEST: "TERMS_UPLOAD_REQUEST",
   TERMS_UPLOAD_SUCCESS: "TERMS_UPLOAD_SUCCESS",
   TERMS_UPLOAD_FAILURE: "TERMS_UPLOAD_FAILURE",

   NON_UPLOAD_REQUEST: "NON_UPLOAD_REQUEST",
   NON_UPLOAD_SUCCESS: "NON_UPLOAD_SUCCESS",
   NON_UPLOAD_FAILURE: "NON_UPLOAD_FAILURE",

   R_UPLOAD_REQUEST: "R_UPLOAD_REQUEST",
   R_UPLOAD_SUCCESS: "R_UPLOAD_SUCCESS",
   R_UPLOAD_FAILURE: "R_UPLOAD_FAILURE",

   GET_SETTINGS_REQUEST: "GET_SETTINGS_REQUEST",
   GET_SETTINGS_FAILURE: "GET_SETTINGS_FAILURE",
   GET_SETTINGS_SUCCESS: "GET_SETTINGS_SUCCESS",

   CREATE_CREATOR_REQUEST: "CREATE_CREATOR_REQUEST",
   CREATE_CREATOR_FAILURE: "CREATE_CREATOR_FAILURE",
   CREATE_CREATOR_SUCCESS: "CREATE_CREATOR_SUCCESS",

   SETTING_STATUS_CHANGER: "SETTING_STATUS_CHANGER",
};
