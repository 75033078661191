import React, { useState, useEffect } from 'react';
import parse from "html-react-parser";
import io from "socket.io-client";
import { useSelector, useDispatch } from "react-redux";

import { Link } from 'react-router-dom';
import * as BsIcons from 'react-icons/bs';
import * as AiIcons from 'react-icons/ai';
import * as HiIcons from 'react-icons/hi';
import * as IoIcons from 'react-icons/io';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TableFooter } from "@material-ui/core";
import { Button } from '@mui/material';

import ContactUsModalComponent from '../../../Common/contact-us-modal/contact-us-modal.component';
import { selectShow } from '../../../../redux/modals/modal.select';
import { toggleShow } from '../../../../redux/modals/modals.actions';
import { createStructuredSelector } from 'reselect';
import { userActions } from '../../../../redux/user/user.actions';
import { connect } from 'react-redux';
import { alphabetArray } from '../../../../helpers/utils';
import authHeader from '../../../../helpers/authHeader';
import { handleResponse } from '../../../../helpers/handleResponse';

import './FinishedQuizContent.css';
import { useHistory } from "react-router-dom";
import quizActions from '../../../../redux/quiz/quiz.actions';

const FinishedQuizContent = ({ classId, quizId, show, toggleShow }) => {

    //const SOCKETIO_ENDPOINT = process.env.REACT_APP_SOCKETIO_ENDPOINT; 
    const API_URL = process.env.REACT_APP_API_URL;

    const dispatch = useDispatch();

    const [quiz, setQuiz] = useState("")
    const answersState = useSelector((state) => state.quiz?.studentQuizAnswers);
    const answers = answersState && answersState.length ? Object.values(answersState[0]?.result) : [];
    const tName = useSelector((state) => state.authentication.user?.userObj)
    const title = quiz?.title;
    const questions = quiz?.questions;

    const history = useHistory()
  
    const handleLogout = () => {
        dispatch(userActions.logout());
    };

    const handleContactUs = () => {
        toggleShow();
    };

    const checkActiveQuiz = () => {
        const requestOptions = {
            method: "GET",
            headers: authHeader(),
            credentials: "include",
        };
        fetch(API_URL + `/quiz/${quizId}/`, requestOptions)
            .then(
                handleResponse
            )
            .then((dataRes) => {
                try{
                    setQuiz(dataRes)
                } catch {

                }
            })
    }

    useEffect(() => {
        checkActiveQuiz();
    }, [])
    
    useEffect(()=>{

        return ()=>{
            dispatch(quizActions.resetQuizData())
        }
    },[])
    /*useEffect(() => {
        if (tName) {
            const socket = io(SOCKETIO_ENDPOINT, {
                transportOptions: {
                    polling: {
                        extraHeaders: {
                            'X-Username': tName.id
                        }
                    }
                }
            });
            socket.emit('submitTest', quizId, classId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tName])*/

    /*return (
        <div className="container" style={containerStyles}>
            
            <div style={divLeftStyles}>

                <p style={p1LeftStyles}> Quiz {quizId} [{title ? title : ""}] </p>
                {questions ? questions.map((item, key) => {
                    return (
                        <div key={key} >
                            <p style={p2LeftStyles}> {item.order}. </p>
                            <p style={{ marginLeft: 40 }}> {parse(item.question.value)} </p>
                            {item && item.question.image && <img src={item.question.image} alt="image" height="200" style={{ marginLeft: 40, marginBottom: 10 }} />}
                            {item.question.answers.map((ans, index, key) => {
                                return <p key={key} style={{ marginLeft: 40 }}> {`${alphabetArray[index]} ${ans.content}`} </p>
                            })}
                        </div>)
                }) 
                : 
                <p>No data</p>}

            </div>



            <div style={divRightStyles}>

                <div className="content-text" style={{ marginTop: 20 }}>
                    Thank you for taking the quiz... <br />

                    <p style={p1RightStyles}> Your answers are: </p>

                    <table style={{ width: 'auto' }} align="center">
                        <tbody>
                            {answers ? answers.map((item, index) => (
                                <tr key={index}>
                                    <th align="center" style={th1Styles}> {index + 1} </th>
                                    <th align="center" style={th2Styles}> {item.label} </th>
                                    <th align="center" style={th3Styles}> {item.content} </th>
                                    <th align="center" style={th4Styles}> 
                                        {item.correct === true ? 
                                            <span style={{ color: 'green' }}> 'OK' </span> 
                                            : 
                                            <span style={{ color: 'red' }}> 'X' </span>}
                                    </th>
                                </tr>
                            )) : null}
                        </tbody>
                    </table>
                    <p style={p2RightStyles}> Please wait for your teacher’s instructions. </p>
                </div>

            </div>

        </div>
    )*/
        
    /*const correctAns = () => {
        answers.map((item) => (
            item.correct === true ? counter++ : 'no correct ans'
        ))
        return counter;
        
    }*/

    return (
        <div className='final-page-container'>
            <div style={divLeftStyles}>

                <p style={p1LeftStyles}> Quiz {quizId} [{title ? title : ""}] </p>
                {questions ? questions.map((item, key) => {
                    return (
                        <div key={item?.id} >
                            <p style={p2LeftStyles}> {item.order}. </p>
                            <p style={{ marginLeft: 40 }}> {parse(item.question.value)} </p>
                            {item && item.question.image && <img src={item.question.image} alt="image" height="200" style={{ marginLeft: 40, marginBottom: 10 }} />}
                            {item.question.answers.map((ans, index) => {
                                return <p key={ans?.id} style={{ marginLeft: 40 }}> {`${alphabetArray[index]} ${ans.content}`} </p>
                            })}
                        </div>)
                })
                    :
                    <p>No data</p>}

            </div>
            <div style={divRightStyles}>
            <div className='final-page-info'>
                <p style={{ fontSize: 16, marginLeft: 30}}>Please wait for your teacher's instructions</p>

                <TableContainer className='past-table-container' style={{marginTop: -4}} >
                    <Table stickyHeader className='past-table'>
                            <TableHead stickyHeader className='past-table-head' >
                            <TableRow className='past-table-row' >
                                <TableCell
                                    className='last-page-crt'
                                    style={tableHeadStyle}
                                >
                                    
                                </TableCell>
                                <TableCell
                                    className='last-page-answers'
                                    style={tableHeadStyleBold}                                >
                                    Answer Selection
                                </TableCell>
                                <TableCell
                                    className='last-page-status'
                                    style={tableHeadStyleBold}
                                >
                                   Right/Wrong
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody className='last-page-table-body' >
                            {answers ? answers.map((item, index) => (
                            <TableRow className='past-table-rows' key={index}>
                                    <TableCell 
                                        style={{ color: 'grey' }}
                                    >
                                        {index + 1}
                                    </TableCell>
                                    <TableCell 
                                        style={{ fontWeight: 'bold', fontSize: 12, color: 'grey'}}
                                    >
                                        {item.label}
                                    </TableCell>
                                    <TableCell
                                        
                                    >
                                        {item.correct === true ?
                                            <IoIcons.IoIosCheckmark className='icons-correct' />
                                            :
                                            <IoIcons.IoIosClose className='icons-false' />
                                        }
                                    </TableCell>
                                </TableRow>
                            )) : null }
                        </TableBody>
                    </Table>
                    
                </TableContainer>
                <div className='return-button-container'>
                    <Button className='return-button' onClick={()=>{history.replace('/landing-page')}}>Back to Homepage</Button>
                </div>
            </div>

            </div>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    show: selectShow,
});

const mapDispatchToProps = (dispatch) => {
    return {
        toggleShow: () => dispatch(toggleShow()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FinishedQuizContent);



const tableHeadStyle = { color: 'grey', fontSize: 12, backgroundColor: 'white' }
const tableHeadStyleBold = { color: 'black', fontSize: 12, backgroundColor: 'white', fontWeight: 'bold' }


const divLeftStyles = {
    float: 'left',
    width: '35%',
    marginLeft: -10,
    height: 490,
    overflowY: 'scroll',
    overflowX: 'scroll',
    textAlign: 'center'
}


const divRightStyles = {
    float: 'right',
    width: '65%',
    marginRight: -10,
    minHeight: 490,
}

const p1LeftStyles = {
    fontSize: 30,
    marginTop: 20,
    textAlign: 'center',
}

const p2LeftStyles = {
    color: 'black',
    fontWeight: 'bold',
    marginLeft: 40,
    marginTop: 50
}

