import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import authHeader from '../../../helpers/authHeader';
import { handleResponse } from '../../../helpers/handleResponse';

import PastQuizzesReportsChart from './PastQuizzesReportsChart';
import Header from '../Header/Header';
import footerComponent from '../../Common/footer/footer.component';

const PastQuizzesReports = (props) => {
    const API_URL = process.env.REACT_APP_API_URL

    const [reportData, setReportData] = useState("");

    const titleReport = props.computedMatch.params.pastQuizTitle


    const tSubject = useSelector((state) => state.quiz.active_class_header?.subject)
    const tGrade = useSelector((state) => state.quiz.active_class_header?.grade)
    const tName = useSelector((state) => state.authentication?.user?.userObj)

    const fetchReports = () => {
        const requestOptions = {
            method: "GET",
            headers: authHeader(),
            credentials: "include",
        };
        fetch(API_URL + '/report/student/retakes/', requestOptions)
            .then(
                handleResponse
            )
            .then((data) => {
                setReportData(data)
            })
    }


    useEffect(() => {
        fetchReports();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    return (
        <div class='container-fluid' className='main-student-container'>
            <Header
                title={"Past Quizzes Report"}
                name={tName ? tName.first_name + " " + tName.last_name : null}
                gInfo={tGrade}
                subject={tSubject}
                back={false}
                back2={true} />
            {/* {chartData?.data[0] && <PastQuizzesReportsChart data={chartData?.data[0]} />} */}
            <PastQuizzesReportsChart data={reportData} title={titleReport}/>
        </div>
    )
}

export default PastQuizzesReports
